import React, { Component } from 'react'
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ActiveInactiveStatusBadge, ActiveInactiveStatusCombo, ConfirmActionModal } from '../../Base/Common/CommonUIComponents';
import { Col, Modal, ModalBody, ModalHeader, Row, Button, Form } from 'reactstrap';
import CustomSelect from '../../Base/Common/CustomSelect';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import BlockUi from 'react-block-ui';

class RateBulkOperations extends Component {    
    constructor(props) {
        super(props);
       
        this.state = {
            columns: [
                {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "RateBulkOperations.RateCode" }),
                    sort: true
                }, 
                {
                    dataField: 'description',
                    text: this.props.intl.formatMessage({ id: "RateBulkOperations.RateDescription" }),
                    sort: true
                },
                {
                    dataField: 'rateLabelId',
                    text: this.props.intl.formatMessage({ id: "EditRate.Label" }),
                    sort: true,
                    formatter: (cell, row) => cell ? this.props.labels?.find(el => el.value === cell)?.label || cell : ''
                },
                {
                    dataField: 'active',
                    text: this.props.intl.formatMessage({ id: "generic.Status" }),
                    sort: true,
                    formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />
                }
            ],
            actions: [
                { value: 'deleteRates', label: <FormattedMessage id="RateBulkOperations.DeleteRates" />, disabled: false },
                { value: 'changeStatus', label: <FormattedMessage id="RateBulkOperations.ChangeStatus" />, disabled: false },
                { value: 'associateLabel', label: <FormattedMessage id="RateBulkOperations.AssociateLabel" />, disabled: false }
            ],
            request: {
                rateCodeIds: [],
                deleteRates: false,
                changeStatus: false,
                active: null,
                associateLabel: false,
                rateLabelId: null
            }
        };
    }

    saveBulkOperations = () => {
        this.setState({ block: true });

        if (this.state.request?.rateCodeIds?.length < 1) {
            handleNotification({ warnings: [{ code: '', message: <FormattedMessage id="RateBulkOperations.ThereMustBeAtLeast1RateSelected" /> }] });
            this.setState({ block: false });
            return;
        }

        const body = { ...this.state.request };

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else if (data.response && data.response.length > 0){
                    handleNotification(data, <FormattedMessage id="RateBulkOperations.RateCodesSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateRateCodeInBulk(body);
                    this.props.toggleModal();
                    this.setState({ block: false });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Rate/Rate/v1/bulkOperation`, body);
    }

    submitOperation = (event) => {
        event.preventDefault();

        if (this.state.request.deleteRates) {
            this.setState({ confirmDeleteModal: true })
        }
        else {
            this.saveBulkOperations();
        }
    }

    handleActions = (combo, action) => {
        const { actions, request } = this.state;

        if (action.action === "select-option") {
            actions.forEach(el => {
                el.disabled = action.option.value === 'deleteRates' ? el.value !== 'deleteRates' : el.value === 'deleteRates';
            });

            request[action.option.value] = true;
        }
        else if (!combo) {
            actions.forEach(el => {
                el.disabled = false;

                request[el.value] = false;
            })
        }
        else {
            request[action.option.value] = false;
        }

        if (action.option?.value === 'deleteRates' || action.removedValue?.value === 'deleteRates' || action.action === 'clear') {
            request.rateCodeIds = []
        }


        this.setState({ actions, request });
    }

    handleSelect = (name, combo) => {
        this.setState({
            request: {
                ...this.state.request,
                [name]: combo ? combo.value : null
            }
        })
    }

    selectRow = () => {
        return {
            mode: 'checkbox',
            bgColor: '#f8f9fa',
            clickToSelect: true,
            selected: [...this.state.request.rateCodeIds],
            onSelect: (row, isSelect, rowIndex, e) => {
                let rateCodeIds = [...this.state.request.rateCodeIds];
                if (isSelect) {
                    rateCodeIds.push(row.id);
                }
                else {
                    rateCodeIds = rateCodeIds.filter(el => el !== row.id);
                }

                this.setState({
                    request: {
                        ...this.state.request,
                        rateCodeIds
                    }
                });
            },
            onSelectAll: (isSelect, rows, e) => {
                if (isSelect) {
                    this.setState({
                        request: {
                            ...this.state.request,
                            rateCodeIds: rows.map(r => r.id)
                        }
                    });
                }
                else {
                    this.setState({
                        request: {
                            ...this.state.request,
                            rateCodeIds: []
                        }
                    });
                }
            }
        }
    };

    render() {
        const { rateList } = this.props;
        const { request, confirmDeleteModal } = this.state;

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false} className="modal-xl" >
                <ModalHeader toggle={this.props.toggleModal}>
                    <FormattedMessage id="RateBulkOperations.BulkOperations" />
                </ModalHeader>
                <ModalBody>
                    <BlockUi tag="div" blocking={this.state.block} >
                        <Form onSubmit={this.submitOperation}>
                            <Row>
                                <Col>
                                    <FormattedMessage id="RateBulkOperations.Actions" />
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm mb-1 mb-lg-0" type="submit" disabled={!request.rateCodeIds || request.rateCodeIds.length === 0}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 col-lg-6">
                                    <CustomSelect
                                        isSearchable
                                        placeholder={""}
                                        options={this.state.actions}
                                        onChange={(combo, action) => this.handleActions(combo, action)}
                                        value={this.state.actions.filter(el => this.state.request[el.value] === true)}
                                        isMulti
                                        required
                                    />
                                </Col>
                            </Row>

                            <Row className="my-3">
                                {request.changeStatus ?
                                    <Col className="col-12 col-lg-6">
                                        <div><FormattedMessage id="generic.Status" /></div>
                                        <ActiveInactiveStatusCombo
                                            name="active"
                                            value={request.active == null ? '' : request.active}
                                            onChangeFunc={this.handleSelect}
                                            required
                                        />
                                    </Col>
                                    : ''}

                                {request.associateLabel ?
                                    <Col className="col-12 col-lg-6">
                                        <div><FormattedMessage id="EditRate.Label" /></div>
                                        <CustomSelect
                                            options={this.props.labels}
                                            onChange={(e) => this.handleSelect('rateLabelId', e)}
                                            value={this.props.labels.find(lbl => lbl.value === request.rateLabelId) || ''}
                                            placeholder={<FormattedMessage id="EditRate.Label" />}
                                            isClearable
                                            isSearchable={true}
                                        />
                                    </Col>
                                    : ''}
                            </Row>
                        </Form>

                        <div className="mt-4">
                            <div className="mb-2"><FormattedMessage id="RateBulkOperations.SelectRates" /></div>
                            <CustomTable
                                data={request.deleteRates ? rateList.filter(el => !el.active) : rateList }
                                columns={this.state.columns}
                                search={true}
                                small={true}
                                exportCSV={false}
                                selectRow={this.selectRow()}
                            />
                        </div>
                    </BlockUi>
                </ModalBody>


                {confirmDeleteModal ?
                    <ConfirmActionModal
                        block={this.state.block}
                        error={this.state.error}
                        text={this.props.intl.formatMessage({ id: "RateBulkOperations.DeleteConfirmationText" })}
                        toggleModal={() => this.setState({ confirmDeleteModal: false })}
                        modal={confirmDeleteModal}
                        actionFunction={this.saveBulkOperations}
                    />
                : ''}
            </Modal>
        )
    }
}

export default injectIntl(RateBulkOperations)
