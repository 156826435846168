import React, { Component } from 'react';
import { Card, CardHeader, Col, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import AddRule from './AddRule';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { deleteAPI, getAPI, putAPI } from "../../Base/API";
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import CustomSelect from '../../Base/Common/CustomSelect';
import { RuleDetail } from './RuleDetail';
import { handleNotification } from '../../Base/Notification';
import RulesByRates from './RulesByRates';
import { CustomDateRangePicker } from '../../Base/Common/CustomReactDates';
import Authorization from '../../Base/Authorization';

export class RuleConfig extends Component {    

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onSaveModal = this.onSaveModal.bind(this);
        this.toggleModalToAddRule = this.toggleModalToAddRule.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.activateYieldRule = this.activateYieldRule.bind(this);
        this.deactivateYieldRule = this.deactivateYieldRule.bind(this);
        this.setYieldRuleStatus = this.setYieldRuleStatus.bind(this);
        this.copyYieldRule = this.copyYieldRule.bind(this);
        this.state = {
            CurrentStep: '1',
            modal: false,
            block: true,
            activeTab: '1',
            activeYieldRules: [],
            deactiveYieldRules: [],
            deletedYieldRules: [],
            ruleType: null,
            startDate: null,
            endDate: null,
            modalData: {},
            validYieldRulesTypes: [
                {
                    'label': <FormattedMessage id="AddRule.CloseBasedOnRoomsAvailable" />,
                    'value': 'Automatic Closure'
                },
                {
                    'label': <FormattedMessage id="AddRule.RestrictAvailability" />,
                    'value': 'Availability Limit'
                },
                {
                    'label': <FormattedMessage id="AddRule.IncreasePriceWhenSalesReachValue" />,
                    'value': 'Price Change'
                },
                {
                    'label': <FormattedMessage id="AddRule.CloseBasedOnReleaseDays" />,
                    'value': 'Release Days'
                },
                {
                    'label': <FormattedMessage id="AddRule.PriceOverride" />,
                    'value': 'Price Override'
                }
            ],
            validYieldRuleAvailabilityPricingType: [
                {
                    'label': <FormattedMessage id="AddRule.Amount" />,
                    'value': 'Amount'
                },
                {
                    'label': <FormattedMessage id="AddRule.Percent" />,
                    'value': 'Percent'
                }
            ],
            Steps: [
                { step: '1', active: true, enabled: true },
                { step: '2', active: false, enabled: false },
                { step: '3', active: false, enabled: false },
                { step: '4', active: false, enabled: false }]
        };
    }

    componentDidMount() {
        this.getYieldRule('/api/Rate/YieldRules/v1');
        const { validYieldRulesTypes } = this.state;
        if (global.modules.some(m => m === 'ComplexPriceRules')) {
            validYieldRulesTypes.push(
                {
                    'label': <FormattedMessage id="AddRule.PriceOverrideByComplex" />,
                    'value': 'Price Override By Complex Availability'
                });
            this.setState({validYieldRulesTypes});
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab }, () => tab == '3' ? this.doSearch() : '');
        }
    }

    toggleModal(tab, yieldRule) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            modalData: yieldRule ? yieldRule : {},
            toCreate: false
        }));
    }

    toggleModalToAddRule() {
        const newModalData = {
            channelInstanceIds: [],
            rateCodeIds: [],
            availabilityType: this.state.validYieldRuleAvailabilityPricingType[0].value,
            availabilityValue: 0,
            applyType: this.state.validYieldRuleAvailabilityPricingType[0].value,
            applyValue: 0,
            weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            releaseDays: 0,
            yieldRuleDates: [{ }]
        };
        this.setState({ modal: true, modalData: newModalData });
    }
    
    doSearch = (evt) => {
        this.setState({ block: true }, _ => {
            const ruleType = this.state.ruleType ? this.state.ruleType : '';
            const dateFrom = this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : '';
            const dateTo = this.state.endDate ? this.state.endDate.format('YYYY-MM-DD') : '';
            const getDeleted = this.state.activeTab === '3' ? true : false;
            if (getDeleted) this.getYieldRuleDeleted(`/api/Rate/YieldRules/v1?dateFrom=${dateFrom}&dateTo=${dateTo}&ruleType=${ruleType}&deleted=${getDeleted}`);
            else this.getYieldRule(`/api/Rate/YieldRules/v1?dateFrom=${dateFrom}&dateTo=${dateTo}&ruleType=${ruleType}`);
        });
    }

    getYieldRule(url) {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.response) {
                    this.setState({ block: false, activeYieldRules: data.response.filter(resp => resp.active && !resp.deleted), deactiveYieldRules: data.response.filter(resp => !resp.active && !resp.deleted) });
                }
                else {
                    this.setState({ block: false, error: errorMessage });
                }
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, url);
    }

    getYieldRuleDeleted(url) {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.response) {
                    this.setState({ block: false, deletedYieldRules: data.response.filter(resp => resp.deleted) });
                }
                else {
                    this.setState({ block: false, error: errorMessage });
                }
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, url);
    }

    filter(el) {
        this.setState({ ruleType: el ? el.value : null });
    }

    onSaveModal(newYieldRule) {
        const { activeYieldRules, deactiveYieldRules } = this.state;
        if (newYieldRule.active) {
            this.updateYieldRules(activeYieldRules, newYieldRule);
        }
        else {
            this.updateYieldRules(deactiveYieldRules, newYieldRule);
        }
        this.setState(({ activeYieldRules, deactiveYieldRules, modal: false }));
    }

    updateYieldRules(yieldRules, newYieldRule) {
        const currentYieldRule = yieldRules.find(yieldRule => yieldRule.id === newYieldRule.id);
        if (currentYieldRule) {
            const yieldRuleIdx = yieldRules.indexOf(currentYieldRule);
            yieldRules[yieldRuleIdx] = newYieldRule;
        }
        else {
            yieldRules.push(newYieldRule);
        }
    }

    getRuleType = (item) => {
        const ruleType = this.state.validYieldRulesTypes.find(rule => rule.value === item.ruleType);
        return ruleType ? ruleType.label : '';
    }

    activateYieldRule(id, idx) {
        function updateYieldRule(activeYieldRules, deactiveYieldRules) {
            const yieldRule = deactiveYieldRules.splice(idx, 1);
            activeYieldRules.push(yieldRule[0]);
        }
        this.setYieldRuleStatus(`/api/Rate/YieldRules/v1/${id}/enable`, updateYieldRule);        
    }

    deactivateYieldRule(id, idx) {
        function updateYieldRule(activeYieldRules, deactiveYieldRules) {
            const yieldRule = activeYieldRules.splice(idx, 1);
            deactiveYieldRules.push(yieldRule[0]);
        }
        this.setYieldRuleStatus(`/api/Rate/YieldRules/v1/${id}/disable`, updateYieldRule);        
    }

    setYieldRuleStatus(url, updateYieldRule) {
        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                handleNotification(data, <FormattedMessage id="RuleConfig.SuccessSetStatus" />, <FormattedMessage id="General.Success" />)
                const { activeYieldRules, deactiveYieldRules } = this.state;
                updateYieldRule(activeYieldRules, deactiveYieldRules);
                this.setState({ block: false, error: errorMessage, activeYieldRules, deactiveYieldRules });
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, url);
    }

    copyYieldRule(yieldRule) {
        const yieldRuleCopied = Object.assign({}, yieldRule);
        yieldRuleCopied.id = null;
        this.setState({ modal: true, modalData: yieldRuleCopied });
    }

    removeYieldRule = (yieldRule) => {
        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="RuleConfig.SuccessRemoveRule" />, <FormattedMessage id="General.Success" />)
                    const { activeYieldRules, deactiveYieldRules } = this.state;
                    const activeIdx = activeYieldRules.indexOf(yieldRule);
                    if (activeIdx >= 0) {
                        activeYieldRules.splice(activeIdx, 1);
                    }
                    else {
                        const deactiveIdx = deactiveYieldRules.indexOf(yieldRule);
                        deactiveYieldRules.splice(deactiveIdx, 1);
                    }
                    this.setState({ block: false, error: errorMessage, activeYieldRules, deactiveYieldRules });
                }
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, `/api/Rate/YieldRules/v1/${yieldRule.id}`);
    }

    render() {
        const { block, error, activeTab, activeYieldRules, deactiveYieldRules, modalData, deletedYieldRules } = this.state;

        return (
            <StyledCard title={"RuleConfig.RuleTitle"} icon="fas fa-list-ol" block={block} error={error} help={<FormattedHTMLMessage id="RuleConfig.Help" />} id="RuleConfig">
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModal}>{modalData.id ? <FormattedMessage id="RuleConfig.RuleDetails" /> : <FormattedMessage id="RuleConfig.AddRule" />}</ModalHeader>
                    <ModalBody className="modal-lg p-0">
                        <AddRule yieldRule={JSON.parse(JSON.stringify(this.state.modalData))} onSave={this.onSaveModal} validYieldRulesTypes={this.state.validYieldRulesTypes} validYieldRuleAvailabilityPricingType={this.state.validYieldRuleAvailabilityPricingType} />
                    </ModalBody>                  
                </Modal>

                <Card className="border-0 py-1 bg-white">
                    <CardHeader className="card-header-gridview bg-white border-0">
                        <Row>
                            <Col className="col-3 p-0">
                                <CustomSelect placeholder={<FormattedMessage id="RuleConfig.RuleType" />} options={this.state.validYieldRulesTypes} icon={'fas fa-sign'} value={this.state.validYieldRulesTypes.find(rule => rule.value === this.state.ruleType)} onChange={this.filter.bind(this)} isClearable />
                            </Col>
                            <Col>
                                <CustomDateRangePicker
                                    startDate={this.state.startDate}
                                    startDateId="reststartDate"
                                    isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment().add(20, 'year')}
                                    endDate={this.state.endDate}
                                    endDateId="restsendDate"
                                    onDatesChange={(startDate, endDate) => this.setState({ startDate, endDate })}
                                    numberOfMonths={2}
                                    showYearOptions={{ pastYears: true, futureYears: true }}
                                    futureYears={20}
                                />
                            </Col>

                            <Col className="col-2 p-0">
                                <button className="btn btn-host btn-sm text-white float-right mx-1" onClick={this.doSearch} > <i className="fas fa-search"></i> </button>
                                <button className="btn btn-host btn-sm text-white float-right  mx-1" onClick={this.toggleModalToAddRule} > <i className="fas fa-plus "></i> </button>
                            </Col>
                        </Row>
                        <hr/>
                    </CardHeader>
                </Card>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="RuleConfig.Active" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                            <FormattedMessage id="RuleConfig.Inactive" />
                        </NavLink>
                    </NavItem>
                    <Authorization
                        perform="yieldRules:deleted"
                        yes={() => (
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                    <FormattedMessage id="RuleConfig.Deleted" />
                                </NavLink>
                            </NavItem>
                        )}
                        no={() => <div></div>}
                    />
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                            <FormattedMessage id="RuleConfig.Rates" />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <RuleDetail
                            yieldRules={activeYieldRules}
                            toggleModal={this.toggleModal}
                            getRuleType={this.getRuleType}
                            setStatus={this.deactivateYieldRule}
                            actionTitle={`${this.props.intl.formatMessage({ id: "RuleConfig.Deactivate" })}/${this.props.intl.formatMessage({ id: "RuleConfig.Copy" })}/${this.props.intl.formatMessage({ id: "RuleConfig.Remove" })}`}
                            copyYieldRule={this.copyYieldRule}
                            removeYieldRule={this.removeYieldRule}
                            powerOffRuleText={value => <FormattedMessage id="RuleConfig.RemoveRuleConfirmText" values={{ ruleName: value }} /> }
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <RuleDetail
                            yieldRules={deactiveYieldRules}
                            toggleModal={this.toggleModal}
                            getRuleType={this.getRuleType}
                            setStatus={this.activateYieldRule}
                            actionTitle={`${this.props.intl.formatMessage({ id: "RuleConfig.Activate" })}/${this.props.intl.formatMessage({ id: "RuleConfig.Copy" })}/${this.props.intl.formatMessage({ id: "RuleConfig.Remove" })}`}
                            copyYieldRule={this.copyYieldRule}
                            removeYieldRule={this.removeYieldRule}
                            powerOffRuleText={value => <FormattedMessage id="RuleConfig.ActiveRuleConfirmText" values={{ ruleName: value }} />}
                        />
                    </TabPane>
                    <Authorization
                        perform="yieldRules:deleted"
                        yes={() => (
                            <TabPane tabId="3">
                                <RuleDetail
                                    yieldRules={deletedYieldRules}
                                    getRuleType={this.getRuleType}
                                    deleted={true}
                                />
                            </TabPane>
                        )}
                        no={() => <div></div>}
                    />
                    {(activeYieldRules && activeYieldRules.length > 0) || (deactiveYieldRules && deactiveYieldRules.length > 0) ?
                        <TabPane tabId="4">
                            <RulesByRates
                                yieldRules={activeYieldRules.concat(deactiveYieldRules)}
                                getRuleType={this.getRuleType}
                            />
                        </TabPane> : ''}
                </TabContent>
            </StyledCard>
        );
    }
}

export default injectIntl(RuleConfig)