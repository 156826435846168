import React, { Component } from 'react';
import { Row, Col, Badge, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';


const parsecChannels = [
    'ABREU',
    'EMVIAGEM',
    'LUSANOVA',
    'OLYMPIAEUROPE'
]

//#region map status
export function getMapStatus(intl, status, cd) {
    switch (status) {
        case 'success':
            return {
                value: 1,
                status: 'success',
                icon: <Badge color="success"> <i className="fas fa-check" /> </Badge>,
                airbnbIcon: <Badge color="success"> <i className="fas fa-check" /> </Badge>,
                label: intl.formatMessage({ id: "ChannelRoomRate.MappedSucessfully" })
            }
        case 'error':
            return {
                value: 2,
                status: 'error',
                icon: getErrorBadge(cd),
                airbnbIcon: <Badge color="danger"> <i className="fas fa-exclamation-triangle" /> </Badge>,
                label: intl.formatMessage({ id: "ChannelRoomRate.MappingDoesNotMatchPMSCodes" })
            }
        case 'warning':
            return {
                value: 3,
                status: 'warning',
                icon: getWarningBadge(cd),
                airbnbIcon: <Badge color="warning"> <i className="fas fa-exclamation-triangle text-white" /> </Badge>,
                label: intl.formatMessage({ id: "ChannelRoomRate.MappedWithIssues" })
            }
        case 'mapNotNeeded':
            return {
                value: 4,
                status: 'mapNotNeeded',
                icon: <Badge color="secondary"> <i className="fas fa-times text-white" style={{ padding: '0 2px' }} /> </Badge>,
                airbnbIcon: <Badge color="secondary"> <i className="fas fa-times text-white" style={{ padding: '0 2px' }} /> </Badge>,
                label: intl.formatMessage({ id: "ChannelRoomRate.MappingNotNeeded" })
            }
    }
}


export function checkMapStatus(channelDataAirbnb, channelData, rateCodeChannel, selectedChannel, hasAvailabilityGroupMapping, rateList, mapIncommingOffice) {

    if (rateCodeChannel) {
        if (selectedChannel.code.toUpperCase() === 'AIRBNB') {
            channelDataAirbnb.forEach(cd => {
                if (cd.roomCode) {
                    if (rateCodeChannel.some(el => el.roomTypeMapping === cd.roomCode)) {
                        if (cd.syncType === 'sync_rates_and_availability')
                            cd.mapStatus = 'success';
                        else {
                            if (cd.mappingNotNeeded)
                                cd.mapStatus = 'mapNotNeeded';
                            else
                                cd.mapStatus = 'warning';
                        }
                    }
                    else {
                        cd.mapStatus = checkErrorOrMNN(cd);
                    }
                    if (cd.mapStatus !== 'mapNotNeeded' && cd.mappingNotNeeded) {
                        cd.mapStatus2 = 'mapNotNeeded';
                    }
                }
            })
        }
        else if (hasAvailabilityGroupMapping) {
            channelData.forEach(cd => {
                if (cd.roomCode && cd.rateCode) {
                    if (rateCodeChannel.some(el => el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode && el.availabilityGroupMapping === cd.extraMapping)) {
                        cd.mapStatus = 'success';
                    }
                    else {
                        cd.mapStatus = checkErrorOrMNN(cd);
                    }
                    if (cd.mapStatus !== 'mapNotNeeded' && cd.mappingNotNeeded) {
                        cd.mapStatus2 = 'mapNotNeeded';
                    }
                }
            })
        }
        else if (selectedChannel.code.toUpperCase() === 'HOTELBEDS') {
            channelData.forEach(cd => {
                if (cd.roomCode && cd.rateCode && cd.hotelBedsSpecific) {
                    if (rateCodeChannel.some(el => el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode && el.availabilityGroupMapping === `${cd.hotelBedsSpecific.contractName}/${cd.hotelBedsSpecific.incommingOffice}/${cd.hotelBedsSpecific.sequence}`)) {
                        cd.mapStatus = 'success';
                    }
                    else {
                        cd.mapStatus = checkErrorOrMNN(cd);
                    }
                    if (cd.mapStatus !== 'mapNotNeeded' && cd.mappingNotNeeded) {
                        cd.mapStatus2 = 'mapNotNeeded';
                    }
                }
            })
        }
        else if (mapIncommingOffice) {
            channelData.forEach(cd => {
                if (cd.roomCode && cd.rateCode && cd.hotelBedsSpecific) {
                    if (rateCodeChannel.some(el => el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode && el.availabilityGroupMapping === `${cd.hotelBedsSpecific.incommingOffice}`)) {
                        cd.mapStatus = 'success';
                    }
                    else {
                        cd.mapStatus = checkErrorOrMNN(cd);
                    }
                    if (cd.mapStatus !== 'mapNotNeeded' && cd.mappingNotNeeded) {
                        cd.mapStatus2 = 'mapNotNeeded';
                    }
                }
            })
        }
        else if (parsecChannels.find(c => c === selectedChannel.code.toUpperCase())) {
            const rates = getRates(rateList);

            channelData.forEach(cd => {
                if (cd.roomCode && cd.rateCode) {
                    const rcc = rateCodeChannel.find(el => el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode);

                    if (rcc) {
                        const rate = rates.find(r => r.id == rcc.rateCodeId);

                        if (rate) {
                            if (rate.occupancies !== null && cd.occupancies !== null && checkOccupationsToSend(rcc.occupationsToSend, rate.occupancies, cd.occupancies)) {
                                cd.mapStatus = 'success';
                            }
                            else {
                                cd.mapStatus = 'warning';
                            }
                        }
                    }
                    else {
                        cd.mapStatus = checkErrorOrMNN(cd);
                    }
                    if (cd.mapStatus !== 'mapNotNeeded' && cd.mappingNotNeeded) {
                        cd.mapStatus2 = 'mapNotNeeded';
                    }
                }
            })
        }
        else {
            channelData.forEach(cd => {
                if (cd.roomCode && cd.rateCode) {
                    if (rateCodeChannel.some(el => el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode)) {
                        cd.mapStatus = 'success';
                    }
                    else {
                        cd.mapStatus = checkErrorOrMNN(cd);
                    }
                    if (cd.mapStatus !== 'mapNotNeeded' && cd.mappingNotNeeded) {
                        cd.mapStatus2 = 'mapNotNeeded';
                    }
                }
            })
        }
    }
    else {
        channelData.forEach(cd => {
            cd.mapStatus = checkErrorOrMNN(cd);
            if (cd.mapStatus !== 'mapNotNeeded' && cd.mappingNotNeeded) {
                cd.mapStatus2 = 'mapNotNeeded';
            }
        })
    }

    const errorCount = channelData.reduce((acc, cur) => cur.mapStatus === 'error' ? acc + 1 : acc, 0) + channelDataAirbnb.reduce((acc, cur) => cur.mapStatus === 'error' ? acc + 1 : acc, 0);
    
    return { channelData, channelDataAirbnb, errorCount };
}

//check if map has error or map not needed
const checkErrorOrMNN = (cd) => {
    if (cd.mappingNotNeeded) {
        return 'mapNotNeeded';
    }
    else {
        return 'error';
    }
}

const checkOccupationsToSend = (occupationsToSend, rateOccupancies, rowOccupancies) => {
    if (occupationsToSend && occupationsToSend.length > 0) {
        const occupancies = rateOccupancies.filter(rtOcc => occupationsToSend.some(occTS => rtOcc.id === occTS))

        return occupancies.length === rowOccupancies.length && checkOccupancies(occupancies, rowOccupancies);
    }
    else {
        return rateOccupancies.length === rowOccupancies.length && checkOccupancies(rateOccupancies, rowOccupancies);
    }
}

const checkOccupancies = (rateOccupancies, rowOccupancies) => {
    for (let i = 0; i < rateOccupancies.length; i++) {
        if (rateOccupancies[i].adults != rowOccupancies[i].adults || rateOccupancies[i].childrens != rowOccupancies[i].childrens) {
            return false;
        }
    }
    return true;
}

function getErrorBadge(row) {
    if (row && row.occupancies) {
        return (
            <span>
                <Badge color="danger" id={`ChannelRoomRateOccupanciesError${row.id}`}>
                    <i className="fas fa-exclamation-triangle" />
                </Badge>
                <UncontrolledTooltip target={`ChannelRoomRateOccupanciesError${row.id}`} modifiers={{ preventOverflow: { boundariesElement: 'window' } }} autohide={false}>
                    <Row>
                        <Col>
                            <FormattedMessage id="ChannelConf.Occupancies" />
                        </Col>
                    </Row>
                    {row.occupancies.map((occ, key) => <Row key={key}><Col><FormattedMessage id="ChannelConf.OccupanciesDesc" values={{ adults: occ.adults, children: occ.childrens }} /></Col></Row>)}
                </UncontrolledTooltip>
            </span>
        );
    }
    else {
        return (
            <Badge color="danger">
                <i className="fas fa-exclamation-triangle" />
            </Badge>
        );
    }
}

const getWarningBadge = (row) => {
    if (row && row.occupancies) {
        return (
            <span>
                <Badge color="warning" id={`ChannelRoomRateOccupanciesWarning${row.id}`}>
                    <i className="fas fa-exclamation-triangle text-white" />
                </Badge>
                <UncontrolledTooltip target={`ChannelRoomRateOccupanciesWarning${row.id}`} modifiers={{ preventOverflow: { boundariesElement: 'window' } }} autohide={false}>
                    <Row>
                        <Col>
                            <FormattedMessage id="ChannelConf.Occupancies" />
                        </Col>
                    </Row>
                    {row.occupancies.map((occ, key) => <Row key={key}><Col><FormattedMessage id="ChannelConf.OccupanciesDesc" values={{ adults: occ.adults, children: occ.childrens }} /></Col></Row>)}
                </UncontrolledTooltip>
            </span>
        );
    }
    else {
        return (
            <Badge color="warning">
                <i className="fas fa-exclamation-triangle text-white" />
            </Badge>
        );
    }
}


const getRates = (rateList) => {
    return rateList && rateList.flatMap(r => r.rateList).map(r => {
        const rate = {
            id: r.id,
            occupancies: r.occupancyList.map(occ => {
                const occupancy = {
                    id: occ.id,
                    adults: occ.adults,
                    childrens: occ.childrens
                };
                return occupancy;
            })
        };

        return rate;
    });
}

//#endregion