import React, { Component } from 'react';
import { Col, Row, Tooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { StopSalesTooltip } from './StopSalesTooltip';

export class RateAvailabilityBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            availabilityOrig: this.props.availability,
            availability: this.props.availability,
            priceVector: ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10', 'p11'],
            restrictiontooltip: false,
            stopSalesForChannelsTooltip: false,
            position: this.getPosition(this.props.position),
            restrictions: [],
            stopSalesChannels: null,
            dayRestrictionForChannel: null,
            dayRestriction: null,
            stopSalesForChannels:null
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.day !== this.props.day){
            this.calcRestrictions(nextProps);
        }
    }

    componentDidMount(){
        this.calcRestrictions(this.props);
    }

    calcRestrictions = (props) => {
        const { restriction, day, ratecodeChannelforRate, channelInstances } = props;
        const restrictions = restriction.filter(el => moment(el.hotelDate).isSame(day, 'date'));

        const stopSalesChannels = restrictions.filter(({channelInstanceId}) => channelInstanceId).reduce((acc, el) => {
            if (el.stopSales && ratecodeChannelforRate?.some(({channelInstanceId, active}) => channelInstanceId === el.channelInstanceId && active)) {
                const channel = channelInstances.find(ci => ci.id === el.channelInstanceId);
                if (channel) {
                    acc.push(channel?.name || '');
                }
            }

            return acc
        }, []);
        
        const stopSalesForChannels = stopSalesChannels?.length > 0;

        const dayRestriction = restrictions.find(el => !el.channelInstanceId);

        const hasRestForGeneralRate = restrictions.some((el) => !el.channelInstanceId);
        
        const restrictionList = ["closeToArrival", "closeToDeparture", "stopSales", "maximumStayLen", "minimumStayLen", "minAdvancedBookingOffset", "maxAdvancedBookingOffset"];

        const hasSameRestForAll = (res) => {
            return (
                restrictions
                    .filter(rstr => rstr.hotelDate === res.hotelDate && rstr.channelInstanceId !== res.channelInstanceId)
                    .reduce((acc, curr) => {
                        return acc &&
                            restrictionList.reduce((acc2, name) => {
                                return acc2 &&
                                    (
                                        (curr[name] === res[name])
                                        || (name === "minAdvancedBookingOffset" &&
                                            (
                                                (!curr[name] || curr[name] === 1) &&
                                                (!res[name] || res[name] === 1)
                                            )
                                        )
                                        || (!curr[name] && !res[name])
                                    );

                            }, true);
                    }, true)
            );
        }
        
        const dayRestrictionForChannel = restrictions.filter(({channelInstanceId}) => channelInstanceId).some(el => {
            const hasSameRestForAllChls = hasSameRestForAll(el);

            //caso existam restrições na tarifa geral e haja diferenças com as retições por canal verificar se são todas vazias(0, null ou false)
            if (hasRestForGeneralRate && !hasSameRestForAllChls) {
                const isAllEmptyOnChannels = restrictions.filter(rstr => rstr.channelInstanceId).reduce((acc, curr) => {
                    return acc &&
                        !(
                            curr.closeToArrival ||
                            curr.closeToDeparture ||
                            curr.stopSales ||
                            (curr.maximumStayLen && curr.maximumStayLen > 0) ||
                            (curr.minimumStayLen && curr.minimumStayLen > 0) ||
                            (curr.minAdvancedBookingOffset && curr.minAdvancedBookingOffset > 0) ||
                            (curr.maxAdvancedBookingOffset && curr.maxAdvancedBookingOffset > 0)
                        )
                }, true);

                return !isAllEmptyOnChannels;
            }
            //caso as restrições tenham sempre o mesmo valor nas mesmas entradas verificar se são todas vazias(0, null ou false)
            if (!hasRestForGeneralRate && hasSameRestForAllChls) {
                const isAllEmpty = restrictions.reduce((acc, curr) => {
                    return acc &&
                        !(
                            curr.closeToArrival ||
                            curr.closeToDeparture ||
                            curr.stopSales ||
                            (curr.maximumStayLen && curr.maximumStayLen > 0) ||
                            (curr.minimumStayLen && curr.minimumStayLen > 0) ||
                            (curr.minAdvancedBookingOffset && curr.minAdvancedBookingOffset > 0) ||
                            (curr.maxAdvancedBookingOffset && curr.maxAdvancedBookingOffset > 0)
                        )
                }, true);

                return !isAllEmpty;
            }
            //Não existe restrição para o dia se as restrições da tarifa geral e canal forem iguais
            else {
                return !(hasSameRestForAllChls && hasRestForGeneralRate);
            }
        });
        
        if (dayRestriction && dayRestriction.stopSales) {
            stopSalesChannels.unshift(<FormattedMessage id="RateAvailability.DefaultRate" />);
        }

        this.setState({ stopSalesChannels, dayRestrictionForChannel, dayRestriction, stopSalesForChannels, restrictions });
    }

    getPosition(adults) {
        const position = adults - 1;
        return position <= 10 ? position : 10;
    }

    onTodoChange(value) {
        this.setState({
            availability: value
        });
    }
    
    toggleState = (val) => {
        this.setState({ restrictiontooltip: val });
    }

    toggleStopSalesTooltipState = (val) => {
        this.setState({ stopSalesForChannelsTooltip: val });
    }

    render() {
        const { channelInstances, columnIndex, lineIndex, day, restriction, sameRestrictionForAllChannels, ratecodeChannelforRate, hasRestrictionForGeneralRate, getChildrenAmount } = this.props;
        const { restrictiontooltip, stopSalesForChannelsTooltip, restrictions, stopSalesChannels, dayRestrictionForChannel, dayRestriction, stopSalesForChannels } = this.state;
        var classCol = "position-top  border-right border-bottom max-with-14Col ";
        
        const displayRestrictionIcon = displayRestrictionIconFunc();

        var priceSelected = Object.keys(this.props.priceTemplate).filter(key =>
            this.props.priceTemplate[key].pricelistId === this.props.rateCode.pricelistId &&
            this.props.priceTemplate[key].roomCategoryId === this.props.rateCode.roomCategoryId &&
            this.props.priceTemplate[key].packageId === this.props.rateCode.packageId &&
            this.props.priceTemplate[key].rateCodeId === this.props.rateCode.id && 
            this.props.day.isSame(moment(this.props.priceTemplate[key].hotelDate), 'date')
        )
        
        var price = null;
        if (priceSelected.length > 0) {

            if (this.props.priceTemplate[priceSelected]) {
                if (this.state.position != null) {
                    price = this.props.priceTemplate[priceSelected][this.state.priceVector[this.state.position]];
                    if (this.props.children) {
                        const priceTemplate = this.props.priceTemplate[priceSelected];
                        price += getChildrenAmount(this.props.children, [priceTemplate.d1, priceTemplate.d2, priceTemplate.d3, priceTemplate.d4, priceTemplate.d5], priceTemplate.componentChildrenGroup2);
                    }
                }
                else {
                    for (var i = 0; i < this.state.priceVector.length; i++) {
                        price = this.props.priceTemplate[priceSelected][this.state.priceVector[i]] !== 0 ? this.props.priceTemplate[priceSelected][this.state.priceVector[i]] : price;
                    }
                }
                if (this.props.priceTemplate[priceSelected].isPriceModeComponent && price) {
                    price += ((this.state.position + 1) * this.props.priceTemplate[priceSelected].componentPrice);
                }
                if (price) {
                    price = price.toFixed(2);
                }
            }
        }

        return (
            <Col className={classCol} style={{ minWidth: '60px', 'cursor': this.props.isDependent === true ? '' : 'pointer', backgroundColor: `${dayRestrictionForChannel ? `#28a74526` : `transparent`}`}} onClick={() => this.props.selectRow(this, this.props.rateCode.id, this.props.rateCode.roomCategoryId, this.props.rateCode.blockId, this.props.isDependent ? this.props.isDependent : null, this.props.day, this.props.day)}>
                <div className="text-center font-weight-bold  text-uppercase ">
                    <Row>
                        <Col className="col-12 p-0">
                            <div className="w-100">
                                <div>
                                    <h6>{price ? price : '-'}</h6>
                                    {
                                        displayRestrictionIcon ?
                                            <div>
                                                <span className={'p-0 float-left fas fa-times-circle py-0 px-2 mr-1 ' + (dayRestriction.stopSales || stopSalesForChannels ? 'text-danger' : 'text-warning')}
                                                onMouseLeave={() => this.toggleState(false)} onMouseEnter={() => this.toggleState(true)}
                                                href="#" id={`restrictiontooltip${lineIndex}${columnIndex}`}></span>
                                                {
                                                    restrictiontooltip ?
                                                        <Tooltip placement="top" isOpen={restrictiontooltip} target={`restrictiontooltip${lineIndex}${columnIndex}`}>
                                                            {
                                                                dayRestriction && (dayRestriction.stopSales || stopSalesForChannels) ?
                                                                    <StopSalesTooltip
                                                                        stopSalesChannels={stopSalesChannels}
                                                                    />
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                dayRestriction && dayRestriction.closeToArrival ?
                                                                    <Row>
                                                                        <Col>
                                                                            <span className={'float-left text-white'}>
                                                                                <i className="fas fa-walking" style={{ marginLeft: '2px', marginRight: '7px' }} />
                                                                                <FormattedMessage id={"RateAvailability.CloseToArrival"} />
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                dayRestriction && dayRestriction.closeToDeparture ?
                                                                    <Row>
                                                                        <Col>
                                                                            <span className={'float-left text-white'}>
                                                                                <i className="fas fa-walking fa-flip-horizontal" style={{ marginLeft: '2px', marginRight: '7px' }} />
                                                                                <FormattedMessage id={"RateAvailability.CloseToDeparture"} />
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                dayRestriction && dayRestriction.minimumStayLen != null && dayRestriction.minimumStayLen > 1 ?
                                                                    <Row>
                                                                        <Col>
                                                                            <span className={'float-left text-white'}>
                                                                                <i className="fas fa-calendar-minus mr-1"/>
                                                                                <FormattedMessage id={"RateAvailability.MinStays"} /> {dayRestriction.minimumStayLen}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                dayRestriction && dayRestriction.maximumStayLen != null && dayRestriction.maximumStayLen > 0 ?
                                                                    <Row>
                                                                        <Col>
                                                                            <span className={'float-left text-white'}>
                                                                                <i className="fas fa-calendar-plus mr-1" />
                                                                                <FormattedMessage id={"RateAvailability.MaxStays"} /> {dayRestriction.maximumStayLen}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                dayRestriction && dayRestriction.minAdvancedBookingOffset != null && dayRestriction.minAdvancedBookingOffset > 0 ?
                                                                    <Row>
                                                                        <Col>
                                                                            <span className={'float-left text-white'}>
                                                                                <i className="fas fa-clock mr-1" />
                                                                                <FormattedMessage id={"RateAvailability.MinAdvanceBookingOffset"} /> {dayRestriction.minAdvancedBookingOffset}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                dayRestriction && dayRestriction.maxAdvancedBookingOffset != null && dayRestriction.maxAdvancedBookingOffset > 0 ?
                                                                    <Row>
                                                                        <Col>
                                                                            <span className={'float-left text-white'}>
                                                                                <i className="fas fa-clock mr-1" />
                                                                                <FormattedMessage id={"RateAvailability.MaxAdvanceBookingOffset"} /> {dayRestriction.maxAdvancedBookingOffset}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    ''
                                                            }
                                                        </Tooltip>
                                                        :
                                                        ''
                                                }
                                            </div>
                                            :
                                            ''
                                    }
                                    {
                                        stopSalesForChannels && !displayRestrictionIcon ?
                                            <div>
                                                <span className={'p-0 float-left fas fa-times-circle py-0 px-2 mr-1 text-danger'}
                                                    onMouseLeave={() => this.toggleStopSalesTooltipState(false)} onMouseEnter={() => this.toggleStopSalesTooltipState(true)}
                                                    href="#" id={`stopSalesForChannelsTooltip${this.props.lineIndex}${this.props.columnIndex}`}
                                                />
                                                {
                                                    stopSalesForChannelsTooltip ?
                                                        <Tooltip placement="top" isOpen={stopSalesForChannelsTooltip} target={`stopSalesForChannelsTooltip${lineIndex}${columnIndex}`}>
                                                            <StopSalesTooltip
                                                                stopSalesChannels={stopSalesChannels}
                                                            />
                                                        </Tooltip>
                                                        :
                                                        ''
                                                }
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        );


        function displayRestrictionIconFunc() {
            return dayRestriction && (
                dayRestriction.stopSales ||
                dayRestriction.closeToArrival || 
                dayRestriction.closeToDeparture || 
                (dayRestriction.minimumStayLen != null && dayRestriction.minimumStayLen > 1) ||
                (dayRestriction.maximumStayLen != null && dayRestriction.maximumStayLen > 0) ||
                (dayRestriction.minAdvancedBookingOffset != null && dayRestriction.minAdvancedBookingOffset > 0) ||
                (dayRestriction.maxAdvancedBookingOffset != null && dayRestriction.maxAdvancedBookingOffset > 0)
            );
        }
    }
};