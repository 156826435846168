const rules = {

    '437de0ae-5c7e-4d17-9a3a-b8bf2981c0cf': {
        static: [
            "sapo:reservations",
            "sapo:billing",
            "sapo:clients",
            "sapo:view",
            "CMClient"
        ]
    },

   /* writer: {
        static: [
            "posts:list",
            "posts:create",
            "users:getSelf",
            "home-page:visit",
            "dashboard-page:visit"
        ],
        dynamic: {
            "posts:edit": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            }
        }
    },*/

    '2cc9c7fc-8987-4680-b51b-f082d943058f': { // operation admin
        static: [
            "admin:view", "admin:config", "admin:maintenance",
            "heyTravelAI:view",
            "booking:promo",
            "booking:oportunity:view", "booking:promo:view",
            "extras:view",
            "reviews:view", "booking:reviews:view", "airbnb:reviews:view", "expedia:reviews:view", 
            "mailbox:view", "booking:message:view", "airbnb:message:view",
            "HotelConfig:facilitiesPolicies:view",
            "HotelConfig:roomDetail:view", "HotelConfig:complexDetail:view",
            "channel:addChannel",
            "channelConf:actions",
            "channelInfo:mapNeeded:edit",
            "channelInfo:airbnb:actions",
            "manageusers:edit",
            "reservation:deposit",
            "sapo:reservations",
            "sapo:billing",
            "sapo:clients",
            "sapo:view",
            "apiGatewayUser:view",
            "AdminUserManagement:view",
            "allProperties:changeProperty",
            "channelConfigDetail:save",
            "channelConfigState:save",
            "deadLetter:delete",
            "configAndAvailability:resendToPMS",
            "CMClient",
            "upgradeRequests:view",
            "hotelLicenseDetail:save",
            "hotelList:create",
            "hotelDetails:save",
            "hotelGroup:add",
            "hotelGroup:save",
            "hotelDetail:setHotelStatus",
            "analyticsAdmin:view",
            "rateCodeDetails:addAndEdit",
            "adminUserEmailReport:view",
            "recordsByEntityName:view",
            "paymentMenu:view",
            "paymentGateway:view",
            "payments:view",
            "payments:actions",
            "experience:view",
            "offers:view",
            "reservationDetailView:logs",
            "manualReservation:profileSearch",
            "channel:mapping:bulkOperations",
            "offers:add",
            "offers:edit",
            "offers:save",
            "experience:add",
            "airbnbReservation:cancel",
            "reservation:cc",
            "cancelPolicy:create",
            "yieldRules:view",
            "rate:history",
            "channel:mapping:history",
            "manualReservations:view",
            "compareAvailability:view",
            "reservationDetail:createdAt:view",
            "channel:editName",
            "reservation:remap",
            "channelConf:adminConfig",
            "inventory:majorBulkUpdate",
            "bulkUpdatePrices:save", 
            "promotions:add",
            "promotions:edit",
            "cancelPolicy:associateRate:edit",
            "emailReservationWebHook:edit",
            "channel:manageCurrencyConfig",
            "admin:checkPasswords",
            "yieldRules:deleted",
            "roomCategoryConfig:CityTax",
            "channel:resetCache"
        ],
        dynamic: {
            "rate:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "rate:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "category:add": () => { if (global.operationMode === 'None') return true; else return false; },
            "category:edit": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            //"channel:addChannel": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.operationMode === 'PmsFull') return true; else return false; },
            "Inventory:ARI:Update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "package:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:enable": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:delete": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "PriceListManagment:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "PriceListManagment:view": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "ota:azure:loadData": () => { if (global.channelList.find(el => el == 'SAPO' || el == 'HeyBE')) return true; else return false; },
            "category:view": () => { if (global.channelList.find(el => el == 'SAPO') || global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "inventory:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "cancellationPolicy:hasHeyBe": () => { if (global.channelList.find(el => el == 'HEYBE')) return true; else return false; },
            "propertyScore:airbnb": () => { if (global.channelList.find(el => el == 'AIRBNB')) return true; else return false; },
            "bulkUpdate:PricesAndRestrictions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "channelMapping:mapGoogleMappings": () => { if ((global.operationMode !== 'PmsFull' || global.PMSType === 'NewHotel') && global.modules.find(mdl => mdl === 'Google')) return true; else return false; },
            "channelMapping:manageGoogleMappings": () => { if (global.operationMode === 'PmsFull' && global.modules.find(mdl => mdl === 'Google')) return true; else return false; },
            "roomCategory:delete": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "comparepmsdata: view": () => { if (global.operationMode === 'PmsFull') return true; else return false; },
            "complexList:add": () => { if (global.operationMode === 'None') return true; else return false; },
            "roomCategorySetup:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "packageSetup:actions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; }
        }
    },

    'ded4f8f8-e327-4936-a5be-0e15f520906f': { //operations
        static: [
            "admin:view", "admin:config","admin:maintenance",
            "heyTravelAI:view",
            "booking:promo",
            "booking:oportunity:view", "booking:promo:view",
            "extras:view",
            "reviews:view", "booking:reviews:view", "airbnb:reviews:view", "expedia:reviews:view", 
            "mailbox:view", "booking:message:view", "airbnb:message:view",
            "HotelConfig:facilitiesPolicies:view",
            "HotelConfig:roomDetail:view", "HotelConfig:complexDetail:view",
            "channel:addChannel",
            "channelConf:actions",
            "channelInfo:mapNeeded:edit",
            "channelInfo:airbnb:actions",
            "manageusers:edit",
            "reservation:deposit",
            "sapo:reservations",
            "sapo:billing",
            "sapo:clients",
            "sapo:view",
            "apiGatewayUser:view",
            "AdminUserManagement:view",
            "allProperties:changeProperty",
            "configAndAvailability:resendToPMS",
            "CMClient",
            "recordsByEntityName:view",
            "paymentMenu:view",
            "paymentGateway:view",
            "payments:view",
            "payments:actions",
            "experience:view",
            "offers:view",
            "reservationDetailView:logs",
            "manualReservation:profileSearch",
            "channel:mapping:bulkOperations",
            "offers:add",
            "offers:edit",
            "offers:save",
            "experience:add",
            "airbnbReservation:cancel",
            "reservation:cc",
            "cancelPolicy:create",
            "yieldRules:view",
            "rate:history",
            "channel:mapping:history",
            "manualReservations:view",
            "compareAvailability:view",
            "reservationDetail:createdAt:view",
            "channel:editName",
            "reservation:remap",
            "channelConf:adminConfig",
            "inventory:majorBulkUpdate",
            "bulkUpdatePrices:save",
            "promotions:add",
            "promotions:edit",
            "cancelPolicy:associateRate:edit",
            "hotelDetails:save",
            "channel:manageCurrencyConfig",
            "yieldRules:deleted",
            "roomCategoryConfig:CityTax",
            "channel:resetCache"
        ],
        dynamic: {
            "rate:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "rate:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "category:add": () => { if (global.operationMode === 'None') return true; else return false; },
            "category:edit": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            //"channel:addChannel": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.operationMode === 'PmsFull') return true; else return false; },
            "Inventory:ARI:Update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "package:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:enable": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:delete": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "PriceListManagment:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "PriceListManagment:view": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "ota:azure:loadData": () => { if (global.channelList.find(el => el == 'SAPO' || el == 'HeyBE')) return true; else return false; },
            "category:view": () => { if (global.channelList.find(el => el == 'SAPO') || global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "inventory:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "cancellationPolicy:hasHeyBe": () => { if (global.channelList.find(el => el == 'HEYBE')) return true; else return false; },
            "propertyScore:airbnb": () => { if (global.channelList.find(el => el == 'AIRBNB')) return true; else return false; },
            "bulkUpdate:PricesAndRestrictions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "channelMapping:mapGoogleMappings": () => { if ((global.operationMode !== 'PmsFull' || global.PMSType === 'NewHotel') && global.modules.find(mdl => mdl === 'Google')) return true; else return false; },
            "channelMapping:manageGoogleMappings": () => { if (global.operationMode === 'PmsFull' && global.modules.find(mdl => mdl === 'Google') || global.PMSType === 'NewHotel') return true; else return false; },
            "roomCategory:delete": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "comparepmsdata: view": () => { if (global.operationMode === 'PmsFull') return true; else return false; },
            "complexList:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "roomCategorySetup:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "packageSetup:actions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; }
        }
    },
    'e2c23135-8904-4d26-93fa-d9573ee19d2d': {  // hotel  manager
        static: [
            "channel:addChannel",
            "channelConf:actions",
            "channelInfo:mapNeeded:edit",
            "channelInfo:airbnb:actions",
            "booking:promo",
            "booking:oportunity:view","booking:promo:view",
            "extras:view",
            "reviews:view", "booking:reviews:view", "airbnb:reviews:view", "expedia:reviews:view", 
            "mailbox:view", "booking:message:view", "airbnb:message:view",
            "HotelConfig:facilitiesPolicies:view",
            "HotelConfig:roomDetail:view", "HotelConfig:complexDetail:view",
            "manageusers:edit",
            "reservation:deposit",
            "CMClient",
            "paymentMenu:view",
            "paymentGateway:view",
            "payments:view",
            "payments:actions",
            "channel:mapping:bulkOperations",
            "offers:add",
            "experience:add",
            "offers:edit",
            "offers:save",
            "yieldRules:view",
            "reservation:cc",
            "cancelPolicy:create",
            "manualReservations:view",
            "channel:editName",
            "inventory:majorBulkUpdate",
            "bulkUpdatePrices:save",
            "promotions:add",
            "promotions:edit",
            "cancelPolicy:associateRate:edit"
        ],
        dynamic: {
            "rate:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false;},
            "rate:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "category:add": () => { if (global.operationMode === 'None') return true; else return false; },
            "category:edit": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            //"channel:addChannel": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "Inventory:ARI:Update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "package:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:enable": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "PriceListManagment:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "PriceListManagment:view": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "ota:azure:loadData": () => { if (global.channelList.find(el => el == 'SAPO' || el == 'HeyBE')) return true; else return false; },
            "category:view": () => { if (global.channelList.find(el => el == 'SAPO') || global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "inventory:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "cancellationPolicy:hasHeyBe": () => { if (global.channelList.find(el => el == 'HEYBE')) return true; else return false; },
            "propertyScore:airbnb": () => { if (global.channelList.find(el => el == 'AIRBNB')) return true; else return false; },
            "bulkUpdate:PricesAndRestrictions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "channelMapping:mapGoogleMappings": () => { if ((global.operationMode !== 'PmsFull' || global.PMSType === 'NewHotel') && global.modules.find(mdl => mdl === 'Google')) return true; else return false; },
            "channelMapping:manageGoogleMappings": () => { if (global.operationMode === 'PmsFull' && global.modules.find(mdl => mdl === 'Google') || global.PMSType === 'NewHotel') return true; else return false; },
            "roomCategory:delete": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "complexList:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "roomCategorySetup:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "packageSetup:actions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; }
        }
    },
    'f7d0725a-bdb6-44fc-b2ce-5a74e69dd89c': {  // Price Management
        static: [
            "channel:addChannel",
            "channelConf:actions",
            "channelInfo:mapNeeded:edit",
            "channelInfo:airbnb:actions",
            "booking:promo",
            "booking:oportunity:view", "booking:promo:view",
            "extras:view",
            "reviews:view", "booking:reviews:view", "airbnb:reviews:view", "expedia:reviews:view", 
            "mailbox:view", "booking:message:view", "airbnb:message:view",
            "HotelConfig:facilitiesPolicies:view",
            "HotelConfig:roomDetail:view", "HotelConfig:complexDetail:view",
            "manageusers:edit",
            "reservation:deposit",
            "CMClient",
            "paymentMenu:view",
            "payments:view",
            "payments:actions",
            "yieldRules:view",
            "manualReservations:view",
            "reservation:cc",
            "channel:editName",
            "inventory:majorBulkUpdate",
            "bulkUpdatePrices:save",
            "promotions:add",
            "promotions:edit",
            "cancelPolicy:associateRate:edit"
        ],
        dynamic: {
            "rate:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "rate:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "category:add": () => { if (global.operationMode === 'None') return true; else return false; },
            "category:edit": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            //"channel:addChannel": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "Inventory:ARI:Update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "package:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:enable": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "PriceListManagment:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "PriceListManagment:view": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "ota:azure:loadData": () => { if (global.channelList.find(el => el == 'SAPO' || el == 'HeyBE')) return true; else return false; },
            "category:view": () => { if (global.channelList.find(el => el == 'SAPO') || global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "inventory:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "cancellationPolicy:hasHeyBe": () => { if (global.channelList.find(el => el == 'HEYBE')) return true; else return false; },
            "propertyScore:airbnb": () => { if (global.channelList.find(el => el == 'AIRBNB')) return true; else return false; },
            "bulkUpdate:PricesAndRestrictions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "channelMapping:mapGoogleMappings": () => { if ((global.operationMode !== 'PmsFull' || global.PMSType === 'NewHotel') && global.modules.find(mdl => mdl === 'Google')) return true; else return false; },
            "channelMapping:manageGoogleMappings": () => { if (global.operationMode === 'PmsFull' && global.modules.find(mdl => mdl === 'Google') || global.PMSType === 'NewHotel') return true; else return false; },
            "complexList:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "roomCategorySetup:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "packageSetup:actions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; }
        }
    },
    '4b836682-0839-4a47-8299-5ca9f9139eea': {  // Marketing Management
        static: [
            "booking:promo",
            "booking:oportunity:view", "booking:promo:view",
            "extras:view",
            "reviews:view", "booking:reviews:view", "airbnb:reviews:view", "expedia:reviews:view", 
            "mailbox:view", "booking:message:view", "airbnb:message:view",
            "HotelConfig:facilitiesPolicies:view",
            "HotelConfig:roomDetail:view", "HotelConfig:complexDetail:view",
            "manageusers:edit",
            "reservation:deposit",
            "CMClient",
            "yieldRules:view",
            "channel:editName"
        ],
        dynamic: {
            "rate:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "rate:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "category:add": () => { if (global.operationMode === 'None') return true; else return false; },
            "category:edit": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            //"channel:addChannel": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "Inventory:ARI:Update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "package:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:enable": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:update": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "PriceListManagment:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "PriceListManagment:view": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "ota:azure:loadData": () => { if (global.channelList.find(el => el == 'SAPO' || el == 'HeyBE')) return true; else return false; },
            "category:view": () => { if (global.channelList.find(el => el == 'SAPO') || global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "inventory:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "cancellationPolicy:hasHeyBe": () => { if (global.channelList.find(el => el == 'HEYBE')) return true; else return false; },
            "propertyScore:airbnb": () => { if (global.channelList.find(el => el == 'AIRBNB')) return true; else return false; },
            "bulkUpdate:PricesAndRestrictions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited') return true; else return false; },
            "channelMapping:mapGoogleMappings": () => { if ((global.operationMode !== 'PmsFull' || global.PMSType === 'NewHotel') && global.modules.find(mdl => mdl === 'Google')) return true; else return false; },
            "channelMapping:manageGoogleMappings": () => { if (global.operationMode === 'PmsFull' && global.modules.find(mdl => mdl === 'Google') || global.PMSType === 'NewHotel') return true; else return false; },
            "complexList:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "roomCategorySetup:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "packageSetup:actions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; }
        }
    },
    'b6afe5cc-2f35-4cd1-acdc-8f58ee39f82d': {  // Reservation Management
        static: [
            "channel:addChannel",
            "booking:promo",
            "booking:oportunity:view", "booking:promo:view",
            "extras:view",
            "reviews:view", "booking:reviews:view", "airbnb:reviews:view", "expedia:reviews:view", 
            "mailbox:view", "booking:message:view", "airbnb:message:view",
            "HotelConfig:facilitiesPolicies:view",
            "HotelConfig:roomDetail:view", "HotelConfig:complexDetail:view",
            "reservation:deposit",
            "manualReservations:view",
            "CMClient",
            "paymentMenu:view",
            "payments:view",
            "payments:actions"
        ],
        dynamic: {
            "cancellationPolicy:hasHeyBe": () => { if (global.channelList.find(el => el == 'HEYBE')) return true; else return false; },
            "propertyScore:airbnb": () => { if (global.channelList.find(el => el == 'AIRBNB')) return true; else return false; }
        }
    },
    "33f374b2-eae8-4c93-9e18-c9022eada62b": {  // Booking Engine Only
        static: [
            "CMClient",
            "bookingEngineOnly:view",

            "channelConf:actions",
            "channelInfo:mapNeeded:edit",
            "channel:mapping:bulkOperations",
                        
            "promotions:add",
            "promotions:edit",

            "offers:add",
            "offers:edit",
            "offers:save",

            "experience:add",

            "cancelPolicy:create",
            "cancelPolicy:associateRate:edit"
        ],
        dynamic: {
            "channel:enable": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:add": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channel:mapping:edit": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate) || global.PMSType === 'NewHotel') return true; else return false; },
            "channelMapping:mapGoogleMappings": () => { if ((global.operationMode !== 'PmsFull' || global.PMSType === 'NewHotel') && global.modules.find(mdl => mdl === 'Google')) return true; else return false; },
            "channelMapping:manageGoogleMappings": () => { if (global.operationMode === 'PmsFull' && global.modules.find(mdl => mdl === 'Google') || global.PMSType === 'NewHotel') return true; else return false; },
            "complexList:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "roomCategorySetup:add": () => { if (global.operationMode === 'None' || global.PMSType === 'NewHotel') return true; else return false; },
            "packageSetup:actions": () => { if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'NewHotel') return true; else return false; }
        }
    },
    '2fb7fbe0-22e9-4fa4-ab27-47b139c55999': {  // View Only
        static: [
            "booking:promo",
            "booking:oportunity:view", "booking:promo:view",
            "extras:view",
            "reviews:view", "booking:reviews:view", "airbnb:reviews:view", "expedia:reviews:view", 
            "mailbox:view", "booking:message:view", "airbnb:message:view",
            "HotelConfig:facilitiesPolicies:view",
            "HotelConfig:roomDetail:view", "HotelConfig:complexDetail:view",
            "reservation:deposit",
            "CMClient",
            "paymentMenu:view",
            "payments:view"
        ],
        dynamic: {
            "cancellationPolicy:hasHeyBe": () => { if (global.channelList.find(el => el == 'HEYBE')) return true; else return false; },
            "propertyScore:airbnb": () => { if (global.channelList.find(el => el == 'AIRBNB')) return true; else return false; }
        }
    },
    'e276ce97-a0b7-40ca-b84a-1250aa808f72': {  // dummy
        static: [
            "booking:promo",
            "booking:oportunity:view",
            "extras:view",
            "reviews:view", "booking:reviews:view", "airbnb:reviews:view", "expedia:reviews:view", 
            "mailbox:view", "booking:message:view", "airbnb:message:view",
            "HotelConfig:facilitiesPolicies:view",
            "HotelConfig:complexDetail:view",
            "HotelConfig:roomDetail:view",
            "inventory:edit",
            
            "configuration:accouting:view",
            "ota:azure:loadData",
            "category:add",
            "category:edit",
            "rate:add",
            "rate:edit",
            "CMClient"
        ]
    }
};

export default rules;