import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { RestrictionBox } from './RestrictionBox';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import CustomSelect from './../Base/Common/CustomSelect'
import { RestrictionBoxCheck } from './RestrictionBoxCheck';

export class ChannelBar extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            channelsOptions: []
        };
    }

    componentDidMount(){
        const { channels, getChannelInstanceName, channelInstance, restriction } = this.props;
        const channelsOptions = channels
            .reduce((acc, curr) => {
                if (acc.find(a => a.value === curr.channelInstanceId))
                    return acc;

                const hasRestForGeneralRate = restriction.some((el) => !el.channelInstanceId &&
                    (
                        el.closeToArrival ||
                        el.closeToDeparture ||
                        el.stopSales ||
                        (el.maximumStayLen && el.maximumStayLen > 0) ||
                        (el.minimumStayLen && el.minimumStayLen > 0) ||
                        (el.minAdvancedBookingOffset && el.minAdvancedBookingOffset > 0) ||
                        (el.maxAdvancedBookingOffset && el.maxAdvancedBookingOffset > 0)
                    )
                );

                const restrictionList = ["closeToArrival", "closeToDeparture", "stopSales", "maximumStayLen", "minimumStayLen", "minAdvancedBookingOffset", "maxAdvancedBookingOffset"];

                const hasSemaRestForAll = (res) => {
                    return (
                        restriction.filter(({ channelInstanceId }) => channelInstanceId !== curr.channelInstanceId)
                            .reduce(
                                (acc, curr) =>
                                    acc &&
                                    restrictionList.reduce((acc2, name) =>
                                        acc2 &&
                                        (
                                            (curr[name] === res[name]) ||
                                            (name === "minAdvancedBookingOffset" &&
                                                (
                                                    (!curr[name] || curr[name] === 1) &&
                                                    (!res[name] || res[name] === 1)
                                                )
                                            ) ||
                                            (!curr[name] && !res[name])
                                        ), true
                                    ), true
                            ), false
                    );
                }

                const restrictions = restriction.filter(({ channelInstanceId }) => channelInstanceId === curr.channelInstanceId).filter(el => {
                    if (!(el.closeToArrival || el.closeToDeparture || el.stopSales || (el.maximumStayLen && el.maximumStayLen > 0) ||
                        (el.minimumStayLen && el.minimumStayLen > 0) || (el.minAdvancedBookingOffset && el.minAdvancedBookingOffset > 0) ||
                        (el.maxAdvancedBookingOffset && el.maxAdvancedBookingOffset > 0))) { // Se não tiver restrições
                        return hasRestForGeneralRate; // se não tiver restrições para a rate
                    }

                    return !hasSemaRestForAll(el); //se tiver para todos os canais a mesma rest é default
                });

                if (restrictions.length === 0)
                    return acc;

                if (getChannelInstanceName(channelInstance, curr.channelInstanceId) !== '') {
                    const channelOption = {
                        value: curr.channelInstanceId,
                        label: `${getChannelInstanceName(channelInstance, curr.channelInstanceId)}(${restrictions.length})`
                    };
                    return [...acc, channelOption];
                }
                return acc;
            }, []);
        
        this.setState({ channelsOptions });
    }

    render() {
        const { channels, restriction, channel, setChannel } = this.props;
        const { channelsOptions } = this.state;

        const noDataForChannel = (item, rest) => {
            const day = restriction.filter(r => item.isSame(moment(r.hotelDate), 'date'));
            const wChannel = day?.filter(r => r.channelInstanceId === channel);
            const noRestriction = wChannel?.find(r => r[rest] !== null && r[rest] !== undefined);
            return !noRestriction;
        }

		const func = (el, item, rest) => {
            if(!item.isSame(moment(el.hotelDate), 'date'))
                return false;

            if(el[rest] === null || el[rest] === undefined)
                return false;
            
            if(el.channelInstanceId === channel)
                return true;

            if(el.channelInstanceId || !noDataForChannel(item, rest))
                return false
            return true
        };
        
		return (
            <div>
                <Row className="flex-nowrap">
                    <Col className="col-3 border-right position-sticky" style={{ left: '0', zIndex: '2', background: 'white' }}>
                        <Row className="">
                            <Col className="col-9">
                                <div className="d-inline-block w-100">
                                    <span className="strong font_size_xxs "> </span>
									{
										channelsOptions?.length > 0 ?
                                            <CustomSelect className="mt-1" options={channelsOptions} value={channels.find(c => c.value === channel)} onChange={setChannel} placeholder={<FormattedMessage id="ChannelBar.ChooseChannel" />} isClearable/>
											:
											<span />
									}
                                </div>
                            </Col>
                            <Col>
                                <p className="strong font_size_xxs text-right">
                                    <FormattedMessage id="ChannelBar.StopSell" />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {
                        this.props.next14Days && this.props.next14Days.map((item, key) => {
                            const r = restriction.filter(el => func(el, item, "stopSales"));
                            return (
                                <RestrictionBoxCheck
                                    key={key}
                                    fromRate={r.length > 0 && !r[0].channelInstanceId && r[0].stopSales && channel}
                                    value={r.length > 0 ? r[0].stopSales : ''}
                                    toggleModal={() => this.props.selectRow(this, this.props.rateCodeId, this.props.roomCategoryId, this.props.blockId, this.props.applicablepricelist && this.props.applicablepricelist.dependencyMasterId, item, item)}
                                    isDependent={this.props.isDependent}
                                />    
                            );
                        })
                    }
                </Row>
                <Row className="flex-nowrap">
                    <Col className="col-3 border-right position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                        <Row className="">
                            <Col className="col-9">
                                <div className="d-inline-block w-100">
                                    <span className="strong font_size_xxs "/>
                                </div>
                            </Col>
                            <Col>
                                <p className="strong font_size_xxs text-right">
                                    <FormattedMessage id="ChannelBar.CTD" />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {
                        this.props.next14Days && this.props.next14Days.map((item, key) => {
							const r = restriction.filter(el => func(el, item, "closeToDeparture"));
                            return (
                                <RestrictionBoxCheck
									key={key}
                                    fromRate={r.length > 0 && !r[0].channelInstanceId && r[0].closeToDeparture && channel}
									value={r.length > 0 ? r[0].closeToDeparture : ''}
									toggleModal={() => this.props.selectRow(this, this.props.rateCodeId, this.props.roomCategoryId, this.props.blockId, this.props.applicablepricelist && this.props.applicablepricelist.dependencyMasterId, item, item)}
									isDependent={this.props.isDependent}
								/>
							);
						})
                    }
                </Row>
                <Row className="flex-nowrap">
                    <Col className="col-3 border-right position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                        <Row className="">
                            <Col className="col-9">
                                <div className="d-inline-block w-100">
                                    <span className="strong font_size_xxs "/>
                                </div>
                            </Col>
                            <Col>
                                <p className="strong font_size_xxs text-right">
                                    <FormattedMessage id="ChannelBar.CTA" />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {
                        this.props.next14Days && this.props.next14Days.map((item, key) => {
							const r = restriction.filter(el => func(el, item, "closeToArrival"));
                            return (
                                <RestrictionBoxCheck
									key={key}
                                    fromRate={r.length > 0 && !r[0].channelInstanceId && r[0].closeToArrival && channel}
									value={r.length > 0 ? r[0].closeToArrival : ''}
									toggleModal={() => this.props.selectRow(this, this.props.rateCodeId, this.props.roomCategoryId, this.props.blockId, this.props.applicablepricelist && this.props.applicablepricelist.dependencyMasterId, item, item)}
									isDependent={this.props.isDependent}
								/>
							);
						})
                    }
                </Row>
                <Row className="flex-nowrap">
                    <Col className="col-3 border-right position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                        <Row className="">
                            <Col className="col-9">
                                <div className="d-inline-block w-100">
                                    <span className="strong font_size_xxs "> </span>
                                </div>
                            </Col>
                            <Col>
                                <p className="strong font_size_xxs text-right">
                                    <FormattedMessage id="ChannelBar.MinStays" />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {
                        this.props.next14Days && this.props.next14Days.map((item, key) => {
                            const r = restriction.filter(el => func(el, item, "minimumStayLen"));
							return(
								<RestrictionBox
									key={key}
									element='minstays'
                                    fromRate={r.length > 0 && !r[0].channelInstanceId && r[0].minimumStayLen && channel}
									value={r.length > 0 ? r[0].minimumStayLen : ''}
									toggleModal={() => this.props.selectRow(this, this.props.rateCodeId, this.props.roomCategoryId, this.props.blockId, this.props.applicablepricelist && this.props.applicablepricelist.dependencyMasterId, item, item)}
									isDependent={this.props.isDependent}
								/>
							);
						})
                    }
                </Row>
                <Row className="flex-nowrap">
                    <Col className="col-3 border-right position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                        <Row className="">
                            <Col className="col-9">
                                <div className="d-inline-block w-100">
                                    <span className="strong font_size_xxs "> </span>
                                </div>
                            </Col>
                            <Col>
                                <p className="strong font_size_xxs text-right">
                                    <FormattedMessage id="ChannelBar.MaxStays" />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {
                        this.props.next14Days && this.props.next14Days.map((item, key) => {
							const r = restriction.filter(el => func(el, item, "maximumStayLen"));
							return(
								<RestrictionBox
									key={key}
									element='maxstays'
                                    fromRate={r.length > 0 && !r[0].channelInstanceId && r[0].maximumStayLen && channel}
									value={r.length > 0 ? r[0].maximumStayLen : ''}
									toggleModal={() => this.props.selectRow(this, this.props.rateCodeId, this.props.roomCategoryId, this.props.blockId, this.props.applicablepricelist && this.props.applicablepricelist.dependencyMasterId, item, item)}
									isDependent={this.props.isDependent}
								/>
							);
						})
                    }
                </Row>
                <Row className="flex-nowrap">
                    <Col className="col-3 border-right position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                        <Row>
                            <Col className="text-right">
                                <p className="strong font_size_xxs text-right">
                                    <FormattedMessage id="ChannelBar.MinAdvanceBookingOffset" />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {
                        this.props.next14Days && this.props.next14Days.map((item, key) => {
							const r = restriction.filter(el => func(el, item, "minAdvancedBookingOffset"));
							return(
								<RestrictionBox 
									key={key}
									element='minadvancebbokingoffset'
                                    fromRate={r.length > 0 && !r[0].channelInstanceId && r[0].minAdvancedBookingOffset && channel}
                                    value={r.length > 0 ? r[0].minAdvancedBookingOffset : ''}
									toggleModal={() => this.props.selectRow(this, this.props.rateCodeId, this.props.roomCategoryId, this.props.blockId, this.props.applicablepricelist && this.props.applicablepricelist.dependencyMasterId, item, item)}
									isDependent={this.props.isDependent}
								/>
							);
						})
                    }
                </Row>
                <Row className="flex-nowrap">
                    <Col className="col-3 border-right position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                        <Row>
                            <Col className="text-right">
                                <p className="strong font_size_xxs text-right">
                                    <FormattedMessage id="ChannelBar.MaxAdvanceBookingOffset" />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {
                        this.props.next14Days && this.props.next14Days.map((item, key) => {
							const r = restriction.filter(el => func(el, item, "maxAdvancedBookingOffset"));
							return(
								<RestrictionBox
									key={key}
									element='maxadvancebbokingoffset'
                                    fromRate={r.length > 0 && !r[0].channelInstanceId && r[0].maxAdvancedBookingOffset && channel}
                                    value={r.length > 0 ? r[0].maxAdvancedBookingOffset : ''}
									toggleModal={() => this.props.selectRow(this, this.props.rateCodeId, this.props.roomCategoryId, this.props.blockId, this.props.applicablepricelist && this.props.applicablepricelist.dependencyMasterId, item, item)}
									isDependent={this.props.isDependent}
								/>
							);
						})
                    }
                </Row>
            </div>
        );
    }
};
