import React, { Component } from 'react';
import { Button, CardHeader, Row, Col, Card, CardBody, Modal, ModalBody } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';
import { ConfirmActionModal } from '../../Base/Common/CommonUIComponents';

export class RuleDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            toggleStatusModal: false
        }
    }

    toggleModal = (rule, modal, index) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedRule: rule,
            selectedRuleIndex: index
        }));
    }

    render() {
        const { yieldRules, toggleModal, getRuleType, setStatus, actionTitle, copyYieldRule, removeYieldRule, deleted, powerOffRuleText } = this.props;

        return (
            <div>
                {this.state.modal &&
                    <ConfirmActionModal
                        text={<>
                            <i className="fas fa-info-circle mr-2" style={{ color: 'orange' }} /><FormattedHTMLMessage id="RuleConfig.RemoveRuleNote" />
                            <br /><br />
                            <FormattedMessage id="RuleConfig.RemoveRuleConfirmText" values={{ ruleName: this.state.selectedRule?.name }} />
                            </>
                        }
                        toggleModal={_ => this.toggleModal(null, 'modal')}
                        modal={this.state.modal}
                        actionFunction={_ => { removeYieldRule(this.state.selectedRule); this.toggleModal(null, 'modal') }}
                    />
                }
                {this.state.toggleStatusModal &&
                    <ConfirmActionModal
                        text={<>
                            <i className="fas fa-info-circle mr-2" style={{ color: 'orange' }} /><FormattedHTMLMessage id="RuleConfig.ChangeRuleStatusNote" />
                                <br /><br />
                                {powerOffRuleText(this.state.selectedRule?.name)}
                            </>
                        }
                        toggleModal={_ => this.toggleModal(null, 'toggleStatusModal')}
                        modal={this.state.toggleStatusModal}
                        actionFunction={_ => { setStatus(this.state.selectedRule.id, this.state.selectedRuleIndex); this.toggleModal(null, 'toggleStatusModal') }}
                    />
                }

                <Card className="small mb-2 shadow border-top-0 border-right-0 border-left-0">
                    <CardHeader className="bg-white ">
                        <Row>
                            <Col><FormattedMessage id="RuleConfig.RuleName" /></Col>
                            <Col><FormattedMessage id="RuleConfig.RuleType" /></Col>
                            <Col><FormattedMessage id="RuleConfig.Rates" /></Col>
                            <Col><FormattedMessage id="RuleConfig.Channels" /></Col>
                            <Col><FormattedMessage id="RuleConfig.Dates" /></Col>
                            <Col><FormattedMessage id="RuleConfig.Rules" /></Col>
                            {deleted ? '' : <Col className="col-2 text-center">{actionTitle}</Col> }
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {
                            yieldRules && yieldRules.map((item, key) =>
                                <div key={key}>
                                    <Row>
                                        <Col onClick={tab => deleted ? '' : toggleModal(tab, item)}>{item.name}</Col>
                                        <Col onClick={tab => deleted ? '' : toggleModal(tab, item)}>{getRuleType(item)}</Col>
                                        <Col onClick={tab => deleted ? '' : toggleModal(tab, item)} className="scrollableDiv" style={{ maxHeight: '110px', overflowY: 'auto' }}>
                                            <ul className="pl-3">
                                                {item.rateCodeIds && item.rateCodeIds.map((el, k) =>
                                                    <li key={k}>{el.name}</li>
                                                )}
                                            </ul>
                                        </Col>
                                        <Col onClick={tab => deleted ? '' : toggleModal(tab, item)} className="scrollableDiv" style={{ maxHeight: '110px', overflowY: 'auto' }}>
                                            <ul className="pl-3">
                                                {item.channelInstanceIds && item.channelInstanceIds.map((el, k) =>
                                                    <li key={k}> {el.name}</li>
                                                )}
                                            </ul>
                                        </Col>
                                        <Col onClick={tab => deleted ? '' : toggleModal(tab, item)} className="scrollableDiv" style={{ maxHeight: '110px', overflowY: 'auto' }}>
                                            {item.dateFrom && item.dateTo ?
                                                <>
                                                    <b><FormattedMessage id="AddRule.YieldRuleFrom" /></b> {moment(item.dateFrom).format("YYYY-MM-DD")} <b><FormattedMessage id="AddRule.YieldRuleTo" /></b> {moment(item.dateTo).format("YYYY-MM-DD")}
                                                </>
                                            : item.yieldRuleDates && item.yieldRuleDates.map((date, idx) =>
                                                <div key={idx}>
                                                    <b><FormattedMessage id="AddRule.YieldRuleFrom" /></b> {moment(date.dateFrom).format("YYYY-MM-DD")} <b><FormattedMessage id="AddRule.YieldRuleTo" /></b> {moment(date.dateTo).format("YYYY-MM-DD")}
                                                </div>
                                            )}
                                        </Col>
                                        <Col onClick={tab => deleted ? '' : toggleModal(tab, item)}>
                                            {item.ruleType === 'Automatic Closure' ? <div><FormattedMessage id="AddRule.AutomaticClosureMessage" /> <b>{item.availabilityValue} {item.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.Rooms" /></div> : ''}
                                            {item.ruleType === 'Availability Limit' ? <div><FormattedMessage id="AddRule.AvailabilityLimitMessage1" /> <b>{item.applyValue}</b> <FormattedMessage id="AddRule.AvailabilityLimitMessage2" /> <b>{item.availabilityValue} {item.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                            {item.ruleType === 'Price Change' ? <div><FormattedMessage id={item.availabilityType === "Percent" ? "AddRule.PriceChangeAvailabilityMessage" : 'AddRule.PriceChangeAvailabilityMessageAmount'} /> <b>{item.availabilityValue} {item.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.PriceChangeIncrementMessage" /> <b>{item.applyValue} {item.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                            {item.ruleType === 'Release Days' ? <div><FormattedMessage id="AddRule.SellingLimit" /> <b>{item.releaseDays}</b>  <FormattedMessage id="AddRule.BeforeCheckIn" /></div> : ''}
                                            {item.ruleType === 'Price Override' ? <div><FormattedMessage id="AddRule.PriceOverrideReviewMessage" /> <b>{item.applyValue} {item.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                            {item.ruleType === 'Price Override By Complex Availability' ? <div><FormattedMessage id={item.availabilityType === "Percent" ? "AddRule.PriceChangeComplexAvailabilityMessage" : 'AddRule.PriceChangeComplexAvailabilityMessageAmount'} /> <b>{item.availabilityValue} {item.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.PriceChangeIncrementMessage" /> <b>{item.applyValue} {item.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                        </Col>
                                        {deleted ? ''
                                            :
                                            <Col className="col-2 text-center">
                                                <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.toggleModal(item, 'toggleStatusModal', key)}>
                                                    <i className="fas fa-power-off" />
                                                </Button>
                                                <Button className="btn btn-host btn-sm mr-2" onClick={_ => copyYieldRule(item)}>
                                                    <i className="fas fa-copy" />
                                                </Button>
                                                <Button className="btn btn-host btn-sm" onClick={_ => this.toggleModal(item, 'modal')}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                    <hr />
                                </div>
                            )
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }
}