import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabPane, TabContent, Card, Badge, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FormattedMessage } from "react-intl";
import BlockUi from "react-block-ui";
import { ErrorAlert } from "../Common/ErrorAlert";
import { getAPI } from "../Base/API";
import { ChannelChoose } from "./ChannelChoose";
import { ChannelList } from "./ChannelList";
import classnames from "classnames";
import Authorization from "../Base/Authorization";
import { CommonHelper } from "../Base/Common/CommonUIComponents";
import { getHasChannelData } from "../Base/Common/ReferenceDataFunctions";
import { ModalForceResendData } from "./ModalForceResendData";
import { handleNotification } from "../Base/Notification";

export class ChannelDashboard extends Component {
	constructor(props) {
		super(props);
		this.toggleModalAdd = this.toggleModalAdd.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.getUser = this.getUser.bind(this);
		this.toggle = this.toggle.bind(this);
		this.state = {
			error: null,
			activeTab: "1",
			block: true,
			modal: false,
			modaladd: false,
			modalForceResendData: false,
			ChannelData: [],
			selectedChannel: {},
			user: null,
			hotelLicense: null,
			internalChannels: [
				'HEYTRAVEL',
				'HOST',
				'HEYBE',
				'GOOGLEHOTELADS'
			],
			metaSearchChannels: [
				'GOOGLEHOTELADS'
			],
			metaSearchChannelsCreated: []
		};
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	toggleModalAdd() {
		this.setState(prevState => ({
			airBnbCode: null,
			modaladd: !prevState.modaladd
		}));
	}

	toggleModalForceResendData = () => {
		this.setState(prevState => ({ modalForceResendData: !prevState.modalForceResendData }));
	}

	componentDidMount() {
		this.refreshData();
		this.getUser();
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let airBnbCode = params.get("code");

		// if page as code parameter is because it was a redirect by airbnb
		if (airBnbCode) {
			this.toggleModalAdd();
			this.setState({ airBnbCode: airBnbCode });
		}
	}

	handleRowSelect = (row, isSelected, e) => {
		if (isSelected) {
			this.setState({ modal: true, selectedChannel: row });
		}
	};

	refreshData() {
		const { internalChannels, metaSearchChannels } = this.state;
		this.setState({ block: true });

		getAPI(result => {
			const { data, error } = result;
			const errorMessage = [];

			if (error) {
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data && Array.isArray(data)) {
				const channels = data.filter(resp => resp.channelConfigState !== 'Decommissioned');
				channels.forEach(resp => {
					resp.isInternalChannel = internalChannels.find(channel => resp.code === channel);
					resp.hasChannelData = getHasChannelData(resp.code);
				});

				this.setState({ error: errorMessage, block: false, ChannelData: channels, metaSearchChannelsCreated: channels.filter(resp => metaSearchChannels.find(meta => meta === resp.code) !== undefined).map(resp => resp.code) });
			}
			else {
				this.setState({ error: errorMessage, block: false });
            }
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance");
	}

	getUser() {
		getAPI(result => {
			const { data, error } = result;
			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				this.setState({ user: data, block: false  });
			}
		}, '/api/User/v1/User');
	}
		
	resetCache = (e) => {
		e.preventDefault();
		this.setState({ block: true });

		getAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ error: errorMessage, block: false }));
				}
			}
			else {
				handleNotification(data, <FormattedMessage id="ResetCache.CacheResetSuccessfully" />, <FormattedMessage id="General.Success" />);
				this.refreshData();
			}
		}, '/api/Rate/ChannelConfig/v1/resetUserCache?cacheKey=channelInstance');
	}

	render() {
		if (this.state.ChannelData) {
			let numChannelEventActive = this.state.ChannelData.filter(
				f =>
					f.active === true &&
					f.channelConfigState !== null &&
					f.channelConfigState !== "verified"
			).length;
			let numChannelEventInactive = this.state.ChannelData.filter(
				f =>
					f.active === false &&
					f.channelConfigState !== null &&
					f.channelConfigState !== "verified"
			).length;

			return (
				<Card className="border-0">
					<BlockUi tag="div" blocking={this.state.block}>
						<ErrorAlert error={this.state.error} />
						<Modal
							isOpen={this.state.modaladd}
							toggle={this.toggleModalAdd}
							fade={false}
							className="modal-lg "
						>
							<ModalHeader toggle={this.toggleModalAdd}>
								<FormattedMessage id="ChannelList.Channel" />
							</ModalHeader>
							<ModalBody className="modal-lg px-0 pb-0">
								<ChannelChoose
									selectedChannel={this.state.selectedChannel}
									ChannelCode={this.state.airBnbCode}
									modal={this.toggleModalAdd}
									onSuccess={this.toggleModalAdd}
								/>
							</ModalBody>
						</Modal>
						{
							this.state.modalForceResendData ?
								<ModalForceResendData
									isOpen={this.state.modalForceResendData}
									toggle={this.toggleModalForceResendData}
									channels={this.state.ChannelData.filter(chn => chn.active === true).map(chn => { return { value: chn.id, label: `${chn.channelName} - ${chn.name}(${chn.propertyCode})` } })}
								/>
								:
								''
						}

						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({
										active: this.state.activeTab === "1"
									})}
									onClick={() => {
										this.toggle("1");
									}}
								>
									<FormattedMessage id="ChannelDashboard.Active" />
									{numChannelEventActive > 0 ? (
										<Badge
											color="info"
											className="ml-1  px-1"
										>
											{numChannelEventActive}
										</Badge>
									) : (
										""
									)}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({
										active: this.state.activeTab === "2"
									})}
									onClick={() => {
										this.toggle("2");
									}}
								>
									<FormattedMessage id="ChannelDashboard.Inactive" />
									{numChannelEventInactive > 0 ? (
										<Badge
											color="info"
											className="ml-1 px-1"
										>
											{numChannelEventInactive}
										</Badge>
									) : (
										""
									)}
								</NavLink>
							</NavItem>
							<span className="ml-auto text-nowrap  border-top-0 border-left-0 border-right-0">
								<CommonHelper
									help={
										<FormattedMessage id="ChannelDashboard.Help" />
									}
                                    id={"ChanneldashoardHelp"}
								/>
								<UncontrolledButtonDropdown className="mr-2">
									<DropdownToggle tag="div" className="cursor-pointer py-1 pl-2">
										<i className="fas fa-ellipsis-v" />
									</DropdownToggle>
									<DropdownMenu right>
										<Authorization
											perform="channel:addChannel"
											yes={() => (
												<DropdownItem onClick={this.toggleModalAdd.bind(this)}>
													<i className="fas fa-plus-square mr-2" />
													<FormattedMessage id="generic.CreateNew" />
												</DropdownItem>
											)}
											no={() => ""}
										/>
										<DropdownItem onClick={this.refreshData}>
											<i className="fas fa-sync mr-2" />
											<FormattedMessage id="generic.Refresh" />
										</DropdownItem>
										<DropdownItem onClick={this.toggleModalForceResendData}>
											<i className="fas fa-hand-holding-usd mr-2" />
											<FormattedMessage id="ChannelDashboard.PushToChannels" />
										</DropdownItem>
										<Authorization
											perform="channel:resetCache"
											yes={() => (
												<DropdownItem onClick={this.resetCache}>
													<i className="fas fa-database mr-2" />
													<FormattedMessage id="navbar.ResetCache" />
												</DropdownItem>
											)}
											no={() => ""}
										/>
									</DropdownMenu>
								</UncontrolledButtonDropdown>
							</span>
						</Nav>
						<TabContent activeTab={this.state.activeTab}>
							<TabPane tabId="1">
								<ChannelList
									ChannelData={this.state.ChannelData.filter(
										f => f.active === true
									)}
									refreshData={this.refreshData}
									user={this.state.user}
									hotelId={this.state.user && this.state.user.defaultProperty}
									metaSearchChannelsCreated={this.state.metaSearchChannelsCreated}
								/>
							</TabPane>
							<TabPane tabId="2">
								<ChannelList
									ChannelData={this.state.ChannelData.filter(
										f => f.active === false
									)}
									refreshData={this.refreshData}
									user={this.state.user}
									hotelId={this.state.user && this.state.user.defaultProperty}
									metaSearchChannelsCreated={this.state.metaSearchChannelsCreated}
								/>
							</TabPane>
						</TabContent>
					</BlockUi>
				</Card>
			);
		} else {
			return (
				<BlockUi tag="div" blocking={this.state.block}>
					<ErrorAlert error={this.state.error} />
				</BlockUi>
			);
		}
	}
}
