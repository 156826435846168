import React, { Component } from 'react';
import { Row, Col, CustomInput, InputGroup, InputGroupAddon, InputGroupText, Input, Button, ButtonGroup, Badge, Card, Collapse, NavItem, Nav, TabContent, TabPane, NavLink } from 'reactstrap';
import moment from 'moment';
import CustomSelect from '../Base/Common/CustomSelect';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { getAPI, postAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import AvailabilityCalendar from './AvailabilityCalendar';
import { getChannelConfig, getDependecyOrderTypes, getPercentageDynamic } from '../Base/Common/ReferenceDataFunctions';
import classnames from "classnames";
import BulkPricesCell from './BulkPricesCell';
import { CheckAuthorization } from '../Base/Authorization';
import { CustomDateRangePicker } from '../Base/Common/CustomReactDates';
import BulkPricesCellExplanation from './BulkPricesCellExplanation';

export class BulkUpdatePrices extends Component {

    constructor(props) {
        super(props);
        this.formPrice = React.createRef();
        this.formCommon = React.createRef();
        this.onClickWeekDays = this.onClickWeekDays.bind(this);
        this.state = {
            block: true,
            price: [],
            childPrice: null,
            childPriceMode: 'amount',
            selectedRate: null,
            startDate: null,
            endDate: null,
            weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            priceAction: 'amount',
            priceType: null,
            priceActions: [
                {
                    value: 'amount',
                    label: <FormattedMessage id="BulkUpdate.Amount" />
                },
                {
                    value: 'modify',
                    label: <FormattedMessage id="BulkUpdate.Modify" />
                },
                {
                    value: 'copyLastYear',
                    label: <FormattedMessage id="BulkUpdate.copyLastYear" />
                }
            ],
            priceTypes: [
                {
                    value: 'increment',
                    label: '€'
                },
                {
                    value: 'percentage',
                    label: '%'
                }
            ],
            priceMode: 'amount',
            activeTab: '1',
            paxList: [],
            rateCodes: [],
            rates: [],
            dependencyMasterId: null,
            currAvailabilities: undefined,
            availabilities: null,
            selectedOcc: 'p1',
            rateMappings: [],
            selectedMapping: null,
            rateRules: [],
            ChangeRuleOpen: false,
            ChangeComplexRuleOpen: false,
            OverrideRuleOpen: false,
            occupationSelect: [],
            dates: [],
            calendarMonths: [],
            displayPricesTab: 'initial',
            freeRoomsFieldsIdx: 0,
            hasComplexPriceRules: global.modules.some(m => m === 'ComplexPriceRules') ? 'initial' : 'none',
            lastYearPrices: [],
            sumbittedStartDate: null,
            currencies: [],
            hasFlexChildPrices: global.modules.some(m => m === 'FlexChildPrice'),
            canD3: false
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data?.response) {
                this.setState({
                    error: errorMessage,
                    rates: data.response,
                    rateCodes: data.response
                        .reduce((acc, curr) => {
                            const opt = { label: curr.name, value: curr.id, isDependent: false, currencyCode: curr.currencyCode };

                            if (curr.dependencyParent) {
                                opt.isDependent = true;
                                acc[1].label = this.getTitle(<FormattedMessage id="BulkUpdate.Dependent" />, acc[1].options.push(opt));
                            }
                            else {
                                acc[0].label = this.getTitle(<FormattedMessage id="BulkUpdate.Main" />, acc[0].options.push(opt));
                            }
                            return acc;
                        }, [this.getRateOption(<FormattedMessage id="BulkUpdate.Main" />, 0), this.getRateOption(<FormattedMessage id="BulkUpdate.Dependent" />, 0) ])
                }, () => {
                    if(global?.modules?.includes("CurrencyConvertion")){
                        this.getCurrencies();
                    }
                    else
                        this.setState({ block: false }); 
                });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Rate/v1`);

        window.setTimeout(
            () => {
                const hasComplexPriceRules = global.modules.some(m => m === 'ComplexPriceRules') ? 'initial' : 'none'
                const hasFlexChildPrices = global.modules.some(mdl => mdl === 'FlexChildPrice');
                this.setState({ hasComplexPriceRules, hasFlexChildPrices });
            },
            2500
        );
    }

    getRateOption(title, total) {
        const rateOption = {
            label: this.getTitle(title, total),
            options: []
        };
        return rateOption;
    }

    getCurrencies = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                this.setState({ currencies: data.response, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Price/Price/Currency/v1/Conversion');
    }

    getTitle(title, total) {
        return (
            <div className="d-flex justify-content-between"><span>{title}</span><span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{total}</span></div>
        );
    }

    availAPI = (startDate, endDate, start) => {
        let { selectedRate, dates, currAvailabilities, availabilities, priceAction } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.length > 0) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data[0] && data[0].availability) {
                    data[0].availability = data[0].availability.map(av => {
                        const ava = av;
                        ava.blockId = data[0].roomBlock;
                        ava.id = Math.floor(Math.random() * 10000) + 1;
                        return (ava);
                    });
                    
                    const copy = { ...data[0] };

                    if(!availabilities){
                        availabilities = [];
                    }

                    availabilities.push(copy);

                    data[0].availability = data[0].availability.filter(av =>
                        dates?.filter(date => moment(date.startDate)?.isSameOrBefore(av.hotelDate) && moment(date.endDate)?.isSameOrAfter(av.hotelDate)).length > 0
                    );
                    
                    if(currAvailabilities){
                        data[0].availability.forEach((d) => {
                            const copyidx = currAvailabilities.availability.findIndex(({hotelDate}) => moment(d.hotelDate)?.isSame(hotelDate));
                            if(copyidx > -1){
                                currAvailabilities.availability.splice(copyidx, 1, d);
                            }
                            else{
                                currAvailabilities.availability.push(d);
                            }
                        });
                    }
                    else{
                        currAvailabilities = data[0];
                    }

                    if (start && dates.length > 1) {
                        this.setState({ availabilities, currAvailabilities }, () => {
                            if(priceAction === 'copyLastYear')
                                this.getLastYearPrices();
                            else
                                this.setState({ block: false });
                        });
                    }
                    else {
                        this.setState({ availabilities, currAvailabilities, endDate: moment(endDate), startDate: moment(start) }, this.GetMappings());
                    }
                    this.smartDrawCalendar(startDate);
                }
                else {
                    this.setState({ block: false });
                }
            }
            else {
                this.setState({ block: false, currAvailabilities: null });
            }
        }, `/api/Price/Availability/v1/Availability/${selectedRate}?startDate=${startDate}&endDate=${endDate}&includePrices=true`);
    }

    smartDrawCalendar = (startDate) => {
        const { sumbittedStartDate } = this.state;

        if (sumbittedStartDate && (moment(sumbittedStartDate).isSame(startDate, 'month') || moment(sumbittedStartDate).add(1, 'month').isSame(startDate, 'month'))) {
            this.buildCalendarStruct(sumbittedStartDate);
            return;
        }
        else{
            this.buildCalendarStruct(startDate);
        }
    }

    getAvailability = (start) => {
        let { startDate, dates, endDate, selectedRate } = this.state;
        if(!selectedRate) return;
        this.setState({ block: true }, () => {
            let date = dates.filter(date => moment(date.endDate).diff(start ? start : startDate?.format('YYYY/MM/DD'), 'months') > 0).length > 0 ?
                start ? moment(start) : startDate
                :
                dates.filter(date => moment(date.startDate).isBefore(start ? start : startDate?.format('YYYY/MM/DD')) && moment(start).diff(date.startDate, 'month', true) > 1)
                    .length > 0 ?
                    start ? moment(start).subtract('1', 'month') : startDate.clone().subtract('1', 'month')
                    :
                    start ? moment(start) : startDate;
            
            const end = moment(endDate).format("YYYY-MM-DD");
            this.availAPI(date?.format('YYYY/MM/DD'), end, start);
        });
    }

    GetMappings = () => {
        const { selectedRate } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                const rateMappings = data.response?.sort(map => map?.mappingActive ? -1 : 1);
                this.setState({ rateMappings: rateMappings }, () => {
                    this.GetRules(rateMappings);
                });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Rate/RateCodeChannel/v1/mappings/rate/${selectedRate}`);
    }

    GetRules = (rateMappings) => {
        const { selectedRate, priceAction, endDate, startDate } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }

                this.setState({
                    rateRules: data.response.map(rule => {
                        let copy = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

                        copy = copy.map(d => {
                            return rule.weekdays.find(day => day === d) ? rule.weekdays.find(day => day === d) : null;
                        });
                        rule.weekdays = copy;
                        return rule;
                    }).filter(rule => {
                        if (rule.active) {
                            if (rule.yieldRuleDates?.length > 0) {
                                if (rule.yieldRuleDates.filter(date => date.dateFrom && endDate?.isSameOrAfter(moment(date.dateFrom)) && date.dateTo && startDate?.isSameOrBefore(moment(date.dateTo))).length === 0) {
                                    return false;
                                };
                            }
                            else if (!rule.dateFrom || (rule.dateFrom && !endDate?.isSameOrAfter(moment(rule.dateFrom))) || !rule.dateTo || (rule.dateTo && !startDate?.isSameOrBefore(moment(rule.dateTo)))) {
                                return false;
                            }
                            return (rule.channelInstanceIds?.filter(({ code }) => rateMappings?.filter(({ channelInstanceId }) => channelInstanceId === code).length > 0).length > 0);
                        }
                        return false
                    })
                }, () => {
                    if(priceAction === 'copyLastYear')
                        this.getLastYearPrices();
                    else
                        this.setState({ block: false });
                });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Rate/YieldRules/v1/rate/${selectedRate}`);

    }

    updatePriceBulk(event) {
        event.preventDefault();
        const { price, selectedRate, dates, weekdays, childPrices, childPriceMode, priceAction, occupationSelect, rate } = this.state;

        if (!this.formCommon.current.reportValidity()) {
            return;
        }

        if (price && price.length > 1) {
            let lst = price.sort((a, b) => (a.position - b.position))
            let p = 0;
            lst.forEach(element => {
                const el = document.getElementById('price' + element.position);
                if (el) {
                    if (element.priceMode === 'amount') {
                        if (element.adultPrice < p) {
                            el.setCustomValidity("The price needs to be greater for larger occuppations")
                        } else {
                            p = element.adultPrice
                            el.setCustomValidity("");
                        }
                    }
                    else if (element.priceMode === 'increment') {
                        if (element.adultPrice < -200 || element.adultPrice > 200) {
                            el.setCustomValidity("The increment needs to be between -200 and 200")
                        } else {
                            el.setCustomValidity("");
                        }
                    }
                    else if (element.priceMode === 'percentage') {
                        if (element.adultPrice < -100 || element.adultPrice > 100) {
                            el.setCustomValidity("The percentage needs to be between -100 and 100")
                        } else {
                            el.setCustomValidity("");
                        }
                    }
                }
            });
        }

        let req = {
            rateCodeId: selectedRate,
            dates: dates,
            weekdays: weekdays
        }

        if (price.length > 0) {
            req.adultPrices = price;
        }
        else if (priceAction === 'copyLastYear') { // for Copy from previous year
            req.adultPrices = occupationSelect.filter(({ value }) => value !== "d2").map((option) => {
                let occ = option.value[1];

                if (option.value?.length === 3) {
                    const pax = rate.defaultPax?.find(({ position, active }) => active && position === parseInt(`${option.value[1]}${option.value[2]}`));
                    const position = parseInt(`${option.value[1]}${option.value[2]}`) <= 10 ? pax?.adults : 11;
                    occ = position;
                }

                return ({
                    position: occ,
                    adultPrice: 0,
                    priceMode: "amount"
                });
            });
        }
        else {
            req.adultPrices = [];
        }
        
        if (childPrices && childPrices.length > 0) {
            req.childPrices = childPrices.map(c => ({...c, position: c.position[1]}));
        }
        else if(priceAction === 'copyLastYear' && occupationSelect.find(({value}) => value === "d2")){ // for Copy from previous year
            req.childPrice = 0;
            req.childPriceMode = "increment";
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="BulkUpdate.PriceUpdatedSucessfuly" />, <FormattedMessage id="General.Success" />);
                        this.sequentialAvailFetch();
                    }
                }
                this.setState({ error: errorMessage, block: false, price: [], childPrices: [] });
            }, `/api/Price/Price/Price/v1/UpdateNDays?copyPreviousYear=${priceAction === "copyLastYear"}`, req);
        });
    }

    sequentialAvailFetch = () => {
        const { dates } = this.state;

        dates.forEach((date) => {
            this.availAPI(date.startDate, date.endDate);
        });
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value).join(',');
        } else {
            targetValue = inputValue ? inputValue.value : null;
        }

        this.setState({ [evt]: targetValue })
    }

    setPrice(isBaseOccupancy, isOccupancyDerived, adult, child, pos, priceMode, evt) {
        const { rates, selectedRate } = this.state;

        const adultPrice = evt?.target?.value && !isNaN(parseInt(evt?.target?.value)) ? evt.target.value : null;
        let currPrice = !isNaN(adultPrice) ? parseFloat(adultPrice) : null;

        let price = [...this.state.price];
        const idx = price.indexOf(price.find(p => p.position === adult));
        
        if (idx === -1) {
            price.push({
                position: adult,
                adultPrice: currPrice,
                priceMode: priceMode
            })
        }
        else {
            if (!currPrice) {
                price.splice(idx, 1);
            }
            else {
                const p = price[idx];
                p.adultPrice = currPrice;
            }
        }

        if(isOccupancyDerived && isBaseOccupancy){
            const defaultPax = rates?.find(rate => rate.id === selectedRate)?.defaultPax;
            const arr = [];

            defaultPax.forEach((pax) => {
                if(pax.adults !== adult && pax.active){
                    const copyIdx = arr.findIndex((p) => p.adults === pax.adults);
                    if(copyIdx < 0){
                        arr.push(pax);
                    }
                    else if(arr[copyIdx].childrens > pax.childrens){
                        arr.splice(copyIdx, 1);
                        arr.push(pax);
                    }
                };
            });

            arr.forEach((pax) => {
                let newCurrPrice = currPrice;

                if(priceMode === "amount" && !isNaN(currPrice)){
                    if(pax.derivedType === "amount"){
                        newCurrPrice = currPrice + pax.derivedAmount;
                    }
                    else{
                        newCurrPrice = currPrice * (1 + pax.derivedAmount / 100);
                    };
                }
                const index = price.indexOf(price.find(p => p.position === pax.adults));

                if (index === -1) {
                    price.push({
                        position: pax.adults,
                        adultPrice: newCurrPrice,
                        priceMode: priceMode
                    })
                }
                else {
                    if (!newCurrPrice) {
                        price.splice(index, 1);
                    }
                    else {
                        const p = price[index];
                        p.adultPrice = newCurrPrice;
                    }
                }
            });
        };

        this.setState(({ price }));
    }

    setComboStatusOnAdultPrice = (name, adult, inputValue) => {
        const targetValue = inputValue ? inputValue.value : null;

        var price = [...this.state.price];

        if (this.state.paxList?.some(el => el.isBaseOccupancy)) {
            price = price.map(el => ({ ...el, priceMode: targetValue, adultPrice: null }))
        }
        else {
            price.forEach(el => {
                if (el.position === adult) {
                    el.priceMode = targetValue;
                    el.adultPrice = null;
                }
            })
        } 

        this.setState(prevState => ({
            ...prevState,
            priceMode: targetValue,
            priceType: targetValue,
            price
        }));
    }

    setPriceModeByPriceAction = (name, inputValue) => {
        const targetValue = inputValue ? inputValue.value : null;
        if (targetValue === this.state.priceActions[0].value) {
            this.setState(({ childPriceMode: targetValue, priceMode: targetValue, priceType: null, [name]: targetValue, price: [], childPrices: [] }));
        }
        else if(targetValue === this.state.priceActions[2].value){
            this.setState(prevState => ({
                childPriceMode: prevState.priceTypes[0].value,
                priceMode: prevState.priceTypes[0].value,
                priceType: prevState.priceTypes[0].value,
                [name]: targetValue,
                price: [],
                childPrices: [],
                block: true
            }), () => this.getLastYearPrices());
        }
        else {
            this.setState(prevState => ({ childPriceMode: prevState.priceTypes[0].value, priceMode: prevState.priceTypes[0].value, priceType: prevState.priceTypes[0].value, [name]: targetValue, price: [], childPrices: [] }));
        }
    }

    getLastYearPrices = () => {
        const { selectedRate, lastYearPrices, dates } = this.state;
        if(dates)
            dates.forEach((curr, key) => {
                const { startDate, endDate } = curr;

                let date = moment(startDate).add(-1, 'year');
                const end = moment(endDate).add(-1, 'year').endOf('month').format("YYYY-MM-DD");

                var result = getAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
                    
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        if(key === dates.length - 1){
                            this.setState({ error: errorMessage, block: false });
                        }
                        else{
                            this.setState({ error: errorMessage});
                        }
                        return;
                    }
                    if (data && data.length > 0) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);

                            if(key === dates.length - 1){
                                this.setState(({ block: false, error: errorMessage }));
                            }
                            else{
                                this.setState(({ error: errorMessage }));
                            }
                            return;
                        }
                        if (data[0] && data[0].availability) {
                            data[0].availability = data[0].availability.map(av => {
                                const ava = av;
                                ava.blockId = data[0].roomBlock;
                                ava.id = Math.floor(Math.random() * 10000) + 1;
                                return (ava);
                            });

                            data[0].availability.forEach((d) => {
                                const copyidx = lastYearPrices.findIndex(({hotelDate}) => moment(d.hotelDate).isSame(hotelDate));
                                if(copyidx > -1){
                                    lastYearPrices.splice(copyidx, 1, d);
                                }
                                else{
                                    lastYearPrices.push(d);
                                }
                            });
                            
                            if(key === dates.length - 1){
                                this.setState({ block: false, lastYearPrices });
                            }
                            else{
                                this.setState({ lastYearPrices });
                            }
                        }
                        else {
                            if(key === dates.length - 1){
                                this.setState({ block: false });
                            }
                        }
                    }
                    else {
                        if(key === dates.length - 1){
                            this.setState({ block: false });
                        }
                    }
                }, `/api/Price/Availability/v1/Availability/${selectedRate}?startDate=${date?.format("YYYY-MM-DD")}&endDate=${end}&includePrices=true`);
            });
        else
            this.setState({ block: false });
    }

    onClickWeekDays(weekDay) {
        const weekdays = [...this.state.weekdays];
        if (weekdays.find(weekday => weekday === weekDay)) {
            const elementsToRemove = 1;
            weekdays.splice(weekdays.indexOf(weekdays.find(item => item === weekDay)), elementsToRemove);
        }
        else {
            weekdays.push(weekDay);
        }
        this.setState(({ weekdays }));
    }

    handleRateChange = (combo, autoSearch) => {
        const { rates, dates, hasFlexChildPrices, canD3 } = this.state;
        const value = combo && combo.value;
        const rate = rates.find(rc => rc.id === value);
        const rateCodePax = rates.filter(rc => rc.id === value).map(rc => rc.defaultPax)[0];
        const copy = [...rateCodePax];
        const paxList = rateCodePax
            .filter(c => {
                if (copy.filter(px => px.name === c.name).length > 1 || !c.active) {
                    copy.splice(copy.indexOf(copy.find(px => px.name === c.name)), 1);
                    return false;
                }
                else {
                    return true;
                }
            })
            .sort((a, b) => a.adults === b.adults ? (a.adults + a.children) - (b.adults + b.children) : a.adults - b.adults);

        const occupationSelect = paxList.filter((item) => !item.childrens).map((item, key) => {
            return ({
                value: `p${item.position}`,
                label: (<div>
                    <span className='mr-2'>{item.adults}</span>
                    <span><i className="fas fa-user"></i></span>
                </div>)
            });
        });
        
        const selectedOcc = occupationSelect && occupationSelect.length > 0 ? occupationSelect[0]?.value : this.state.selectedOcc;

        if (paxList.filter(el => el.childrens > 0).length > 0) {
            occupationSelect.splice(0, 0, {
                value: `d2`,
                label: (<div>
                    <span className='mr-2'>1</span>
                    <span><i className={"fas fa-child "} /></span>
                </div>)
            });
        }

        if(hasFlexChildPrices && canD3){
            occupationSelect.splice(1, 0, {
                value: `d3`,
                label: (<div>
                    <span className='mr-2'>{`>1`}</span>
                    <span><i className={"fas fa-child "} /></span>
                </div>)
            });
        }

        this.setState({
            rate,
            canD3: rate?.defaultPax?.filter(({childrens}) => childrens > 1).length > 0,
            selectedRate: value,
            currencyCode: combo ? combo.currencyCode : null,
            lastYearPrices: [],
            paxList,
            selectedOcc,
            rateRules: [],
            price: [],
            childPrices: [],
            rateMappings: [],
            currAvailabilities: undefined,
            occupationSelect,
            displayPricesTab: combo && combo.isDependent ? 'none' : 'initial',
            activeTab: combo && combo.isDependent ? '2' : '1'
            }, () => {
                if(autoSearch && dates?.length > 0) this.sequentialAvailFetch();
            }
        );
    }

    getDays = () => {
        const { startDate, endDate } = this.state;

        const numberOfDays = moment(endDate).diff(moment(), 'days') <= 30 ? moment(endDate).diff(moment(), 'days') : 30;

        var days = [];

        for (var i = 0; i < numberOfDays; i++) {
            days.push(moment(startDate).add(i, 'days'));
        }

        return (days);
    }

    changeSelectedOcc = (occ) => {
        const { mapping } = this.state;
        if (!mapping || mapping.priceMode !== 1) {
            this.setState({ selectedOcc: occ });
        }
    }

    selectMapping = (id) => {
        const { rateMappings, selectedMapping, paxList, selectedOcc, selectedRate, rates, hasFlexChildPrices, canD3 } = this.state;
        const mapping = rateMappings?.find((map) => map.rateCodeChannelId === id);
        let occupationSelect = [...this.state.occupationSelect];
        const rate = rates.find(rate => rate.id === selectedRate);
        if(mapping.channelCode !== "HRS" || !(mapping.rateCodeMapping === "business" || mapping.rateCodeMapping === "economy" || mapping.rateCodeMapping === "comfort" || mapping.rateCodeMapping?.includes("extraBed"))){
            if (mapping && mapping.pricePosition > 10 && selectedMapping !== id) {
                const pricePosition = rate?.defaultPax?.find(({ position }) => position === mapping.pricePosition)

                occupationSelect.push({
                    value: `p${mapping.pricePosition}`,
                    label: <div>
                        <span className='mr-2'>{pricePosition?.adults}</span>
                        <span className='mr-2'><i className="fas fa-user" /></span>
                        <span className='mr-2'>{pricePosition?.childrens}</span>
                        <span><i className="fas fa-child" /></span>
                    </div>
                });
            }
            else {
                const rateCodePax = rate.defaultPax;
                const copy = [...rateCodePax];
                const paxList = rateCodePax.filter(c => {
                    if (copy.filter(px => px.name === c.name).length > 1 || !c.active) {
                        copy.splice(copy.indexOf(copy.find(px => px.name === c.name)), 1);
                        return false;
                    }
                    else {
                        return true;
                    }
                })
                    .sort((a, b) => a.adults === b.adults ? (a.adults + a.children) - (b.adults + b.children) : a.adults - b.adults);

                occupationSelect = paxList.filter((item) => !item.childrens).map((item, key) => {
                    return ({
                        value: `p${item.position}`,
                        label: (<div>
                            <span className='mr-2'>{item.adults}</span>
                            <span><i className="fas fa-user"></i></span>
                        </div>)
                    });
                });

                if (paxList.filter(el => el.childrens > 0).length > 0) {
                    occupationSelect.splice(0, 0, {
                        value: `d2`,
                        label: (<div>
                            <span className='mr-2'>1</span>
                            <span><i className={"fas fa-child "} /></span>
                        </div>)
                    });
                    if(hasFlexChildPrices && canD3){
                        occupationSelect.splice(1, 0, {
                            value: `d3`,
                            label: (<div>
                                <span className='mr-2'>{`>1`}</span>
                                <span><i className={"fas fa-child "} /></span>
                            </div>)
                        });
                    }
                };
            };

            if ((mapping.interfaceMode !== 2 && mapping.interfaceMode !== 4) || (selectedMapping === id) || (mapping.priceMarkupType && mapping.priceMarkupType > 0)) {
                this.setState({
                    occupationSelect: occupationSelect?.filter((value, index, array) => array.findIndex((e) => e.value === value.value) === index),
                    selectedMapping: selectedMapping === id ? null : id,
                    selectedOcc:
                        selectedMapping === id && selectedOcc && occupationSelect && occupationSelect.length > 1 ?
                            occupationSelect[1]?.value
                            :
                            mapping.priceMode === 1 || mapping.priceMode === 2 ?
                                mapping.pricePosition === 0 ?
                                    `p${paxList[0].position}`
                                    :
                                    `p${mapping.pricePosition}`
                                :
                                selectedOcc
                });
            };
        }
    }

    toggleChangeRuleOpen = () => {
        this.setState(prev => ({ ChangeRuleOpen: !prev.ChangeRuleOpen }));
    }

    toggleChangeComplexRuleOpen = () => {
        this.setState(prev => ({ ChangeComplexRuleOpen: !prev.ChangeComplexRuleOpen }));
    }

    toggleOverrideRuleOpen = () => {
        this.setState(prev => ({ OverrideRuleOpen: !prev.OverrideRuleOpen }));
    }

    findRule = (operation, d, key, channelInstanceId) => {
        const { rateRules, selectedMapping, rateMappings } = this.state;

        const mapping = rateMappings?.find((map) => map.rateCodeChannelId === selectedMapping);

        let rules = rateRules?.filter((rule) => {
            if (d === true) {
                if (rule.ruleType === operation) {
                    if (rule.channelInstanceIds.filter((channelinstace) => channelinstace.code === channelInstanceId).length > 0) {
                        return true;
                    }
                };
            }
            else {
                if ((rule.ruleType === operation) && d?.moment) {
                    if (rule.yieldRuleDates?.length > 0 &&
                        rule.yieldRuleDates.filter(date => date.dateFrom && d?.moment?.isSameOrAfter(moment(date.dateFrom))
                            &&
                            date.dateTo && d?.moment.isSameOrBefore(moment(date.dateTo))).length > 0 &&
                        rule.channelInstanceIds.filter((channelinstace) => channelinstace.code === mapping?.channelInstanceId).length > 0
                        && rule.weekdays[key]) {
                        return true;
                    }
                    else if (moment(rule.dateFrom)?.isSameOrBefore(d.moment) && moment(rule.dateTo)?.isSameOrAfter(d.moment) && rule.weekdays[key]) {
                        return true;
                    };
                };
            };
        });

        return rules;
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val });
    }

    isDayBlocked = (day) => {
        const { dates } = this.state;
        const val = dates.find(date => {
            if(moment(date.startDate).dayOfYear() + moment(date.startDate).year() <= day.dayOfYear() + day.year()){
                if(day.dayOfYear() + day.year() <= moment(date.endDate).dayOfYear() + moment(date.endDate).year()){
                    if(day.year() === moment(date.startDate).year() && day.year() === moment(date.endDate).year()){
                        return true;
                    };
                };
            };
            return false;
        });
        return val;
    }

    removeDate = (index) => {
        let { dates, currAvailabilities } = this.state;
        const date = dates[index];

        currAvailabilities.availability = currAvailabilities?.availability?.filter((d) => !(moment(d.hotelDate)?.isSameOrAfter(date?.startDate) && moment(d.hotelDate)?.isSameOrBefore(date?.endDate)));
        
        dates.splice(index, 1);
        this.setState({ currAvailabilities }, () => this.selectDate(dates[0]?.startDate, dates[0]?.endDate));
    }

    selectDate = (start, endDate, isNew, focus) => {
        if (isNew) {
            this.setState({ startDate: moment(start), endDate: moment(endDate) }, () => this.getAvailability(start));
        }
        else {
            this.setState({ startDate: start ? moment(start) : null, endDate: endDate ? moment(endDate) : null }, () => {
                if(focus)
                    this.smartDrawCalendar(start);
            });
        }
    }

    hasNewPrice = (anyOcc) => {
        const { price, selectedOcc, rate, childPrices } = this.state;
        let occ = selectedOcc[1];

        if (selectedOcc?.length === 3) {
            const pax = rate.defaultPax?.find(({ position, active }) => active && position === parseInt(`${selectedOcc[1]}${selectedOcc[2]}`));
            const position = parseInt(`${selectedOcc[1]}${selectedOcc[2]}`) <= 10 ? pax?.adults : 11;
            occ = position;
        }

        if(anyOcc || ((selectedOcc === "d2" || selectedOcc === "d3") && childPrices && childPrices.find(({position}) => position === selectedOcc))){
            return true;
        }

        if(price.find(({position}) => position.toString() === occ || anyOcc)){
            return true;
        }

        return false;
    }

    getNewPrice = (basePrice, dayAvailability, key) => {
        const { selectedOcc, childPrices, price, childPriceMode, rate } = this.state;

        if(selectedOcc === "d2" || selectedOcc === "d3"){
            const childPrice = parseFloat(childPrices?.find(({position}) => position === selectedOcc)?.adultPrice ?? '0');
            if(childPriceMode === 'amount')
                return childPrice;

            if(childPriceMode === "increment")
                return basePrice + childPrice;
            
            if(childPriceMode === "percentage")
                return (basePrice + (basePrice * (childPrice / 100)));
        }

        let occ = selectedOcc[1];
        let pax;

        if (selectedOcc?.length === 3) {
            pax = rate.defaultPax?.find(({ position, active }) => active && position === parseInt(`${selectedOcc[1]}${selectedOcc[2]}`));
            const position = parseInt(`${selectedOcc[1]}${selectedOcc[2]}`) <= 10 ? pax?.adults : 11;
            occ = position;
        }
        else{
            pax = rate.defaultPax?.find(({ position, active }) => active && position.toString() === occ);
        }
        
        if(pax && pax.derivedType && pax.derivedAmount){
            return this.calcDerivedOccIncrement(pax, basePrice, dayAvailability, key);
        }

        const currPrice = price.find(({position}) => position.toString() === occ);

        return this.calcNewPrice(currPrice, basePrice);
    }

    calcDerivedOccIncrement = (pax, basePrice, dayAvailability, key) => {
        const { rate, price } = this.state;

        const basePax = rate.defaultPax?.find(({ isBaseOccupancy, active }) => active && isBaseOccupancy);

        if(!basePax)
            return basePrice;

        const basePaxPrice = price.find(({position}) => position === basePax.position);

        if(!dayAvailability)
            return basePrice;
        
        const baseMaxPriceValue = this.calculatePrice(dayAvailability, key, false, true, null, null, `p${basePax.position}`)

        if(!basePaxPrice || !baseMaxPriceValue)
            return basePrice;
        
        const basePaxNewPrice = this.calcNewPrice(basePaxPrice, baseMaxPriceValue);

        if(pax.derivedType === "amount") {
            return basePaxNewPrice + pax.derivedAmount;
        }
        else {
            return basePaxNewPrice * (1 + pax.derivedAmount / 100);
        }
    }
    
    calcNewPrice = (old, basePrice) => {
        if(!old) return basePrice;

        if(old.priceMode === 'amount')
            return old.adultPrice;
        
        if(old.priceMode === "increment")
            return basePrice + old.adultPrice;

        if(old.priceMode === "percentage")
            return (basePrice + (basePrice * (old.adultPrice / 100)));
    }

    renderCell = (dayAvailability, key) => {
        const { selectedMapping, lastYearPrices, activeTab, priceAction, selectedOcc, currencyCode } = this.state;
        const selectedChannelInstance = this.getSelectedMapping();
        
        const basePrice = this.calculatePrice(dayAvailability, key, false, true);
        const hasNewPrice = this.hasNewPrice() || (priceAction === "copyLastYear" && activeTab === '1');
        let newPrice = null;
        
        if(hasNewPrice && activeTab === '1'){
            if(priceAction === "copyLastYear"){
                const lastYearPrice = lastYearPrices.find(p => moment(p.hotelDate).format('YYYY-MM-DD') === moment(dayAvailability.hotelDate).add(-1, 'year').format('YYYY-MM-DD'));
                if(lastYearPrice){
                    if(selectedOcc === "d2"){
                        newPrice = this.getNewPrice(lastYearPrice.d2);
                    }
                    else{
                        newPrice = this.getNewPrice(lastYearPrice[selectedOcc]);
                    }
                }
            }
            else{
                newPrice = this.getNewPrice(basePrice, dayAvailability, key) ?? 0;
            }
        }

        return (
            <div className="text-center" style={{ width: '100%', fontSize: '14px', fontWeight: '500', padding: '0.5vh 0', alignItems: 'center', justifyContent: 'center' }}>
                <div className="mb-1" style={{ fontSize: '0.9em' }}>
                    <FormattedNumber value={basePrice} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                </div>
                {activeTab === '1' && hasNewPrice ?
                    <div style={{ fontSize: '0.8em', color: '#165C7D' }}>
                        <FormattedNumber value={newPrice} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2}/>
                    </div>
                : selectedMapping ?
                    <div style={{ fontSize: '0.8em', color: '#165C7D' }}>
                        {basePrice > 0 ?
                            <FormattedNumber
                                value={this.calculatePrice(dayAvailability, key, true, true, true)}
                                style="currency"
                                currency={selectedChannelInstance?.convertCurrencyToChannel ? selectedChannelInstance.currencyConvertion : (currencyCode || "EUR")}
                                minimumFractionDigits={2}
                            />
                        : 'N/A'}
                    </div>
                :''}
            </div>
        );
    }

    calculatePrice = (dayAvailability, k, withRules, withPackage, markup, ruleIndex, occ) => {
        const { rate, currencies, hasFlexChildPrices, canD3 } = this.state;
        const selectedMapping = this.getSelectedMapping();
        const selectedOcc = occ ?? this.state.selectedOcc;

        //Calculate base price
        let val = dayAvailability[selectedOcc];

        if (selectedOcc?.length === 3) {
            const pax = rate.defaultPax?.find(({ position, active }) => active && position === parseInt(`${selectedOcc[1]}${selectedOcc[2]}`));

            const position = parseInt(`${selectedOcc[1]}${selectedOcc[2]}`) <= 10 ? `p${pax?.adults}` : `p11`;
            const childrenPosition = hasFlexChildPrices && canD3 ? "d3" : "d2";

            if(position === "p11" && pax.childrens > 0){
                const adultPosition = "p" + rate.defaultPax?.find((p) => p.adults === pax.adults && p.childrens === 0 && p.active)?.position?.toString();

                const adultPrice = dayAvailability[adultPosition];
                if(childrenPosition === "d3") {
                    const third = dayAvailability[childrenPosition];
                    const childrenPrice = third??dayAvailability.d2;
                    
                    val = adultPrice + (third ? (dayAvailability.d2 + (childrenPrice * (pax.childrens - 1))) : childrenPrice * pax.childrens);
                }
                else {
                    const childrenPrice = dayAvailability.d2;
                    
                    val = adultPrice + (childrenPrice * pax.childrens);
                }
            }
            else{
                let adultPrice = dayAvailability[position];

                const arr = [];
                for (let i = 0; i < rate.defaultPax?.find(({ position }) => position === parseInt(`${selectedOcc[1]}${selectedOcc[2]}`))?.childrens; i++) {
                    arr.push(dayAvailability ? (dayAvailability[childrenPosition]??dayAvailability.d2) : null);
                }
                let childPrice = arr.reduce((a, b) => a + b, 0);

                val = adultPrice + (childPrice * pax.childrens);
            }
        }

        //Calculate Rules
        if (withRules) {
            const ChangeRules = this.findRule('Price Change', { moment: moment(dayAvailability.hotelDate) }, k);
            const PriceByAvail = this.findRule('Price Override By Complex Availability', { moment: moment(dayAvailability.hotelDate) }, k);
            const OverrideRules = this.findRule('Price Override', { moment: moment(dayAvailability.hotelDate) }, k);
            
            const isHost = global.PMSType === 'Host' || global.PMSType === 'None';

            let currIndex;
            let restrictionType;
            if (!isNaN(ruleIndex)) {
                const allRules = this.appliedRules();
                if (allRules) {
                    if (allRules[ruleIndex]?.ruleType === 'Price Change') {
                        restrictionType = 'Price Change';
                        currIndex = ChangeRules.findIndex((el) => el.id === allRules[ruleIndex].id);
                    }
                    else if (allRules[ruleIndex]?.ruleType === 'Price Override By Complex Availability') {
                        restrictionType = 'Price Override By Complex Availability';
                        currIndex = PriceByAvail.findIndex((el) => el.id === allRules[ruleIndex]?.id);
                    }
                    else if (allRules[ruleIndex]?.ruleType === 'Price Override') {
                        restrictionType = 'Price Override';
                        currIndex = OverrideRules.findIndex((el) => el.id === allRules[ruleIndex]?.id);
                    }
                }
            }

            const percentage = this.getPercentage(dayAvailability, isHost);
            if (ChangeRules && ChangeRules.length > 0) {
                ChangeRules.forEach((rule, i) => {
                    if (restrictionType !== 'Price Change' || isNaN(currIndex) || currIndex < 0 || currIndex > i) {
                        if ((rule.availabilityType === "Percent" && rule.availabilityValue <= percentage) || (rule.availabilityType === "Percent" && rule.availabilityValue <= dayAvailability.inventory)
                        || (rule.availabilityType === "Amount" && rule.availabilityValue >= dayAvailability.freeRooms)) {
                            if (rule.applyType === "Percent") {
                                val = val + (rule.applyValue / 100) * val;
                            }
                            else {
                                val = val + rule.applyValue;
                            }
                        };
                    }
                });
            };

            const hotelPercentage = 100 - (dayAvailability.complexFreeRooms / dayAvailability.complexInventory) * 100;
            if (PriceByAvail && PriceByAvail.length > 0) {
                PriceByAvail.forEach((rule, i) => {
                    if (restrictionType !== 'Price Override By Complex Availability' || isNaN(currIndex) || currIndex < 0 || currIndex > i) {
                        if ((rule.availabilityType === "Percent" && hotelPercentage >= rule.availabilityValue) ||
                        (rule.availabilityType === "Amount" && rule.availabilityValue >= dayAvailability.complexFreeRooms)) {
                            if (rule.applyType === "Percent") {
                                val = val + (rule.applyValue / 100) * val;
                            }
                            else {
                                val = val + rule.applyValue;
                            }
                        };
                    }
                });
            };

            if (OverrideRules && OverrideRules.length > 0) {
                OverrideRules.forEach((rule, i) => {
                    if (restrictionType !== 'Price Override' || isNaN(currIndex) || currIndex < 0 || currIndex > i) {
                        if (rule.applyType === "Percent") {
                            val = val + (rule.applyValue / 100) * val;
                        }
                        else {
                            val = val + rule.applyValue;
                        };
                    }
                });
            };
        }

        //Calculate package
        if (withPackage && selectedMapping?.priceMarkupIncludePackageComponents && (!markup || !selectedMapping?.excludePriceComponent)) {
            val = val + this.getPackagePrice(dayAvailability);
        }

        //Calculate Mapping Markup
        if (markup) {
            if (selectedMapping?.priceMarkupType === 1 && selectedMapping?.priceMarkupValue) {
                val = isNaN(selectedMapping.priceMarkupValue * val) ? 0 : (selectedMapping.priceMarkupValue * val);
            }
            else {
                val = isNaN((selectedMapping?.priceMarkupValue || 0) + val) ? 0 : (selectedMapping?.priceMarkupValue || 0) + val;
            }
        }

        //Calculate package
        if (withPackage && !selectedMapping?.priceMarkupIncludePackageComponents && (!markup || !selectedMapping?.excludePriceComponent)) {
            val = val + this.getPackagePrice(dayAvailability);
        }

        // If there's currency conversions values, this channel is sold in a diff currency from
        // the configured rate currency and is calculating total price (with rules, package and channel markup)
        if(currencies && selectedMapping && selectedMapping.convertCurrencyToChannel && selectedMapping.currencyConvertion && withRules && withPackage && markup){
            const conversionValue = currencies.find(({baseCurrency, targetCurrency}) => targetCurrency === selectedMapping.currencyConvertion && baseCurrency === rate.currencyCode)?.rate??1
            val = val * conversionValue;
        }

        return val > 0 ? val : 0;
    }

    getPercentage = (dayAvailability, isHost) => {
        if (dayAvailability && dayAvailability.freeRooms && isHost) {
            if (dayAvailability.inventory > 0) {
                const inventory = dayAvailability.inventory - dayAvailability.outOfInventory - dayAvailability.outOfOrder;
                const freeRooms = dayAvailability.freeRooms < 0 ? inventory : inventory - dayAvailability.freeRooms;
                return (parseInt((freeRooms / inventory) * 100));
            }
        }
    }

    getPackagePrice = (dayAvailability) => {
        const { selectedOcc, rate } = this.state;

        let val = 0;
        if (dayAvailability.isPriceModeComponent) {
            if (selectedOcc?.length !== 3) {
                if (selectedOcc === "d2") {
                    val = val + dayAvailability.componentChildrenGroup2
                }
                else {
                    val = val + (dayAvailability.componentPrice * parseInt(selectedOcc[1]));
                }
            }
            else {
                const adults = rate.defaultPax?.find(({ position }) => position === parseInt(`${selectedOcc[1]}${selectedOcc[2]}`)).adults;
                val = val + (adults * dayAvailability.componentPrice);

                const child = rate.defaultPax?.find(({ position }) => position === parseInt(`${selectedOcc[1]}${selectedOcc[2]}`)).childrens;
                val = val + dayAvailability.componentChildrenGroup2 * child;
            }
        }
        return val;
    }

    renderSelectDay = (selectDay) => {
        const { selectedOcc, selectdayAvailability, selectedk, activeTab, currencyCode } = this.state;

        const selectedMapping = this.getSelectedMapping();

        return (
            activeTab !== '1' || global.operationMode === 'PmsFull' ?
                <Row className="mt-2">
                    <Col className="col-6">
                        <Card className="px-4 py-3 shadow border-0">
                            <Row>
                                <Col>
                                    <b>{selectDay}</b>
                                </Col>
                                {selectedMapping ?
                                    <Col className="text-center">
                                        <b>{selectedMapping.channelName}</b>
                                    </Col>
                                : ''}
                                <Col className="col-4 text-right">
                                    <Row>
                                        <Col style={{ color: '#165C7D' }}>
                                            <b><FormattedNumber value={this.calculatePrice(selectdayAvailability, selectedk, true, true, true)} style="currency" currency={selectedMapping?.convertCurrencyToChannel ? selectedMapping.currencyConvertion : (currencyCode || "EUR")} minimumFractionDigits={2} /></b>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <Row>
                                        <Col className="col-1"></Col>
                                        <Col>
                                            <FormattedMessage id="BulkUpdate.BaseValue" />
                                        </Col>
                                        <Col className="col-4 text-right">
                                            <FormattedNumber value={this.calculatePrice(selectdayAvailability, selectedk)} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {this.appliedRules().map((rule, i) => 
                                rule.ruleType === "Price Change" ?
                                    <Row className="mt-1">
                                        <Col className="col-1">
                                            <div className='mr-2 mt-1' style={{ width: '10px', height: '10px', background: '#42b742', borderRadius: '20px' }} />
                                        </Col>
                                        <Col>
                                            {rule.name}
                                        </Col>
                                        <Col className="col-5 text-right">
                                            {rule.applyType == 'Percent' ?
                                                <Row>
                                                    <Col className='mr-1'>
                                                        ({rule.applyValue}%)
                                                    </Col>
                                                    <Col className="col-5 pl-0">
                                                        <FormattedNumber value={((rule.applyValue / 100) * this.calculatePrice(selectdayAvailability, selectedk, i !== 0, false, false, i))} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                    </Col>
                                                </Row>
                                                :
                                                <FormattedNumber value={rule.applyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                            }
                                        </Col>
                                    </Row>
                                : rule.ruleType === "Price Override By Complex Availability" ?
                                    <Row className="mt-1">
                                        <Col className="col-1">
                                            <div className='mr-2 mt-1' style={{ width: '10px', height: '10px', background: 'rgb(255, 222, 49)', borderRadius: '20px' }} />
                                        </Col>
                                        <Col>
                                            {rule.name}
                                        </Col>
                                        <Col className="col-5 text-right">
                                            {rule.applyType == 'Percent' ?
                                                <Row>
                                                    <Col className='mr-1'>
                                                        ({rule.applyValue}%)
                                                    </Col>
                                                    <Col className="col-5 pl-0">
                                                        <FormattedNumber value={((rule.applyValue / 100) * this.calculatePrice(selectdayAvailability, selectedk, i !== 0, false, false, i))} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                    </Col>
                                                </Row>
                                                :
                                                <FormattedNumber value={rule.applyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                            }
                                        </Col>
                                    </Row>
                                :
                                    <Row className="mt-1">
                                        <Col className="col-1">
                                            <div className='mr-2 mt-1' style={{ width: '10px', height: '10px', background: '#e37a4a', borderRadius: '20px' }} />
                                        </Col>
                                        <Col>
                                            {rule.name}
                                        </Col>
                                        <Col className="col-5 text-right">
                                            {rule.applyType == 'Percent' ?
                                                <Row>
                                                    <Col className='mr-1'>
                                                        ({rule.applyValue}%)
                                                    </Col>
                                                    <Col className="col-5 pl-0">
                                                        <FormattedNumber value={((rule.applyValue / 100) * this.calculatePrice(selectdayAvailability, selectedk, i !== 0, false, false, i))} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                    </Col>
                                                </Row>
                                                :
                                                <FormattedNumber value={rule.applyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                            }
                                        </Col>
                                    </Row>
                            )}
                            {selectdayAvailability.isPriceModeComponent && selectedMapping?.priceMarkupIncludePackageComponents && !selectedMapping?.excludePriceComponent ?
                                <Row className="mt-2">
                                    <Col>
                                        <Row>
                                            <Col className="col-1"></Col>
                                            <Col>
                                                <FormattedMessage id="BulkUpdate.PackageValue" />
                                            </Col>
                                            <Col className="col-4 text-right">
                                                <Row>
                                                    <Col className='mr-1'>
                                                        ({this.getOccupation().adults ?
                                                            <span className='ml-1'>{this.getOccupation().adults} <i className="fas fa-user ml-1" /></span>
                                                            : ''}
                                                        {this.getOccupation().children ?
                                                            <span className='ml-1'>{this.getOccupation().children} <i className="fas fa-child ml-1" /></span>
                                                            : ''})
                                                    </Col>
                                                    <Col className="col-5 pl-0">
                                                        <FormattedNumber
                                                            value={this.getPackagePrice(selectdayAvailability)}
                                                            style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            : ''}
                            {selectedMapping?.priceMarkupType && selectedMapping.priceMarkupType > 0 && selectedMapping.priceMarkupValue ?
                                <Row className="mt-1">
                                    <Col className="col-1"></Col>
                                    <Col>
                                        <FormattedMessage id="BulkUpdate.MappingMarkup" />
                                    </Col>
                                    <Col className="col-4 text-right">
                                        <Row>
                                            <Col className='mr-1'>
                                                ({selectedMapping.priceMarkupType === 1 ? 'x' : '+'} {selectedMapping.priceMarkupValue})
                                            </Col>
                                            {selectedMapping.priceMarkupType === 1 ?
                                                <Col className="col-5 pl-0">
                                                    <FormattedNumber
                                                        value={
                                                            (selectedMapping.priceMarkupValue * (this.calculatePrice(selectdayAvailability, selectedk, true, true)))
                                                            - (this.calculatePrice(selectdayAvailability, selectedk, true, true))}
                                                        style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                </Col>
                                                : ''}
                                        </Row>
                                    </Col>
                                </Row>
                            : ''}
                            {selectdayAvailability.isPriceModeComponent && !selectedMapping?.priceMarkupIncludePackageComponents && !selectedMapping?.excludePriceComponent ?
                                <Row className="mt-2">
                                    <Col>
                                        <Row>
                                            <Col className="col-1"></Col>
                                            <Col>
                                                <FormattedMessage id="BulkUpdate.PackageValue" />
                                                {selectedOcc?.length === 3 ? <i className="fas fa-user ml-1"></i> : ''}
                                            </Col>
                                            <Col className="col-4 text-right">
                                                <Row>
                                                    <Col className='mr-1'>
                                                        ({this.getOccupation().adults ?
                                                            <span className='ml-1'>{this.getOccupation().adults} <i className="fas fa-user ml-1" /></span>
                                                            : ''}
                                                        {this.getOccupation().children ?
                                                            <span className='ml-1'>{this.getOccupation().children} <i className="fas fa-child ml-1" /></span>
                                                            : ''})
                                                    </Col>
                                                    <Col className="col-5 pl-0">
                                                        <FormattedNumber value={this.getPackagePrice(selectdayAvailability)} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            : ''}
                            {selectedMapping?.currencyConvertion && selectedMapping?.convertCurrencyToChannel ?
                                <Row className="mt-2">
                                    <Col>
                                        <Row>
                                            <Col className="col-1"></Col>
                                            <Col className="d-flex align-items-center">
                                                <div>
                                                    <FormattedMessage id="BulkUpdate.CurrencyConversion"/>
                                                </div>
                                                <div className='ml-2'>
                                                    {`${currencyCode} -> ${selectedMapping.currencyConvertion}`}
                                                </div>
                                            </Col>
                                            <Col className="col-4 d-flex align-items-center justify-content-end">
                                                <div>
                                                    <span className='mr-1'>x</span>
                                                    {this.getCurrencyExchangeValue(currencyCode, selectedMapping.currencyConvertion)}
                                                </div>
                                                <div className='ml-1'>
                                                    (
                                                        <FormattedNumber
                                                            value={this.calculatePrice(selectdayAvailability, selectedk) * this.getCurrencyExchangeValue(currencyCode, selectedMapping.currencyConvertion)}
                                                            style="currency"
                                                            currency={selectedMapping.currencyConvertion || currencyCode || "EUR"}
                                                            minimumFractionDigits={2}
                                                        />
                                                    )
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            :''}
                        </Card>
                    </Col>
                </Row>
            :''
        );
    }

    getCurrencyExchangeValue = (from, to) => {
        const { currencies } = this.state;

        return currencies?.find(({baseCurrency, targetCurrency}) => targetCurrency === to && baseCurrency === from)?.rate??1
    }

    getSelectedMapping = () => {
        const { rateMappings } = this.state;
        return rateMappings?.find((map) => map.rateCodeChannelId === this.state.selectedMapping);
    }

    checkSelectDay = (dayAvailability, d, k) => {
        const hasPrice = (this.state.activeTab === "2" || global.operationMode === 'PmsFull') && (dayAvailability && d?.moment && this.calculatePrice(dayAvailability, k, false, true) > 0);
        if (hasPrice) {
            this.setState({ selectdayAvailability: dayAvailability, selectedk: k });
        }
        return hasPrice;
    }

    appliedRules = () => {
        const { selectdayAvailability, selectedk } = this.state;

        const isHost = global.PMSType === 'Host' || global.PMSType === 'None';
        const percentage = this.getPercentage(selectdayAvailability, isHost);

        if(!selectdayAvailability)
            return [];

        const hotelPercentage = 100 - (selectdayAvailability.complexFreeRooms / selectdayAvailability.complexInventory) * 100;

        const priceChange = this.findRule('Price Change', { moment: moment(selectdayAvailability.hotelDate) }, selectedk)
        .filter(rule => (rule.availabilityType === "Percent" && rule.availabilityValue <= percentage) || (rule.availabilityType === "Amount" && rule.availabilityValue >= selectdayAvailability.freeRooms)
        || (rule.availabilityType === "Percent" && rule.availabilityValue <= selectdayAvailability.inventory));
        
        const priceOverride = this.findRule('Price Override', { moment: moment(selectdayAvailability.hotelDate) }, selectedk);

        const priceOverrideComplex = this.findRule('Price Override By Complex Availability', { moment: moment(selectdayAvailability.hotelDate) }, selectedk)
        .filter(rule => (rule.availabilityType === "Percent" && hotelPercentage >= rule.availabilityValue) ||
        (rule.availabilityType === "Amount" && rule.availabilityValue >= selectdayAvailability.complexFreeRooms));
 
        return (priceChange.concat(priceOverride).concat(priceOverrideComplex));
    }

    getOccupation = () => {
        const { rate, selectedOcc } = this.state;

        const occupation = {
            adults: 0,
            children: 0
        };

        if (selectedOcc?.length === 3) {

            occupation.adults = occupation.adults + rate.defaultPax?.find(({ position }) => position === parseInt(`${selectedOcc[1]}${selectedOcc[2]}`)).adults;

            for (let i = 0; i < rate.defaultPax?.find(({ position }) => position === parseInt(`${selectedOcc[1]}${selectedOcc[2]}`)).childrens; i++) {
                occupation.children++;
            }

        }
        else if (selectedOcc === 'd2') {
            occupation.children++;
        }
        else {
            occupation.adults = occupation.adults + parseInt(selectedOcc[1]);
        }

        return occupation;
    }

    buildCalendarStruct = (startDate) => {
        let blank = this.getBlankCells(startDate);
        let daysInMonth = this.getDaysInMonth(startDate);
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], rows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push({ day: "" })
                }
                rows.push(cells);
            }
        });

        let blankSec = this.getBlankCells(startDate, true);
        let daysInMonthSec = this.getDaysInMonth(startDate, true);
        let totalSlotsSec = [...blankSec, ...daysInMonthSec];
        let cellsSec = [], rowsSec = [];

        totalSlotsSec.forEach((row, i) => {
            if (i % 7 !== 0) {
                cellsSec.push(row); // if index not equal 7 that means not go to next week
            } else {
                rowsSec.push(cellsSec); // when reach next week we contain all td in last week to rows 
                cellsSec = []; // empty container 
                cellsSec.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlotsSec.length - 1) { // when end loop we add remain date
                while (cellsSec.length < 7) {
                    cellsSec.push({ day: "" })
                }
                rowsSec.push(cellsSec);
            }
        });

        this.setState({ calendarMonths: [rows, rowsSec], sumbittedStartDate: startDate });
    }

    getFirstDayOfMonth = (startDate, sec) => {
        let dateObject = startDate;

        if (sec) dateObject = moment(startDate).add(1, 'month');

        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    getDaysInMonth = (startDate, sec) => {
        let daysInMonth = [];

        if (sec) {
            for (let d = 1; d <= moment(startDate).add(1, 'month').daysInMonth(); d++) {
                let date = moment(startDate).add(1, 'month').format('MM') + '-' + d + '-' + moment(startDate).add(1, 'month').format('YYYY');
                daysInMonth.push({
                    day: d,
                    moment: moment(date)
                });
            }
        }
        else {
            for (let d = 1; d <= moment(startDate).daysInMonth(); d++) {
                let date = moment(startDate).format('MM') + '-' + d + '-' + moment(startDate).format('YYYY');
                daysInMonth.push({
                    day: d,
                    moment: moment(date)
                });
            }
        }

        return daysInMonth;
    }

    getBlankCells = (startDate, sec) => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(startDate, sec); i++) {
            blanks.push({
                day: ""
            });
        }

        return blanks
    }

    handleDates = (startDate, endDate) => {
        const { dates } = this.state;
        if(endDate && this.state.endDate){
            this.setState({ startDate, endDate: null });
        }
        else if(!dates?.find((d) => moment(d.startDate).isBetween(startDate, endDate) || moment(d.endDate).isBetween(startDate, endDate))){
            if (startDate !== null && endDate !== null) {
                dates.push({
                    startDate: moment(startDate, 'YYYY-MM-DD').format("YYYY-MM-DD"),
                    endDate: moment(endDate, 'YYYY-MM-DD').format("YYYY-MM-DD")
                });
                this.setState({ dates }, () => this.selectDate(startDate, endDate, true));
            }
            else {
                this.setState({ startDate, endDate });
            }
        }
    }

    isOutsideRange = (day) => {
        const { dates, startDate, endDate } = this.state;
        
        if(day?.isBefore(moment(), 'day') || day > moment().add(2, 'years'))
            return true;

        if(startDate && day?.isSameOrAfter(moment(startDate).add(1, 'year')))
            return true;

        if(dates){
            if(dates.find(({startDate}) => day?.isSameOrAfter(moment(startDate).clone().add(1, 'year'))))
                return true;

            if(startDate && !endDate){
                const closestDate = dates.sort((a,b) => a.startDate - b.startDate).find((d) => moment(d.startDate).isAfter(startDate));
        
                if(closestDate && closestDate.startDate && day?.isSameOrAfter(closestDate.startDate))
                    return true;
            }
        }
    }

    getHighestComplexPercentage = () => {
        const { currAvailabilities } = this.state;
        
        if(!currAvailabilities?.availability)
            return 0;

        const freeRoomsList = currAvailabilities.availability.map(({complexFreeRooms}) => complexFreeRooms);
        const min = Math.min(...freeRoomsList);
        const inv = currAvailabilities?.availability?.find(({ complexFreeRooms }) => min === complexFreeRooms)?.complexInventory??0;
        return 100 - (min / inv) * 100;
    }

    getHighestPercentage = () => {
        const { currAvailabilities } = this.state;
        
        if(!currAvailabilities?.availability)
            return 0;

        const freeRoomsList = currAvailabilities.availability.map(({freeRooms}) => freeRooms);
        const min = Math.min(...freeRoomsList);
        const inv = currAvailabilities.availability.find(({freeRooms}) => min === freeRooms)?.inventory??0;
        return 100 - (min / inv) * 100;
    }

    handleChangeChildPrices = (evt, occ) => {
        const { childPrices, childPriceMode } = this.state;

        const value = evt?.target?.value && !isNaN(parseInt(evt?.target?.value)) ? evt.target.value : null;
        const idx = childPrices.indexOf(childPrices.find(p => p.position === occ));

        if (idx === -1) {
            childPrices.push({
                position: occ,
                adultPrice: value,
                priceMode: childPriceMode
            })
        }
        else {
            if(!value){
                childPrices.splice(idx, 1);
            }
            else{
                const p = childPrices[idx];
                p.adultPrice = value;
            }
        }

        this.setState({ childPrices });
    }

    getPriceMode = (priceMode) => {
        return this.state.priceTypes.find(pt => priceMode === pt.value);
    }

    render() {
        const { block, error, paxList, rateCodes, selectedRate, currAvailabilities, startDate, sumbittedStartDate, weekdays, selectedOcc, rateMappings, ChangeComplexRuleOpen,
        selectedMapping, rateRules, ChangeRuleOpen, OverrideRuleOpen, activeTab, occupationSelect, rates, dates, endDate, selectdayAvailability, selectedk, calendarMonths,
        displayPricesTab, freeRoomsFieldsIdx, hasComplexPriceRules, price, priceAction, childPrices, hasFlexChildPrices, canD3, priceType, currencyCode } = this.state;

        const validWeekDays = [
            {
                value: "Mon",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />,
                weekday: 0
            },
            {
                value: "Tue",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />,
                weekday: 1
            },
            {
                value: "Wed",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />,
                weekday: 2
            },
            {
                value: "Thu",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />,
                weekday: 3
            },
            {
                value: "Fri",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />,
                weekday: 4
            },
            {
                value: "Sat",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />,
                weekday: 5
            },
            {
                value: "Sun",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />,
                weekday: 6
            }
        ];
        
        const currRate = rates?.find(rate => rate.id === selectedRate);

        const freeRoomsFields = [
            {
                freeRooms: 'freeRooms',
                inventory: 'inventory',
                outOfOrder: 'outOfOrder',
                outOfInventory: 'outOfInventory'
            },
            {
                freeRooms: 'complexFreeRooms',
                inventory: 'complexInventory',
                outOfOrder: 'complexOutOfOrder',
                outOfInventory: 'complexOutOfInventory'
            }
        ]

        const mappingsTabActive = (!childPrices || childPrices.length === 0) && (!price || price.length === 0);
        
        const d2 = childPrices?.find(({position}) => position === "d2");
        const d3 = childPrices?.find(({ position }) => position === "d3");

        return (
            <StyledCard block={block} error={error} title={global.operationMode !== 'PmsFull' ? "navbar.BulkUpdatePrices" : "navbar.BulkUpdatePricesPMSFULL"} help={<FormattedMessage id="BulkUpdate.Help"/>} id="BulkPricesUpdateHelp">
                <form ref={this.formCommon} >
                    <Row className="align-items-center">
                        <Col className="col-3">
                            <CustomSelect
                                icon={'fas fa-gift'}
                                required
                                placeholder={<FormattedMessage id='BulkUpdate.ChooseRate' />}
                                options={rateCodes}
                                value={rateCodes.find(rate => rate.value === selectedRate)}
                                onChange={this.handleRateChange}
                            />
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FormattedMessage id="MajorBulkUpdateModal.Dates" />
                        </Col>
                        <Col className="col-3">
                            <CustomDateRangePicker
                                required
                                startDate={startDate}
                                startDateId="reststartDate"
                                isOutsideRange={this.isOutsideRange}
                                endDate={endDate}
                                endDateId="restsendDate"
                                numberOfMonths={2}
                                isDayBlocked={this.isDayBlocked}
                                minimumNights={0}
                                onDatesChange={(startDate, endDate) => this.handleDates(startDate, endDate)}
                                showYearOptions={{ pastYears: false, futureYears: true }}
                            />
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FormattedMessage id="MajorBulkUpdateModal.Weekdays" />
                        </Col>
                        <Col className="col-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <ButtonGroup name="weekdays" size="sm">
                                {validWeekDays && validWeekDays.map((weekDay, key) =>
                                    <Button key={key} className={this.state.weekdays.find(el => el === weekDay.value) ? 'btn btn-host' : 'btn'} onClick={() => this.onClickWeekDays(weekDay.value)}>
                                        {weekDay.label}
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Col>
                        <Col className="col">
                            <Button className="btn btn-sm btn-host float-right shadow" onClick={() => this.getAvailability()} disabled={!selectedRate || !startDate || !endDate}>
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-3">
                            {currAvailabilities?.roomDesc ?
                                <div style={{ fontSize: '0.9em' }} className="mt-1">
                                    <FormattedMessage id="MajorBulkUpdateModal.Category" />:
                                    <span style={{ display: 'inline' }}><b className='ml-1'>{currAvailabilities.roomDesc}</b></span>
                                </div>
                                : ''}
                        </Col>
                        <Col className="col-5">
                            {dates.map((date, key) =>
                                <div className='mr-2' key={key} style={{ display: 'inline-block' }}>
                                    <Badge color="primary" className="p-2 my-1" style={{ cursor: 'pointer' }}>
                                        <span>
                                            <span onClick={() => this.selectDate(date.startDate, date.endDate, false, true)}>
                                                <FormattedMessage id="BookingPromotionCard.From" /> {`${date.startDate}`} <FormattedMessage id="BookingPromotionCard.To" /> {`${date.endDate}`}
                                            </span>
                                            <span className="text-right ml-3" onClick={_ => this.removeDate(key)} style={{ cursor: 'pointer' }}>
                                                <i className="fas fa-times text-white" />
                                            </span>
                                        </span>
                                    </Badge>
                                </div>
                            )}
                        </Col>
                        <Col className="d-flex align-items-center justify-content-end">
                            {global.operationMode !== 'None' ?
                                <Row className="mt-2" style={{ display: hasComplexPriceRules }}>
                                    <Col className="d-flex justify-content-end">
                                        <span className="mr-2">
                                            <FormattedMessage id="BulkUpdate.DisplayComplexAvailability" />
                                        </span>
                                        <CustomInput type="switch"
                                            name="displayComplexAvailability"
                                            id="displayComplexAvailability"
                                            bsSize="sm" className="text-sm"
                                            checked={this.state.freeRoomsFieldsIdx === 1}
                                            onChange={evt => this.setState({ freeRoomsFieldsIdx: evt.target.checked ? 1 : 0 })}
                                        />
                                    </Col>
                                </Row>
                            : ''}
                        </Col>
                    </Row>
                </form>
                <hr />
                {currAvailabilities && (global.operationMode !== 'PmsFull' || rateMappings?.length > 0) ?
                    <div>
                        <Row>
                            <Col className="col-4">
                                <form className='mt-2 h-100' ref={this.formPrice}>
                                    <Nav style={global.operationMode !== 'PmsFull' ? displayPricesTab === "none" ? { display: displayPricesTab } : {} : {display: 'none'}} tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: 'pointer' }}
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => this.toggleTab("1")}
                                            >
                                                <FormattedMessage id="BulkUpdate.EditPrices" />
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={!mappingsTabActive ? "noHoverNavItem" : ''}>
                                            <NavLink
                                                active={mappingsTabActive}
                                                style={{ cursor: 'pointer' }}
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => mappingsTabActive && this.toggleTab("2")}
                                            >
                                                <FormattedMessage id="BulkUpdate.EditMappings" />
                                                {!mappingsTabActive ?
                                                    <i style={{ color: "#3d3d3d" }} className="fas fa-lock ml-2"></i>
                                                :''}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={global.operationMode !== 'PmsFull' ? this.state.activeTab : '2'} className="h-100">
                                        {this.state.activeTab === "1" && global.operationMode !== 'PmsFull' ?
                                            <TabPane style={{ border: 'none', height: '100%' }} tabId="1">
                                                <div style={{ display: displayPricesTab, height: '100%' }}>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <Row className="mb-1">
                                                                    <Col className="col-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <i className="fas fa-dollar-sign mr-2" />
                                                                        <FormattedMessage id="BulkUpdate.Price" />
                                                                    </Col>
                                                                    <Col className="col-7">
                                                                        <CustomSelect
                                                                            required
                                                                            placeholder={'PriceAction'}
                                                                            options={this.state.priceActions}
                                                                            value={this.state.priceActions.find(priceAction => priceAction.value === this.state.priceAction)}
                                                                            onChange={this.setPriceModeByPriceAction.bind(this, 'priceAction')}
                                                                        />
                                                                    </Col>
                                                                    <Col className="col-2">
                                                                        <Button className="btn btn-sm btn-host float-right shadow" disabled={!CheckAuthorization("bulkUpdatePrices:save", null) || priceAction !== "copyLastYear" && (!selectedRate || !currAvailabilities || (price.length === 0 && (childPrices && childPrices.length === 0)))} onClick={this.updatePriceBulk.bind(this)}>
                                                                            <i className="fas fa-save" />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                {paxList?.filter(el => el.childrens > 0).length > 0 ?
                                                                    <Row className="mb-1">
                                                                        <Col className="col-3 pointer" onClick={() => this.changeSelectedOcc('d2')}>
                                                                            {hasFlexChildPrices && canD3 ?
                                                                                <span className='mr-1'>1º</span>
                                                                            :''}
                                                                            <i className={"fas fa-child " + (selectedOcc === `d2` ? ' color-host' : '')} />
                                                                        </Col>
                                                                        <Col className="col-9">
                                                                            <Row>
                                                                                {this.state.priceType != null && this.state.priceType !== this.state.priceActions[0].value ?
                                                                                    <Col className="col-4">
                                                                                        <CustomSelect isSearchable={false} required options={this.state.priceTypes} value={this.state.priceTypes.find(priceType => priceType.value === this.state.childPriceMode)} onChange={this.setComboStatus.bind(this, 'childPriceMode')} />
                                                                                    </Col>
                                                                                : ''}
                                                                                <Col>
                                                                                    <InputGroup size="sm" >
                                                                                        <InputGroupAddon addonType="prepend">
                                                                                            <InputGroupText style={{ borderRight: 'unset' }}>
                                                                                                <i className={`fas fa-${this.state.childPriceMode === this.state.priceTypes[1].value ? `percent` : `dollar-sign`}`} />
                                                                                            </InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <FormattedMessage id={(hasFlexChildPrices && canD3) ? "BulkUpdate.PricePerFirstChild" : "BulkUpdate.PricePerChild"}>
                                                                                            {placeholder =>
                                                                                                <Input
                                                                                                    className="border-left-0"
                                                                                                    id="childPrice"
                                                                                                    type="number"
                                                                                                    max='999999999'
                                                                                                    step='0.01'
                                                                                                    value={d2?.adultPrice || d2?.adultPrice === 0 ? d2.adultPrice : ''}
                                                                                                    placeholder={placeholder}
                                                                                                    onChange={e => this.handleChangeChildPrices(e, 'd2')}
                                                                                                    onFocus={() => this.changeSelectedOcc('d2')}
                                                                                                />
                                                                                            }
                                                                                        </FormattedMessage>
                                                                                    </InputGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                : ''}
                                                                {hasFlexChildPrices && canD3 && paxList?.filter(el => el.childrens > 1).length > 0 ?
                                                                    <Row className="mb-1">
                                                                        <Col className="col-3 pointer" onClick={() => this.changeSelectedOcc('d3')}>
                                                                            <i className={"fas fa-child " + (selectedOcc === `d3` ? ' color-host' : '')} />
                                                                            <span className='ml-1'>{`>1`}</span>
                                                                        </Col>
                                                                        <Col className="col-9">
                                                                            <Row>
                                                                                {this.state.priceType != null && this.state.priceType !== this.state.priceActions[0].value ?
                                                                                    <Col className="col-4">
                                                                                        <CustomSelect
                                                                                            isSearchable={false}
                                                                                            required
                                                                                            options={this.state.priceTypes}
                                                                                            value={this.state.priceTypes.find(priceType => priceType.value === this.state.childPriceMode)}
                                                                                            onChange={this.setComboStatus.bind(this, 'childPriceMode')}
                                                                                        />
                                                                                    </Col>
                                                                                : ''}
                                                                                <Col>
                                                                                    <InputGroup size="sm" >
                                                                                        <InputGroupAddon addonType="prepend">
                                                                                            <InputGroupText style={{ borderRight: 'unset' }}>
                                                                                                <i className={`fas fa-${this.state.childPriceMode === this.state.priceTypes[1].value ? `percent` : `dollar-sign`}`} />
                                                                                            </InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <FormattedMessage id="BulkUpdate.PricePerOtherChild">
                                                                                            {placeholder =>
                                                                                                <Input
                                                                                                    className="border-left-0"
                                                                                                    id="childPrice2"
                                                                                                    type="number"
                                                                                                    max='999999999'
                                                                                                    step='0.01'
                                                                                                    value={d3?.adultPrice || d3?.adultPrice === 0 ? d3?.adultPrice : ''}
                                                                                                    placeholder={placeholder}
                                                                                                    onChange={e => this.handleChangeChildPrices(e, 'd3')}
                                                                                                    onFocus={() => this.changeSelectedOcc('d3')}
                                                                                                />
                                                                                            }
                                                                                        </FormattedMessage>
                                                                                    </InputGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                : ''}
                                                                {paxList?.map((item, key) => {
                                                                    const priceObj = this.state.price.find(el => el.position === item.adults);

                                                                    return (
                                                                        item.childrens === 0 ?
                                                                            <Row key={key} className="mb-1">
                                                                                <Col className="col-3">
                                                                                    <div className={"pointer " + (selectedOcc === `p${item.position}` ? ' color-host' : '')} onClick={() => this.changeSelectedOcc(`p${item.position}`)}>
                                                                                        <i className="fas fa-user" />({item.adults})
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="col-9">
                                                                                    <Row>
                                                                                        {this.state.priceType != null && this.state.priceType !== this.state.priceActions[0].value ?
                                                                                            <Col className="col-4">
                                                                                                <CustomSelect isSearchable={false} required options={this.state.priceTypes}
                                                                                                    value={this.getPriceMode(priceObj?.priceMode || this.state.priceType)}
                                                                                                    onChange={this.setComboStatusOnAdultPrice.bind(this, 'priceType', item.adults)}
                                                                                                />
                                                                                            </Col>
                                                                                            :
                                                                                            <div />
                                                                                        }
                                                                                        <Col>
                                                                                            <InputGroup size="sm" >
                                                                                                <InputGroupAddon addonType="prepend">
                                                                                                    <InputGroupText style={{ borderRight: 'unset' }}>
                                                                                                        <i className={`fas fa-${((priceObj && priceObj.priceMode) || this.state.priceType) === this.state.priceTypes[1].value ? `percent` : `dollar-sign`}`} />
                                                                                                    </InputGroupText>
                                                                                                </InputGroupAddon>
                                                                                                <Input
                                                                                                    disabled={currRate.isOccupancyDerived && !item.isBaseOccupancy}
                                                                                                    className="border-left-0" type="number"
                                                                                                    id={'price' + item.position}
                                                                                                    required max='999999999' step='0.01'
                                                                                                    placeholder={this.props.intl.formatMessage({ id: "BulkUpdate.PriceFor" }) + item.adults + this.props.intl.formatMessage({ id: "BulkUpdate.Adults" })}
                                                                                                    value={priceObj?.adultPrice || ''}
                                                                                                    onChange={this.setPrice.bind(this, item.isBaseOccupancy, currRate.isOccupancyDerived, item.adults, item.children, item.position, this.state.priceMode)}
                                                                                                    onFocus={() => this.changeSelectedOcc(`p${item.position}`)}
                                                                                                />
                                                                                            </InputGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        : ''
                                                                    );
                                                                })}
                                                            </div>
                                                            <div>
                                                                <Row className="mb-1 mt-2" style={{ fontSize: '0.9em'}}>
                                                                    <Col>
                                                                        <div>
                                                                            {priceAction === "copyLastYear" ?
                                                                                <>
                                                                                    <span><FormattedMessage id="BulkUpdate.Explain.CopyPrices"/></span>
                                                                                    {dates.map((date, key) => 
                                                                                        <span key={key}>
                                                                                            {key > 0 ?
                                                                                                <span>,</span>
                                                                                            :''}
                                                                                            <b className='ml-1'>{moment(date.startDate).clone().add(-1, 'year').format('YYYY/MM/DD')}</b>
                                                                                            <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.To"/></span>
                                                                                            <b className='ml-1'>{moment(date.endDate).clone().add(-1, 'year').format('YYYY/MM/DD')}</b>
                                                                                        </span>
                                                                                    )}
                                                                                    <span className="ml-1 mr-1"><FormattedMessage id="BulkUpdate.Explain.ApplyFrom"/></span>
                                                                                </>
                                                                            :
                                                                                    <span><FormattedMessage id="BulkUpdate.Explain.From"/></span>
                                                                            }
                                                                            {dates.map((date, key) => 
                                                                                <span key={key}>
                                                                                    {key > 0 ?
                                                                                        <span>,</span>
                                                                                    :''}
                                                                                    <b className='ml-1'>{moment(date.startDate).format('YYYY/MM/DD')}</b>
                                                                                    <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.To"/></span>
                                                                                    <b className='ml-1'>{moment(date.endDate).format('YYYY/MM/DD')}</b>
                                                                                </span>
                                                                            )}.
                                                                        </div>
                                                                        <div className='pr-1'>
                                                                            {childPrices && childPrices.length > 0 ?
                                                                                childPrices.sort((a) => a.position === "d2" ? -1 : 1).map((c, k) => 
                                                                                    <div key={k}>
                                                                                        {c.priceMode === "amount" ? 
                                                                                            <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.Set"/></span>
                                                                                        :
                                                                                            <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.Add"/></span>
                                                                                        }
                                                                                        <b className='ml-1'>
                                                                                            {c.adultPrice}
                                                                                            {c.priceMode === "percentage" ? 
                                                                                                '%'
                                                                                            :
                                                                                                '€'
                                                                                            }
                                                                                        </b>
                                                                                        <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.ToOcc"/></span>
                                                                                        <b>
                                                                                            <span className="ml-1">
                                                                                                {hasFlexChildPrices && canD3 ?
                                                                                                    c.position === "d2" ?
                                                                                                        <FormattedMessage id="BulkUpdate.Explain.FirstChildren"/>
                                                                                                    :
                                                                                                        <FormattedMessage id="BulkUpdate.Explain.OtherChildren"/>
                                                                                                :
                                                                                                    <FormattedMessage id="BulkUpdate.Explain.Children"/>
                                                                                                }
                                                                                            </span>
                                                                                        </b>
                                                                                    </div>
                                                                                )
                                                                            :
                                                                                <div>
                                                                                    <FormattedMessage id="BulkUpdate.Explain.NoChanges"/>
                                                                                    <b><span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.Children"/></span></b>
                                                                                </div>
                                                                            }

                                                                            {paxList.filter(({childrens}) => !childrens).map((item, key) => {
                                                                                const priceObj = price.find(el => el.position === item.adults);
                                                                                return(
                                                                                    priceObj ?
                                                                                        <div key={key}>
                                                                                            {!priceType ?
                                                                                                <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.Set"/></span>
                                                                                            :
                                                                                                <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.Add"/></span>
                                                                                            }
                                                                                            <b className='ml-1'>
                                                                                                {priceObj.adultPrice}
                                                                                                {priceType === "percentage" ? '%' : '€'}
                                                                                            </b>
                                                                                            <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.ToOcc"/></span>
                                                                                            <b className='ml-1'>
                                                                                                {item.adults}
                                                                                                <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.Adults"/></span>
                                                                                            </b>
                                                                                        </div>
                                                                                    :
                                                                                        <div key={key}>
                                                                                            <FormattedMessage id="BulkUpdate.Explain.NoChanges"/>
                                                                                            <b className='ml-1'>
                                                                                                {item.adults}
                                                                                                <span className="ml-1"><FormattedMessage id="BulkUpdate.Explain.Adults"/></span>
                                                                                            </b>
                                                                                        </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            {currAvailabilities.availability?.filter(({ isPriceModeComponent }) => isPriceModeComponent).length > 0 ?
                                                                <div>
                                                                    <Row className="mt-2" style={{ fontSize: '0.9em' }}>
                                                                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <FormattedMessage id="BulkUpdate.AssociatedPackage" />
                                                                        </Col>
                                                                        <Col className="col-3">
                                                                            <Row>
                                                                                <Col className="text-right">
                                                                                    <FormattedNumber
                                                                                        value={currAvailabilities.availability.filter(({ isPriceModeComponent }) => isPriceModeComponent)[0].componentPrice}
                                                                                        style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2}
                                                                                    />
                                                                                </Col>
                                                                                <Col className="col-1">
                                                                                    <i className="fas fa-user"></i>
                                                                                </Col>
                                                                            </Row>
                                                                            {currAvailabilities.availability.filter(({ isPriceModeComponent }) => isPriceModeComponent)[0].componentChildrenGroup2 ?
                                                                                <Row>
                                                                                    <Col className="text-right">
                                                                                        <FormattedNumber
                                                                                            value={currAvailabilities.availability.filter(({ isPriceModeComponent }) => isPriceModeComponent)[0].componentChildrenGroup2}
                                                                                            style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-1">
                                                                                        <i className="fas fa-child"></i>
                                                                                    </Col>
                                                                                </Row>
                                                                                : ''}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            : ''}
                                                        </div>
                                                        {!block && currAvailabilities ?
                                                            <div className='pb-5'>
                                                                <BulkPricesCellExplanation/>
                                                            </div>
                                                        :''}
                                                    </div>
                                                </div>
                                            </TabPane>
                                        :''}
                                        {this.state.activeTab === "2" || global.operationMode === 'PmsFull' ?
                                            <TabPane style={{ border: 'none' }} tabId="2">
                                                {rateMappings?.length > 0 ?
                                                    <Row>
                                                        <Col className="mt-4">
                                                            <i className="fas fa-random mr-1" />
                                                            <b><FormattedMessage id="BulkUpdate.Mappings" /></b>
                                                        </Col>
                                                        <Col className="col-3">
                                                            <CustomSelect
                                                                options={occupationSelect}
                                                                value={occupationSelect.find(({ value }) => value === selectedOcc)}
                                                                onChange={(combo) => this.changeSelectedOcc(combo?.value)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                : ''}
                                                <Row>
                                                    <Col>
                                                        {rateMappings?.filter(({ isIcal, priceMode, interfaceMode }) => !isIcal && priceMode !== -1 && interfaceMode !== 2).map((mapping, key) => {
                                                    
                                                            const channelConfig = getChannelConfig(mapping.channelCode);

                                                            const mappedRate = rates?.find(rate => rate.id === mapping?.rateCodeId);
                                                            const defaultPax = mappedRate?.defaultPax?.find(({ position }) => position === mapping?.pricePosition);
                                                    
                                                            return <Card className="my-2 p-2 shadow" key={key}
                                                                style={{
                                                                    border: 'unset', cursor: mapping.interfaceMode === 4 || (mapping.channelCode === "HRS" &&
                                                                    (mapping.rateCodeMapping === "business" || mapping.rateCodeMapping === "economy" || mapping.rateCodeMapping === "comfort"
                                                                    || mapping.rateCodeMapping?.includes("extraBed"))) ? '' : 'pointer',
                                                                    background: selectedMapping === mapping.rateCodeChannelId ? '#d4eaf7' :
                                                                    (mapping.interfaceMode === 4 || (mapping.channelCode === "HRS" &&
                                                                    (mapping.rateCodeMapping === "business" || mapping.rateCodeMapping === "economy" || mapping.rateCodeMapping === "comfort"
                                                                    || mapping.rateCodeMapping?.includes("extraBed")))) ? '#e1e1e1' : ''
                                                                }}
                                                                onClick={() => this.selectMapping(mapping.rateCodeChannelId)}>
                                                                <Row>
                                                                    <Col className="col-1 pr-0">
                                                                        {channelConfig ?
                                                                            <span title={mapping.channelName}>
                                                                                <img
                                                                                    alt=""
                                                                                    style={{ width: '100%', height: 'auto' }}
                                                                                    src={channelConfig.image}
                                                                                />
                                                                            </span>
                                                                        :''}
                                                                    </Col>

                                                                    <Col className='pr-0 col-2'>
                                                                        {mapping.priceMode !== -1 ?
                                                                            <div>
                                                                                <BulkPricesCell
                                                                                    mapping={mapping}
                                                                                    findRule={this.findRule}
                                                                                    anyDay={true}
                                                                                    getHighestComplexPercentage={this.getHighestComplexPercentage}
                                                                                    getHighestPercentage={this.getHighestPercentage}
                                                                                />
                                                                            </div>
                                                                        : ''}
                                                                    </Col>

                                                                    <Col>
                                                                        <Row>
                                                                            <Col>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                    <div className='d-flex'>
                                                                                        {mapping.priceMode !== -1 &&
                                                                                            <span>
                                                                                                <b><FormattedMessage id={'ChannelList.priceMode' + mapping.priceMode} /></b>
                                                                                            </span>
                                                                                        }
                                                                                        {mapping.priceMode === 1 || mapping.priceMode === 2 ?
                                                                                            <span className='ml-2'>
                                                                                                {mappedRate && mapping.pricePosition?.toString()?.length === 2 ?
                                                                                                    <>
                                                                                                        {defaultPax && defaultPax.adults > 0 ?
                                                                                                            <>
                                                                                                                <span>
                                                                                                                    <i key={key} className="fas fa-user"></i>
                                                                                                                    ({defaultPax?.adults})
                                                                                                                </span>
                                                                                                                {defaultPax.childrens ?
                                                                                                                    <span>
                                                                                                                        <i key={key} className="fas fa-child"></i>
                                                                                                                        ({defaultPax.childrens})
                                                                                                                    </span>
                                                                                                                :''}
                                                                                                            </>
                                                                                                        :''}
                                                                                                    </>
                                                                                                : mapping.pricePosition === 0 ?
                                                                                                    paxList[0]?.adults && paxList[0]?.adults > 0 ?
                                                                                                        <span>
                                                                                                            <i key={key} className="fas fa-user"></i>
                                                                                                            ({paxList[0]?.adults})
                                                                                                        </span>
                                                                                                    :''
                                                                                                :
                                                                                                    mapping.pricePosition && mapping.pricePosition > 0 ?
                                                                                                        <span>
                                                                                                            <i key={key} className="fas fa-user"></i>
                                                                                                            ({mapping.pricePosition})
                                                                                                        </span>
                                                                                                    :''
                                                                                                }
                                                                                            </span>
                                                                                            : ''}
                                                                                    </div>
                                                                                    <div>
                                                                                        <span>
                                                                                            {mapping.interfaceMode && mapping.interfaceMode > 0 ?
                                                                                                <Badge className="mr-1 p-1" color="primary">
                                                                                                    <FormattedMessage id={'generic.inventoryMode' + mapping.interfaceMode} />
                                                                                                </Badge>
                                                                                                : ''}
                                                                                        </span>
                                                                                        <span>
                                                                                            {mapping.mappingActive ?
                                                                                                <Badge color="primary"><FormattedMessage id="ChannelDashboard.Active" /></Badge>
                                                                                                :
                                                                                                <Badge color="secondary"><FormattedMessage id="ChannelDashboard.Inactive" /></Badge>
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        {mapping.priceMarkupType && mapping.priceMarkupType > 0
                                                                        && (mapping.channelCode !== "HRS" ||
                                                                        !(mapping.rateCodeMapping === "business" || mapping.rateCodeMapping === "economy" || mapping.rateCodeMapping === "comfort" || mapping.rateCodeMapping?.includes("extraBed"))) ?
                                                                            <Row>
                                                                                <Col>
                                                                                    <span className='mr-1'>
                                                                                        {mapping.priceMarkupType === 1 ?
                                                                                            <FormattedMessage id="generic.markupType1" />
                                                                                            : mapping.priceMarkupType === 2 ?
                                                                                                <FormattedMessage id="generic.markupType2" />
                                                                                        : ''}
                                                                                    </span>

                                                                                    {mapping.priceMarkupValue ?
                                                                                        <span>
                                                                                            <b>{mapping.priceMarkupValue}</b>
                                                                                        </span>
                                                                                        : ''}
                                                                                    <span className='mx-2'>-</span>
                                                                                    {mapping.priceMarkupIncludePackageComponents ?
                                                                                        <span>
                                                                                            <FormattedMessage id="EditChannelMapping.Rate&PriceComponent" />
                                                                                        </span>
                                                                                        :
                                                                                        <span>
                                                                                            <FormattedMessage id="EditChannelMapping.Rate" />
                                                                                        </span>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                            : ''}
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        })}
                                                    </Col>
                                                </Row>
                                                {rateRules?.length > 0 && rateRules.filter((rule) => rule.ruleType === "Price Change" || rule.ruleType === "Price Override By Complex Availability" || rule.ruleType === "Price Override").length > 0 ?
                                                    <Row>
                                                        <Col>
                                                            <Row className="mt-4">
                                                                <Col>
                                                                    <span className="mr-2 fas fa-sign" /> <b><FormattedMessage id="MajorBulkUpdateModal.Rules" /></b>
                                                                </Col>
                                                            </Row>
                                                            {rateRules.filter((rule) => rule.ruleType === "Price Change").length > 0 ?
                                                                <Card className="my-2 p-2 shadow" style={{ border: 'unset', cursor: 'pointer' }}>
                                                                    <div onClick={this.toggleChangeRuleOpen} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='mr-2' style={{ width: '10px', height: '10px', background: '#42b742', borderRadius: '20px' }} />
                                                                            <div>
                                                                                <b><FormattedMessage id="AddRule.IncreasePriceWhenSalesReachValue" />:</b>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <i className={ChangeRuleOpen ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'} />
                                                                        </div>
                                                                    </div>
                                                                    <Collapse isOpen={ChangeRuleOpen}>
                                                                        <Row>
                                                                            <Col>
                                                                                {rateRules.filter((rule) => rule.ruleType === "Price Change").map((rule, i) =>
                                                                                    <Row key={`ChangeRule${i}`} className="mt-1">
                                                                                        <Col className="col-4">
                                                                                            {rule.name}
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <div>
                                                                                                <FormattedMessage id={rule.availabilityType === "Percent" ? "AddRule.PriceChangeAvailabilityMessage" : 'AddRule.PriceChangeAvailabilityMessageAmount'}/> <b>{rule.availabilityValue} {rule.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.PriceChangeIncrementMessage" /> <b>{rule.applyValue} {rule.applyType == 'Percent' ? '%' : ''}</b>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    </Collapse>
                                                                </Card>
                                                            : ''}
                                                            {rateRules.filter((rule) => rule.ruleType === "Price Override By Complex Availability").length > 0 ?
                                                                <Card className="my-2 p-2 shadow" style={{ border: 'unset', cursor: 'pointer' }}>
                                                                    <div onClick={this.toggleChangeComplexRuleOpen} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='mr-2' style={{ width: '10px', height: '10px', background: 'rgb(255, 222, 49)', borderRadius: '20px' }} />
                                                                            <div>
                                                                                <b><FormattedMessage id="AddRule.IncreasePriceWhenComplexSalesReachValue" />:</b>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <i className={ChangeComplexRuleOpen ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'} />
                                                                        </div>
                                                                    </div>
                                                                    <Collapse isOpen={ChangeComplexRuleOpen}>
                                                                        <Row>
                                                                            <Col>
                                                                                {rateRules.filter((rule) => rule.ruleType === "Price Override By Complex Availability").map((rule, i) =>
                                                                                    <Row key={`ChangeRule${i}`} className="mt-1">
                                                                                        <Col className="col-4">
                                                                                            {rule.name}
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <div>
                                                                                                <FormattedMessage id={rule.availabilityType === "Percent" ? "AddRule.PriceChangeAvailabilityMessage" : 'AddRule.PriceChangeComplexAvailabilityMessageAmount'}/> <b>{rule.availabilityValue} {rule.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.PriceChangeIncrementMessage" /> <b>{rule.applyValue} {rule.applyType == 'Percent' ? '%' : ''}</b>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    </Collapse>
                                                                </Card>
                                                            : ''}
                                                            {rateRules.filter((rule) => rule.ruleType === "Price Override").length > 0 ?
                                                                <Card className="my-2 p-2 shadow" style={{ border: 'unset', cursor: 'pointer' }}>
                                                                    <div onClick={this.toggleOverrideRuleOpen} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='mr-2' style={{ width: '10px', height: '10px', background: '#e37a4a', borderRadius: '20px' }} />
                                                                            <div>
                                                                                <b><FormattedMessage id="AddRule.PriceOverride" />:</b>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <i className={OverrideRuleOpen ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'} />
                                                                        </div>
                                                                    </div>
                                                                    <Collapse isOpen={OverrideRuleOpen}>
                                                                        <Row>
                                                                            <Col>
                                                                                {rateRules.filter((rule) => rule.ruleType === "Price Override").map((rule, i) =>
                                                                                    <Row key={`OverrideRule${i}`} className="mt-1">
                                                                                        <Col className="col-4">
                                                                                            {rule.name}
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <div>
                                                                                                <FormattedMessage id="AddRule.PriceOverrideReviewMessage" /> <b>{rule.applyValue} {rule.applyType == 'Percent' ? '%' : ''}</b>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    </Collapse>
                                                                </Card>
                                                                : ''}
                                                        </Col>
                                                    </Row>
                                                : ''}

                                                {rates?.filter(rate => rate.dependencyParent === selectedRate).length > 0 ?
                                                    <>
                                                        <Row className="mt-4">
                                                            <Col>
                                                                <span className="fas fa-sitemap mr-2" /> <b><FormattedMessage id="MajorBulkUpdateModal.Dependencies" /></b>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {rates.filter(rate => rate.dependencyParent === selectedRate).map((rate, k) =>
                                                                    <Card className="my-2 p-2 shadow pointer" key={k} style={{ border: 'unset' }} onClick={() => this.handleRateChange({ value: rate?.id, isDependent: true }, true)}>
                                                                        <Row>
                                                                            <Col>
                                                                                {rate.name}
                                                                            </Col>
                                                                            <Col className="text-right">
                                                                                {
                                                                                    rate.dependecyOrder === null ?
                                                                                        <span>
                                                                                            {rate.dependencyPercent ?
                                                                                                <>
                                                                                                    {rate.dependencyPercent} %
                                                                                                </>
                                                                                                :
                                                                                                <FormattedNumber value={rate.dependencyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                                                            }
                                                                                        </span>
                                                                                        :
                                                                                        <span>
                                                                                            {
                                                                                                rate.dependecyOrder === getDependecyOrderTypes()[0] ?
                                                                                                    <span>
                                                                                                        <FormattedNumber value={rate.dependencyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                                                                        {` + ${rate.dependencyPercent} %`}
                                                                                                    </span>
                                                                                                    :
                                                                                                    ``
                                                                                            }
                                                                                            {
                                                                                                rate.dependecyOrder === getDependecyOrderTypes()[1] ?
                                                                                                    <span>
                                                                                                        {`${rate.dependencyPercent} % + `}
                                                                                                        <FormattedNumber value={rate.dependencyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                                                                    </span>
                                                                                                    :
                                                                                                    ``
                                                                                            }
                                                                                        </span>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    :
                                                    rates?.find(({ id }) => id === selectedRate)?.dependencyParent ?
                                                        <>
                                                            <Row className="mt-4">
                                                                <Col>
                                                                    <span className="fas fa-sitemap mr-2" /> <b><FormattedMessage id="MajorBulkUpdateModal.Parent" /></b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Card className="my-2 pointer p-2 shadow" style={{ border: 'unset' }} onClick={() => this.handleRateChange({ value: rates?.find(({ id }) => id === selectedRate).dependencyParent }, true)}>
                                                                        <Row>
                                                                            <Col className="col-8">
                                                                                {rates?.find(({ id }) => id === selectedRate)?.dependencyParentCode}
                                                                            </Col>
                                                                            <Col className="text-right">
                                                                                {
                                                                                    rates?.find(({ id }) => id === selectedRate)?.dependecyOrder === null ?
                                                                                        rates?.find(({ id }) => id === selectedRate)?.dependencyPercent && rates?.find(({ id }) => id === selectedRate)?.dependencyPercent > 0 ?
                                                                                            <>
                                                                                                {rates?.find(({ id }) => id === selectedRate)?.dependencyPercent} %
                                                                                            </>
                                                                                            :
                                                                                            rates?.find(({ id }) => id === selectedRate)?.dependencyValue && rates?.find(({ id }) => id === selectedRate)?.dependencyValue > 0 ?
                                                                                                <FormattedNumber value={rates?.find(({ id }) => id === selectedRate)?.dependencyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                                                                :
                                                                                                ''
                                                                                        :
                                                                                        <span>
                                                                                            {
                                                                                                rates?.find(({ id }) => id === selectedRate)?.dependecyOrder === getDependecyOrderTypes()[0] ?
                                                                                                    <span>
                                                                                                        <FormattedNumber value={rates?.find(({ id }) => id === selectedRate)?.dependencyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                                                                        {` + ${rates?.find(({ id }) => id === selectedRate)?.dependencyPercent} %`}
                                                                                                    </span>
                                                                                                    :
                                                                                                    ``
                                                                                            }
                                                                                            {
                                                                                                rates?.find(({ id }) => id === selectedRate)?.dependecyOrder === getDependecyOrderTypes()[1] ?
                                                                                                    <span>
                                                                                                        {`${rates?.find(({ id }) => id === selectedRate)?.dependencyPercent} % + `}
                                                                                                        <FormattedNumber value={rates?.find(({ id }) => id === selectedRate)?.dependencyValue} style="currency" currency={currencyCode || "EUR"} minimumFractionDigits={2} />
                                                                                                    </span>
                                                                                                    :
                                                                                                    ``
                                                                                            }
                                                                                        </span>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        ''
                                                }
                                            </TabPane>
                                        :''}
                                    </TabContent>
                                </form>
                            </Col>
                            <Col className="pl-0">
                                {!block && currAvailabilities ?
                                    <AvailabilityCalendar
                                        blockedWeekdays={weekdays}
                                        alwaysUpdate={true}
                                        buildCalendarStruct={this.buildCalendarStruct}
                                        isNavigable={true}
                                        currAvailabilities={currAvailabilities}
                                        startDate={sumbittedStartDate}
                                        selectedMapping={selectedMapping}
                                        findRule={this.findRule}
                                        renderCell={this.renderCell}
                                        renderSelectDay={this.renderSelectDay}
                                        checkSelectDay={this.checkSelectDay}
                                        selectdayAvailability={selectdayAvailability}
                                        selectedk={selectedk}
                                        calendarMonths={calendarMonths}
                                        cellHeight="11vh"
                                        getFreeRooms={day => day[freeRoomsFields[freeRoomsFieldsIdx].freeRooms]}
                                        getPercentage={(dayAvailability, isHost) => getPercentageDynamic(dayAvailability, isHost, freeRoomsFields[freeRoomsFieldsIdx])}
                                    />
                                :''}
                            </Col>
                        </Row>
                    </div>
                :
                    <Row>
                        <Col className="text-center">
                            <FormattedMessage id="BulkUpdate.ThereIsNoInfo" />
                        </Col>
                    </Row>
                }
            </StyledCard>
        );
    }
}
export default injectIntl(BulkUpdatePrices);