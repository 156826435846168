import React, { Component } from 'react';
import { CustomInput, Row, Col, Collapse, Input, Label, CardBody, Button, ButtonGroup, CardHeader, Card, InputGroup } from 'reactstrap';
import { ErrorAlert } from "../../Common/ErrorAlert";
import CustomSelect from '../../Base/Common/CustomSelect';
import BlockUi from 'react-block-ui'
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { postAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import { CommonHelper } from '../../Base/Common/CommonUIComponents'

export class BookingPromotionCard extends Component {

    constructor(props) {
        super(props);
        this.toggleAdvanceSettings = this.toggleAdvanceSettings.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDiscountChange = this.handleDiscountChange.bind(this);
        this.handleMinNights = this.handleMinNights.bind(this);
        this.handleNonRefundableChange = this.handleNonRefundableChange.bind(this);
        this.handleHourStartChange = this.handleHourStartChange.bind(this);
        this.handleHourEndChange = this.handleHourEndChange.bind(this);
        this.handleLastMinuteChange = this.handleLastMinuteChange.bind(this);
        this.handleEarlyBookerDaysChange = this.handleEarlyBookerDaysChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddRate = this.handleAddRate.bind(this);
        this.changeChannelInstanceId = this.changeChannelInstanceId.bind(this);
        this.onClickWeekDays = this.onClickWeekDays.bind(this);
        this.onDatesChangeExcludedDates = this.onDatesChangeExcludedDates.bind(this);
        this.isDayBlockedExcludedDays = this.isDayBlockedExcludedDays.bind(this);
        this.getExcludedDates = this.getExcludedDates.bind(this);
        this.removeExcludedDate = this.removeExcludedDate.bind(this);
        this.renderMonth = this.renderMonth.bind(this);
        this.savePromotionFormModeOne = React.createRef();
        this.savePromotionFormAdvanceSettings = React.createRef();
        this.savePromotionFormModeTwo = React.createRef();
        this.state = {
            error: null,
            blockWeekDays: [],
            AdvancedSettings : false,
            channelinstanceList: [],
            mode: this.getMode(this.props.selectedPromotion && this.props.selectedPromotion.type),
            selectedPromotion: this.props.selectedPromotion ? this.preparePromotion(this.props.selectedPromotion) : this.getNewSelectedPromotion(this.props.promotionType[0].code),
            excludedStartDate: null,
            activePromotions: this.props.activePromotions
        };
    }

    preparePromotion(promotion) {
        if (promotion && promotion.stay_date && promotion.stay_date.excluded_dates) {
            promotion.stay_date.excluded_dates.start = promotion.stay_date.excluded_dates.start != null ? moment(promotion.stay_date.excluded_dates.start) : null;
            promotion.stay_date.excluded_dates.end = promotion.stay_date.excluded_dates.end != null ? moment(promotion.stay_date.excluded_dates.end) : null;
            promotion.stay_date.excluded_dates.excluded_date = promotion.stay_date.excluded_dates.excluded_date.length > 0 ? promotion.stay_date.excluded_dates.excluded_date.map(excluded_date => moment(excluded_date)) : [];
        }
        return promotion;
    }

    getNewSelectedPromotion(type) {
        return {
            "name": "",
            "type": type,
            "min_stay_through": 1,
            "non_refundable": false,
            "no_cc_promotion": false,
            "book_date": {
                "start": null,
                "end": null
            },
            "book_time": {
                "start": null,
                "end": null
            },
            "stay_date": {
                "start": null,
                "end": null,
                "active_weekdays": {
                    active_weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
                },
                "excluded_dates": {
                    "start": null,
                    "end": null,
                    "excluded_date": []
                }
            },
            "additional_dates": null,
            "rateCodeChannels": {
                "rate": []
            },
            "discount": {
                "value": null
            },
            "active": true,            
            "last_minute": {
                "value": null,
                "unit": null
            },
            "early_booker_days": null,
            "target_channel": null,
            "promotionCode": null,
            "channelInstanceId": this.props.channelInstances && this.props.channelInstances.length > 0 ? this.props.channelInstances[0].id : null
        };
    }

    getMode(type) {
        return type === "geo_rate" || type === "mobile_rate" ? 2 : 1;
    }

    setActivePromotion(item) {
        if (this.props.editMode && !item.active && ((this.getMode(item.code) === 2 && this.getAvailableChannels(item.code).length > 0) || this.getMode(item.code) === 1)) {        
            var stateCopy = Object.assign({}, this.props);
            stateCopy.promotionType = stateCopy.promotionType.slice();
            for (var i = 0; i < stateCopy.promotionType.length; i++) {
                if (stateCopy.promotionType[i].code === item.code ) {
                    stateCopy.promotionType[i] = Object.assign({}, stateCopy.promotionType[i]);
                    stateCopy.promotionType[i].active = true;
                }
                else {
                    stateCopy.promotionType[i].active = false;
                }
            }
            this.setState(stateCopy);
            const mode = this.getMode(item.code);
            this.setState(({ selectedPromotion: this.getNewSelectedPromotion(item.code, mode), mode: mode }));
        }        
    }    

    toggleAdvanceSettings() {
        this.setState(state => ({ AdvancedSettings: !state.AdvancedSettings }));
    }
    
    handleNameChange(evt) {
        const value = evt.target.value;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, name: value } })); 
    }

    handleDiscountChange(evt) {
        const val = evt.target ? evt.target.value : null;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, discount: { value: val } } }));
    }

    handleMinNights(evt) {
        const val = evt.target.value;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, min_stay_through: val } }));
    }

    handleNonRefundableChange(evt) {
        const val = evt.target.checked;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, non_refundable: val } }));
    }

    handleHourStartChange(evt) {
        const val = evt.target.value;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, book_time: { ...prevState.selectedPromotion.book_time, start: val } } }));
    }

    handleHourEndChange(evt) {
        const val = evt.target.value;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, book_time: { ...prevState.selectedPromotion.book_time, end: val } } }));
    }

    handleLastMinuteChange(evt) {
        const val = evt.target.value;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, last_minute: { ...prevState.selectedPromotion.last_minute, value: val } } }));
    }

    handleEarlyBookerDaysChange(evt) {
        const val = evt.target.value;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, early_booker_days: val } }));
    }

    handleComboBox(name, combo) {
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, [name]: combo ? combo.value : null } }));
    }

    handleComboBoxInsideLastminute(name, combo) {
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, last_minute: { ...prevState.selectedPromotion.last_minute, [name]: combo? combo.value : null } } }));
    }

    changeChannelInstanceId(evt) {
        const val = evt.target.value;
        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, channelInstanceId: val, rateCodeChannels: { rate: [] }}}));
    }

    handleAddRate(evt) {
        const rateCodeChannels = this.state.selectedPromotion.rateCodeChannels.rate;
        const index = parseInt(evt.target.id, 0);
        const newRateCodeChannel = {
            id: index
        }

        if (rateCodeChannels) {
            const rateCodeChannel = rateCodeChannels.find(item => item.id == newRateCodeChannel.id);
            if (rateCodeChannel && !evt.target.checked) {
                rateCodeChannels.splice(rateCodeChannels.indexOf(rateCodeChannel), 1);
            }
            else if (!rateCodeChannel && evt.target.checked) {
                rateCodeChannels.push(newRateCodeChannel);
            }
        }

        this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, rateCodeChannels: { rate: rateCodeChannels } } }));
    }

    onClickWeekDays(weekDay) {
        const weekdays = this.state.selectedPromotion.stay_date && this.state.selectedPromotion.stay_date.active_weekdays && this.state.selectedPromotion.stay_date.active_weekdays.active_weekday;
        if (weekdays) {
            if (weekdays.find(weekday => weekday === weekDay)) {
                const elementsToRemove = 1;
                weekdays.splice(weekdays.indexOf(weekdays.find(item => item === weekDay)), elementsToRemove);
            }
            else {
                weekdays.push(weekDay);
            }
            this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, stay_date: { ...prevState.selectedPromotion.stay_date, active_weekdays: { ...prevState.selectedPromotion.stay_date.active_weekdays, active_weekday: weekdays }}}}));
        }
        else {
            const newActiveDays = {
                active_weekday: [ weekDay ]
            };
            this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, stay_date: { ...prevState.selectedPromotion.stay_date, active_weekdays: newActiveDays } } }));
        }
    }

    handleSubmit() {
        if (this.state.error) { //clean error message if exists
            this.setState({ error: null });
        }
        const timeBeforeBooking = document.getElementById('TimeBeforeBooking');
        const numberOfDaysBeforeCheckIn = document.getElementById('NumberOfDaysBeforeCheckIn');
        const discountOne = document.getElementById('discountOne');
        const hourStart = document.getElementById('hoursstart');
        const hourEnd = document.getElementById('hoursstartto');
        const rateCodeChannels = document.getElementById('bconnectionOne');
        const promotionName = document.getElementById('PromotionName');
        const stayDate = document.getElementById('startDate');

        if (this.state.mode === 1) {            
            if (this.state.selectedPromotion.discount.value < 1 || this.state.selectedPromotion.discount.value > 99) {
                discountOne.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.DiscountOne" }));
            }
            else if (this.state.selectedPromotion.stay_date == null || this.state.selectedPromotion.stay_date.start === null || this.state.selectedPromotion.stay_date.end == null) {
                stayDate.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.StayDayNull" }));
            }
            else if (this.state.selectedPromotion.book_time && (this.state.selectedPromotion.book_time.start >= 0 && this.state.selectedPromotion.book_time.start > 23)) {
                hourStart.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.BookTimeValuesOutOfRange" }));
            }
            else if (this.state.selectedPromotion.book_time && (this.state.selectedPromotion.book_time.end >= 0 && this.state.selectedPromotion.book_time.end > 23)) {
                hourEnd.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.BookTimeValuesOutOfRange" }));
            }
            else if (this.state.selectedPromotion.rateCodeChannels && this.state.selectedPromotion.rateCodeChannels.rate && this.state.selectedPromotion.rateCodeChannels.rate.length === 0) {
                rateCodeChannels.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.RateCodeChannelsEmpty" }));
            }
            else if (this.state.selectedPromotion.type == "last_minute" && this.state.selectedPromotion.last_minute && (this.state.selectedPromotion.last_minute.value === null || this.state.selectedPromotion.last_minute.unit === null)) {
                timeBeforeBooking.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.LastMinuteIsEmpty" }));
            }
            else if (this.state.selectedPromotion.type == "early_booker" && this.state.selectedPromotion.early_booker_days === null) {
                numberOfDaysBeforeCheckIn.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.EarlyBookerIsEmpty" }));
            }
            else if (this.state.selectedPromotion.name && this.state.selectedPromotion.name.length > 20) {
                promotionName.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.PromotionNameIsOverSize" }));
            }
            else {
                discountOne.setCustomValidity("");
                hourStart.setCustomValidity("");
                hourEnd.setCustomValidity("");
                rateCodeChannels.setCustomValidity("");
                timeBeforeBooking.setCustomValidity("");
                numberOfDaysBeforeCheckIn.setCustomValidity("");
                promotionName.setCustomValidity("");
            }
        }
        else if (this.state.mode === 2) {
            const discountTwo = document.getElementById('discountTwo');
            if (this.state.selectedPromotion.type === "geo_rate" && (this.state.selectedPromotion.discount.value < 5 || this.state.selectedPromotion.discount.value > 100)) {
                discountTwo.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.DiscountGeoRate" }));
            }
            else if (this.state.selectedPromotion.type === "mobile_rate" && (this.state.selectedPromotion.discount.value < 1 || this.state.selectedPromotion.discount.value > 80)) {
                discountTwo.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.DiscountMobileRate" }));
            }
            else {
                discountTwo.setCustomValidity("");
            }
        }
        
        if (this.state.mode === 2 && this.savePromotionFormModeTwo.current.reportValidity() || this.state.mode === 1 && (this.savePromotionFormModeOne.current.reportValidity() && this.savePromotionFormAdvanceSettings.current.reportValidity())) {               
            const url = this.state.selectedPromotion.id ? `/api/Price/Promotion/v1/${this.state.selectedPromotion.id}` : `/api/Price/Promotion/v1`;
            const body = {
                request: {
                    promotion: this.state.selectedPromotion
                }
            }
            this.setState({ block: true });
            postAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false }));
                        return;
                    }
                    if (data.request && data.request.promotion) {
                        this.setState({ selectedPromotion: this.preparePromotion(data.request.promotion) });
                        handleNotification(data, <FormattedMessage id="BookingPromotionCard.SavePromotionSuccess" />, <FormattedMessage id="BookingPromotionCard.Success" />);
                    }
                }
                this.setState({ block: false });
            }, url, JSON.stringify(body));
        }        
    }

    onDatesChangeExcludedDates(day) {
        const { startDate, endDate } = day;
        if (startDate && endDate) {
            const start = startDate.dayOfYear() + startDate.year(); 
            const end = endDate.dayOfYear() + endDate.year(); 
            if (start != end) {
                const excludedDate = this.getNewExcludedDateArray(start, end);
                this.setState(prevState => ({
                    excludedStartDate: null,
                    selectedPromotion: {
                        ...prevState.selectedPromotion,
                        stay_date: {
                            ...prevState.selectedPromotion.stay_date,
                            excluded_dates: {                                
                                start: startDate,
                                end: endDate,
                                excluded_date: excludedDate 
                            }
                        }
                    }
                }));
            }
            else {               
                const excludedDate = this.state.selectedPromotion.stay_date.excluded_dates.excluded_date;
                excludedDate.push(this.state.excludedStartDate);
                this.setState(prevState => ({
                    excludedStartDate: null,
                    selectedPromotion: { ...prevState.selectedPromotion, stay_date: { ...prevState.selectedPromotion.stay_date, excluded_dates: { ...prevState.selectedPromotion.stay_date.excluded_dates, excluded_date: excludedDate } } }
                }));
            }
        }
        else {
            this.setState({ excludedStartDate: startDate });
        }
    }

    getNewExcludedDateArray(start, end) {
        const excludedDate = this.state.selectedPromotion.stay_date.excluded_dates.excluded_date;
        const daysToRemove = excludedDate.filter(excluded_date => start <= excluded_date.dayOfYear() + excluded_date.year() && excluded_date.dayOfYear() + excluded_date.year() <= end);
        daysToRemove.forEach(dayToRemove => {
            const idxToRemove = excludedDate.indexOf(dayToRemove);
            if (idxToRemove > -1) {
                const elementsToRemove = 1;
                excludedDate.splice(idxToRemove, elementsToRemove);                
            }
        });
        return excludedDate;
    }

    isDayBlockedExcludedDays(day) {
        const start = this.state.selectedPromotion.stay_date.excluded_dates.start;
        const end = this.state.selectedPromotion.stay_date.excluded_dates.end;
        return (start && end && start.dayOfYear() + start.year() <= day.dayOfYear() + day.year() && day.dayOfYear() + day.year() <= end.dayOfYear() + end.year()) || this.state.selectedPromotion.stay_date.excluded_dates.excluded_date.find(excluded_date => excluded_date.dayOfYear() + excluded_date.year() === day.dayOfYear() + day.year())       
    }

    getExcludedDates() {
        const excludedDates = [];
        if (this.state.selectedPromotion.stay_date.excluded_dates.start && this.state.selectedPromotion.stay_date.excluded_dates.end) {
            excludedDates.push({
                startDate: this.state.selectedPromotion.stay_date.excluded_dates.start,
                endDate: this.state.selectedPromotion.stay_date.excluded_dates.end,
            });
        }
        this.state.selectedPromotion.stay_date.excluded_dates.excluded_date.forEach(excludedDate => {
            excludedDates.push({
                startDate: excludedDate,
            });
        });
        return excludedDates;
    }

    removeExcludedDate(excludedDate) {
        if (excludedDate.endDate) {
            this.setState(prevState => ({
                excludedStartDate: null,
                selectedPromotion: {
                    ...prevState.selectedPromotion,
                    stay_date: {
                        ...prevState.selectedPromotion.stay_date,
                        excluded_dates: {
                            ...prevState.selectedPromotion.stay_date.excluded_dates,
                            start: null,
                            end: null
                        }
                    }
                }
            }));
        }
        else {
            const start = excludedDate.startDate.dayOfYear() + excludedDate.startDate.year();
            this.setState(prevState => ({
                excludedStartDate: null,
                selectedPromotion: {
                    ...prevState.selectedPromotion,
                    stay_date: {
                        ...prevState.selectedPromotion.stay_date,
                        excluded_dates: {
                            ...prevState.selectedPromotion.stay_date.excluded_dates,
                            excluded_date: this.getNewExcludedDateArray(start, start)
                        }
                    }
                }
            }));
        }
    }

    renderMonth(month) {
        month.locale(this.props.intl.locale)
        return (
            (month && moment(month).isValid()) ? moment(month).format('MMMM YYYY') : ''
        )
    }

    getAvailableChannels(type) {
        const availableChannels = this.getMode(type) === 1 ? this.props.channelInstances : this.props.channelInstances && this.props.channelInstances.filter(item => this.getTargrtChannels(item.id, type).length > 0);
            
        return availableChannels ? availableChannels : [];
    }

    getCountries() {
        const countries = [
            {
                label: 'Algeria',
                value: 'algeria_pos'
            },
            {
                label: 'Argentina',
                value: 'argentina_pos'
            },
            {
                label: 'Australia',
                value: 'australia_pos'
            },
            {
                label: 'Belarus',
                value: 'belarus_pos'
            },
            {
                label: 'Brazil',
                value: 'brazil_pos'
            },
            {
                label: 'Canada',
                value: 'canada_pos'
            },
            {
                label: 'Chile',
                value: 'chile_pos'
            },
            {
                label: 'Colombia',
                value: 'colombia_pos'
            },
            {
                label: 'Domestic',
                value: 'domestic_pos'
            },
            {
                label: 'EU',
                value: 'eu_pos'
            },
            {
                label: 'Hong Kong',
                value: 'hong_kong_pos'
            },
            {
                label: 'India',
                value: 'india_pos'
            },
            {
                label: 'Indonesia',
                value: 'indonesia_pos'
            },
            {
                label: 'International',
                value: 'international_pos'
            },
            {
                label: 'Iran',
                value: 'iran_pos'
            },
            {
                label: 'Israel',
                value: 'israel_pos'
            },
            {
                label: 'Japan',
                value: 'japan_pos'
            },
            {
                label: 'Kazakhstan',
                value: 'kazakhstan_pos'
            },
            {
                label: 'Kuwait',
                value: 'kuwait_pos '
            },
            {
                label: 'Malaysia',
                value: 'malaysia_pos'
            },
            {
                label: 'Mexico',
                value: 'mexico_pos'
            },
            {
                label: 'New Zealand',
                value: 'new_zealand_pos'
            },
            {
                label: 'Oman',
                value: 'oman_pos'
            },
            {
                label: 'Pakistan',
                value: 'pakistan_pos'
            },
            {
                label: 'Peru',
                value: 'peru_pos'
            },
            {
                label: 'Philippines',
                value: 'philippines_pos'
            },
            {
                label: 'Qatar',
                value: 'qatar_pos'
            },
            {
                label: 'Russia',
                value: 'russia_pos'
            },
            {
                label: 'Saudi Arabia',
                value: 'saudi_arabia_pos'
            },
            {
                label: 'Singapore',
                value: 'singapore_pos'
            },
            {
                label: 'South Africa',
                value: 'south_africa_pos'
            },
            {
                label: 'South Korea',
                value: 'south_korea_pos'
            },
            {
                label: 'Switzerland',
                value: 'switzerland_pos'
            },
            {
                label: 'Taiwan',
                value: 'taiwan_pos'
            },
            {
                label: 'Thailand',
                value: 'thailand_pos'
            },
            {
                label: 'Trinidad & Tobago',
                value: 'trinidad_&_tobago_pos'
            },
            {
                label: 'Turkey',
                value: 'turkey_pos'
            },
            {
                label: 'Ukraine',
                value: 'ukraine_pos'
            },
            {
                label: 'United Arab Emirates',
                value: 'united_arab_emirates_pos'
            },
            {
                label: 'United States',
                value: 'united_states_pos'
            },
            {
                label: 'Vietnam',
                value: 'vietnam_pos'
            }
        ];
        return countries;
    }

    getMobileOptions() {
        const mobileOptions = [
            {
                label: 'app',
                value: 'app'
            },
            {
                label: 'all',
                value: 'all'
            }
        ];
        return mobileOptions;
    }

    getTargrtChannels(channelInstanceId, type) {
        const countries = this.getCountries();
        const mobileOptions = this.getMobileOptions();
        const targetChannelOptions = type === "geo_rate" ? countries : mobileOptions;
        return targetChannelOptions.filter(item => this.state.activePromotions.find(ap => ap.channelInstanceId === channelInstanceId && ap.target_channel === item.value && ap.active) == null);
    }
    
    render() {
        const promotion = this.props.promotionType && this.props.promotionType.find(item => item.code === this.state.selectedPromotion.type);
        const displayModeOne = this.state.mode === 1 ? 'block' : 'none';
        const displayModeTwo = this.state.mode === 2 ? 'block' : 'none';
        const displayLastMinuteForm = this.state.selectedPromotion.type === "last_minute" ? 'block' : 'none';
        const displayEarlyBookerorm = this.state.selectedPromotion.type === "early_booker" ? 'block' : 'none';
        const timeBeforeCheckInTypes = [
            {
                label: <FormattedMessage id="BookingPromotionCard.TimeBefeoreCheckInTypeDays" />,
                value: 'day'
            },
            {
                label: <FormattedMessage id="BookingPromotionCard.TimeBefeoreCheckInTypeHours" />,
                value: 'hour'
            }
        ];
        const channelInstance = this.props.channelInstances.find(channelInstance => channelInstance.id === this.state.selectedPromotion.channelInstanceId);
        const validWeekDays = [
            {
                value: "Sun",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />
            },
            {
                value: "Mon",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />
            },
            {
                value: "Tue",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />
            },
            {
                value: "Wed",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />
            },
            {
                value: "Thu",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />
            },
            {
                value: "Fri",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />
            },
            {
                value: "Sat",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />
            }            
        ];
        const targetChannelOptionsFiltered = this.getTargrtChannels(this.state.selectedPromotion.channelInstanceId, this.state.selectedPromotion.type);
        const allTargetChannelOptions = this.state.selectedPromotion && this.state.selectedPromotion.type === "geo_rate" ? this.getCountries() : this.getMobileOptions();
        const availableChannels = this.props.editMode ? this.getAvailableChannels(this.state.selectedPromotion.type) : this.props.channelInstances;


        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Card> 
                        <CardHeader className="bg-white">
                            <Row className='d-flex align-items-center'>
                                <Col>
                                    <i className="fas fa-percentage mr-2" />
                                    <FormattedMessage id="BookingPromotionCard.Promotion" />
                                </Col>
                                <Col>
                                    <Button className="btn-host btn-sm float-right" onClick={this.handleSubmit}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>           
                        <CardBody>
                            <Row>
                            {
                                this.props.promotionType && this.props.promotionType.map((item, key) =>
                                    <Col key={key} className={global.isMobile ? 'm-2 px-0' : ''}>
                                        <Card style={{minHeight: global.isMobile ? '110px' : '', minWidth: global.isMobile ? '110px' : ''}} className={item.code === this.state.selectedPromotion.type ? "border-left-primary shadow h-100 py-0  bg-light" : (this.getAvailableChannels(item.code).length === 0 ? "border-left-secundary shadow h-100 py-0  bg-light" : "shadow h-100 py-0")} onClick={this.setActivePromotion.bind(this, item)}>
                                            <CardBody className="pb-2 text-center" >
                                                <i className={`text-secondary fas ${global.isMobile ? 'fa-2x' : 'fa-3x'} ` + item.icon} />
                                                {!global.isMobile ?
                                                    <h4 className="text-secondary">
                                                        {item.name}
                                                    </h4>
                                                :
                                                    <h5 className="text-secondary">
                                                        {item.name}
                                                    </h5>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            }
                            </Row>
                        </CardBody>
                        <CardHeader className=" bg-white">
                            <h6>
                                <FormattedMessage id="BookingPromotionCard.ConfigurationType" /> <strong>{promotion != null ? promotion.name : ''}</strong>
                            </h6>
                        </CardHeader>
                        <CardBody>
                            <div style={{ display: displayModeOne }}>
                                <form ref={this.savePromotionFormModeOne}> 
                                    <div style={{ display: displayLastMinuteForm }}>
                                        <Row className={`pt-2 ${global.isMobile ? 'mb-2' : ''}`}>
                                            <Col className="col-lg-4 col-10">
                                                <Label for="TimeBeforeBooking"><FormattedMessage id="BookingPromotionCard.TimeBeforeBooking" />*</Label>
                                            </Col>
                                            {global.isMobile ? 
                                                <Col className="col-2">
                                                    <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.TimeBeforeBookingHelp" />} id={'timebeforebookingtooptip'} />
                                                </Col>
                                            : ''}
                                            <Col className={`col-lg-2 ${global.isMobile ? 'pr-0 ' : ''}`}>
                                                <Input type="number" name="TimeBeforeBooking" id="TimeBeforeBooking" bsSize="sm" className="text-sm" min="0" maxLength="2" value={this.state.selectedPromotion.last_minute && this.state.selectedPromotion.last_minute.value ? this.state.selectedPromotion.last_minute.value : ''} onChange={this.handleLastMinuteChange} />
                                            </Col>
                                            <Col className="col-lg-2">
                                                <CustomSelect options={timeBeforeCheckInTypes} name="timeBeforeCheckInType" id="timeBeforeCheckInType" bsSize="sm" className="text-sm" placeholder={<FormattedMessage id="BookingPromotionCard.TimeBeforeCheckInTypePlaceholder" />} value={this.state.selectedPromotion.last_minute && timeBeforeCheckInTypes.find(item => item.value === this.state.selectedPromotion.last_minute.unit)} onChange={this.handleComboBoxInsideLastminute.bind(this, 'unit')} isClearable />
                                            </Col>
                                            {!global.isMobile ? 
                                                <Col className="col-4" >
                                                    <CommonHelper help={<FormattedMessage id="BookingPromotionCard.TimeBeforeBookingHelp" />} id={'timebeforebookingtooptip'} />
                                                </Col>
                                            : ''}
                                        </Row>
                                    </div>
                                    <div style={{ display: displayEarlyBookerorm }}>
                                        <Row>
                                            <Col className="col-lg-4 col-10">
                                                <Label for="NumberOfDaysBeforeCheckIn"><FormattedMessage id="BookingPromotionCard.NumberOfDaysBeforeCheckIn" />*: </Label>
                                            </Col>
                                            {global.isMobile ?
                                                <Col className="col-2">
                                                    <CommonHelper placement='top' help={<FormattedMessage id="BookingPromotionCard.NumberOfDaysBeforeCheckInHelp" />} id={'numdayscheckintooptip'} />
                                                </Col>
                                            : ''}
                                            <Col className={`col-lg-4`}>
                                                <Input type="number" name="NumberOfDaysBeforeCheckIn" id="NumberOfDaysBeforeCheckIn" bsSize="sm" className="text-sm" min="0" maxLength="2" value={this.state.selectedPromotion.early_booker_days ? this.state.selectedPromotion.early_booker_days : ''} onChange={this.handleEarlyBookerDaysChange}/>
                                            </Col>
                                            {!global.isMobile ? 
                                                <Col className="col-4" >
                                                    <CommonHelper help={<FormattedMessage id="BookingPromotionCard.NumberOfDaysBeforeCheckInHelp" />} id={'numdayscheckintooptip'} />
                                                </Col>
                                            : ''}
                                        </Row>
                                    </div>
                                    <Row className="pt-2" >
                                        <Col className="col-lg-4 col-10">
                                            <Label for="PromotionName">
                                                <FormattedMessage id="BookingPromotionCard.PromotionName" />*
                                            </Label>
                                        </Col>
                                        {global.isMobile ?
                                            <Col className="col-2" >
                                                <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.PromotionNameHelp" />} id={'promonametooltip'} />
                                            </Col> 
                                        : ''}
                                        <Col className={`col-lg-4`}>
                                            <Input type="text" name="PromotionName" id="PromotionName" bsSize="sm" className="text-sm" maxLength="20" required value={this.state.selectedPromotion.name ? this.state.selectedPromotion.name : ''} onChange={this.handleNameChange} />
                                        </Col>
                                        {!global.isMobile ?
                                            <Col className="col-4" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.PromotionNameHelp" />} id={'promonametooltip'} />
                                            </Col>
                                        : ''}
                                    </Row>
                                    <Row className="pt-2" >
                                        <Col className="col-lg-4 col-10">
                                            <Label for="discountOne">
                                                <FormattedMessage id="bookingPromotion.discount" />*
                                            </Label>
                                        </Col>
                                        {global.isMobile ?
                                            <Col className="col-2" >
                                                <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.DiscountHelp" />} id={'discounttooltip'} />
                                            </Col> 
                                        : ''}
                                        <Col className="col-lg-4" >
                                            <Input type="number" name="discountOne" id="discountOne" bsSize="sm" className="text-sm " min="1" max="99" maxLength="2" required value={this.state.selectedPromotion.discount.value ? this.state.selectedPromotion.discount.value : ''} onChange={this.handleDiscountChange} />
                                        </Col>
                                        {!global.isMobile ?
                                            <Col className="col-4" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.DiscountHelp" />} id={'discounttooltip'} />
                                            </Col>
                                        : ''}
                                    </Row>
                                    <Row className="pt-2"  >
                                        <Col className="col-lg-4 col-10">
                                            <Label for="MinimumNight">
                                                <FormattedMessage id="BookingPromotionCard.MinimumNight" />
                                            </Label>
                                        </Col>
                                        {global.isMobile ?
                                            <Col className="col-2" >
                                                <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.MinimumNightHelp" />} id={'minnitpromotooltip'} />
                                            </Col>
                                        : ''}
                                        <Col className="col-lg-4" >
                                            <Input type="number" name="MinimumNight" id="MinimumNight" bsSize="sm" className="text-sm " min="1" max="7" maxLength="1" value={this.state.selectedPromotion ? this.state.selectedPromotion.min_stay_through : null} onChange={this.handleMinNights} />
                                        </Col> 
                                        {!global.isMobile ?
                                            <Col className="col-4" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.MinimumNightHelp" />} id={'minnitpromotooltip'} />
                                            </Col>
                                        : ''}
                                    </Row>
                                    <Row className={`pt-2 ${global.isMobile ? 'mb-2' : ''}`} >
                                        <Col className="col-lg-4 col-10">
                                            <Label for="AvailableWeekDays"><FormattedMessage id="BookingPromotionCard.AvailableWeekDays" /></Label>
                                        </Col>
                                        {global.isMobile ?
                                            <Col className="col-2">
                                                <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.AvailableWeekDaysHelp" />} id={'weekavailtooptip'} />
                                            </Col> 
                                        : ''}
                                        <Col className="col-lg-4" >
                                            <ButtonGroup name="weekdays" size="sm">
                                                {
                                                    validWeekDays && validWeekDays.map((weekDay, key) =>
                                                        <Button
                                                            key={key}
                                                            className={this.state.selectedPromotion.stay_date && this.state.selectedPromotion.stay_date.active_weekdays && this.state.selectedPromotion.stay_date.active_weekdays.active_weekday && this.state.selectedPromotion.stay_date.active_weekdays.active_weekday.filter(el => el === weekDay.value).length === 0 ? 'btn' : 'btn btn-host'}
                                                            onClick={() => this.onClickWeekDays(weekDay.value)}
                                                        >
                                                            {weekDay.label}
                                                        </Button>
                                                    )
                                                }                                                
                                            </ButtonGroup>
                                        </Col>
                                        {!global.isMobile ?
                                            <Col className="col-4" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.AvailableWeekDaysHelp" />} id={'weekavailtooptip'} />
                                            </Col>  
                                        : ''}                                  
                                    </Row>
                                    <Row className="pt-2">
                                        <Col className="col-lg-4 col-10">
                                            <Label for="AvailableWeekDays">
                                                <FormattedMessage id="BookingPromotionCard.StayDays" />*
                                            </Label>
                                        </Col>
                                        {global.isMobile ?
                                            <Col className="col-2">
                                                <CommonHelper placement='top' help={<FormattedMessage id="BookingPromotionCard.StayDaysHelp" />} id={'UncontrolledTooltipExample'} /> 
                                            </Col>
                                        : ''}
                                        <Col className="col-lg-5" >
                                            <DateRangePicker
                                                startDate={this.state.selectedPromotion.stay_date && this.state.selectedPromotion.stay_date.start ? moment(this.state.selectedPromotion.stay_date.start).locale(moment.locale()) : null}
                                                startDateId="startDate"
                                                startDatePlaceholderText={this.props.intl.formatMessage({ id: "BookingPromotionCard.StartDatePlaceholderText" })}
                                                endDate={this.state.selectedPromotion.stay_date && this.state.selectedPromotion.stay_date.end ? moment(this.state.selectedPromotion.stay_date.end).locale(moment.locale()) : null}
                                                endDateId="endDate"
                                                endDatePlaceholderText={this.props.intl.formatMessage({ id: "BookingPromotionCard.EndDatePlaceholderText" })}
                                                isOutsideRange={day => day.isBefore(moment(), 'day') || day > moment().add(2, 'year')}
                                                onDatesChange={({ startDate, endDate }) => this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, stay_date: { ...prevState.selectedPromotion.stay_date, start: startDate, end: endDate } } }))}
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                small={true}
                                                numberOfMonths={global.isMobile ? 1 : 3}
                                                showDefaultInputIcon={false}
                                                renderMonthText={this.renderMonth}
                                            />
                                        </Col>
                                        {!global.isMobile ?
                                            <Col className="col-3" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.StayDaysHelp" />} id={'UncontrolledTooltipExample'} /> 
                                            </Col>
                                        : ''}
                                    </Row>
                                    <Row className="pt-2" >
                                        <Col className={`col-lg-4 col-12 ${global.isMobile ? 'mb-2' : ''}`}>
                                            <Row>
                                                <Col className="col-lg-6 col-10">
                                                    <Label for="bconnectionOne">
                                                        <FormattedMessage id="BookingPromotionCard.Rates" />*
                                                    </Label>
                                                </Col>
                                                {global.isMobile ?
                                                    <Col className="col-2">
                                                        <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.RatesHelp" />} id={'bookingpromotioncardrateshelp'} /> 
                                                    </Col>
                                                : ''}
                                                
                                                <Col className="col-lg-6">
                                                    <Input required type="select" name="bconnectionOne" id="bconnectionOne" value={this.state.selectedPromotion.channelInstanceId} onChange={this.changeChannelInstanceId} disabled={!this.props.editMode}>
                                                        {this.props.channelInstances && this.props.channelInstances.map((channelInstance, key) => <option key={key} value={channelInstance.id} > {channelInstance.name} ( {channelInstance.propertyCode} )</option>)}
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="col-lg-7" >
                                            <Row >
                                                {channelInstance && channelInstance.rateCodeChannels && channelInstance.rateCodeChannels.response && channelInstance.rateCodeChannels.response.map((rateCodeChannel, key) => 
                                                    <Col key={key} className="col-lg-4 col-6 pb-lg-2 pb-3 small">
                                                        <CustomInput
                                                            type="checkbox"
                                                            name={rateCodeChannel.id}
                                                            id={rateCodeChannel.id}
                                                            bsSize="sm"
                                                            className="text-sm "
                                                            label={rateCodeChannel.localRateDesc}
                                                            checked={this.state.selectedPromotion.rateCodeChannels.rate && this.state.selectedPromotion.rateCodeChannels.rate.find(elem => elem.id.toString() === rateCodeChannel.id.toString()) ? true : false}
                                                            onChange={this.handleAddRate}
                                                        />
                                                    </Col>
                                                ) }                                                    
                                            </Row>
                                        </Col>
                                        {!global.isMobile ?
                                            <Col className="col-1" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.RatesHelp" />} id={'bookingpromotioncardrateshelp'} /> 
                                            </Col>
                                        : ''}
                                    </Row>
                                    
                                </form>
                            </div>
                            <div style={{ display: displayModeTwo }}>
                                <form ref={this.savePromotionFormModeTwo}>
                                    <Row className="pt-2">
                                        <Col className="col-lg-4 col-10">
                                            <Label for="discountTwo">
                                                <FormattedMessage id="bookingPromotion.discount" />*
                                            </Label>
                                        </Col>
                                        {global.isMobile ? 
                                            <Col className="col-2">
                                                <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.DiscountHelp" />} id={'discountmodetwotooltip'} />
                                            </Col>
                                        : ''}
                                        <Col className="col-lg-4" >
                                            <Input type="number" name="discountTwo" id="discountTwo" bsSize="sm" className="text-sm " min={this.state.selectedPromotion.type === "mobile_rate" ? "10" : "5"} max={this.state.selectedPromotion.type === "mobile_rate" ? "80" : "100"} maxLength="3" required value={this.state.selectedPromotion.discount.value ? this.state.selectedPromotion.discount.value : ''} onChange={this.handleDiscountChange} />
                                        </Col>
                                        {!global.isMobile ? 
                                            <Col className="col-4" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.DiscountHelp" />} id={'discountmodetwotooltip'} />
                                            </Col>
                                        : ''}
                                    </Row>                                    
                                    <Row className="pt-2">
                                        <Col className="col-lg-4 col-10">
                                            <Label for="channelInstance">
                                                <FormattedMessage id="BookingPromotion.channelInstance" />*
                                            </Label>
                                        </Col>
                                        {global.isMobile ? 
                                            <Col className="col-2">
                                                <CommonHelper placement='top' help={<FormattedMessage id="BookingPromotionCard.ChannelInstanceHelp" />} id={'channelinstancetooltip'} />
                                            </Col>
                                        : ''}
                                        <Col className="col-lg-4">
                                            <Input required type="select" name="bconnectionTwo" id="bconnectionTwo" value={this.state.selectedPromotion.channelInstanceId} onChange={this.changeChannelInstanceId} disabled={!this.props.editMode}>
                                                {availableChannels.map((channelInstance, key) => <option key={key} value={channelInstance.id}> {channelInstance.name} ({channelInstance.propertyCode})</option>)}
                                            </Input>
                                        </Col>
                                        {!global.isMobile ?
                                            <Col className="col-4" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.ChannelInstanceHelp" />} id={'channelinstancetooltip'} />
                                            </Col>
                                        : ''}
                                    </Row>
                                    <Row className="pt-2">
                                        <Col className="col-lg-4 col-10">
                                            <Label for="target_channel"><FormattedMessage id="BookingPromotionCard.TargetChannel" /></Label>
                                        </Col>
                                        {global.isMobile ? 
                                            <Col className="col-2">
                                                {this.state.selectedPromotion && this.state.selectedPromotion.type === "geo_rate" ?

                                                    <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.GeoRateTargetChannelHelp" />} id={'targetchanneltooltip'} />
                                                :
                                                    <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.MobileRateTargetChannelHelp" />} id={'targetchanneltooltip'} />
                                                }
                                            </Col>
                                        : ''}
                                        <Col className="col-lg-4">
                                            <CustomSelect options={targetChannelOptionsFiltered} name="target_channel" id="target_channel" bsSize="sm" className="text-sm" placeholder={this.state.selectedPromotion.type === "geo_rate" ? <FormattedMessage id="BookingPromotionCard.GeoRateTargerChannel" /> : <FormattedMessage id="BookingPromotionCard.MobileRateTargerChannel" />} value={allTargetChannelOptions.find(item => item.value === this.state.selectedPromotion.target_channel)} onChange={this.handleComboBox.bind(this, 'target_channel')} isClearable />
                                        </Col>
                                        {!global.isMobile ? 
                                            <Col className="col-4">
                                                {
                                                    this.state.selectedPromotion && this.state.selectedPromotion.type === "geo_rate" ?
                                                        <CommonHelper help={<FormattedMessage id="BookingPromotionCard.GeoRateTargetChannelHelp" />} id={'targetchanneltooltip'} />
                                                        :
                                                        <CommonHelper help={<FormattedMessage id="BookingPromotionCard.MobileRateTargetChannelHelp" />} id={'targetchanneltooltip'} />
                                                }
                                            </Col>
                                        : ''}
                                    </Row>
                                    <Row className="pt-2" >
                                        <Col className="col-lg-4 col-10">
                                            <Label for="ExcludedDates">
                                                <FormattedMessage id="BookingPromotion.ExcludedDates" />
                                            </Label>
                                        </Col>
                                        {global.isMobile ? 
                                            <Col className="col-2">
                                                <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.ExcludedDatesHelp" />} id={'excludeddatestooltipModeTwo'} />
                                            </Col>
                                        : ''}
                                        <Col className="col-lg-4" >
                                            <InputGroup size="sm text-right" >
                                                <DateRangePicker
                                                    startDate={this.state.excludedStartDate ? moment(this.state.excludedStartDate).locale(moment.locale()) : null}
                                                    startDateId="your_unique_start_date_id_promotion"
                                                    startDatePlaceholderText={this.props.intl.formatMessage({ id: "BookingPromotionCard.StartDatePlaceholderText" })}
                                                    isOutsideRange={day => day < moment() || day > moment(this.state.excludedStartDate).add(30, 'day')}
                                                    endDate={null}
                                                    endDateId="your_unique_end_date_id_promotion"
                                                    endDatePlaceholderText={this.props.intl.formatMessage({ id: "BookingPromotionCard.EndDatePlaceholderText" })}
                                                    onDatesChange={this.onDatesChangeExcludedDates}
                                                    focusedInput={this.state.focusedInputModeTwo}
                                                    onFocusChange={focusedInput => this.setState(({ focusedInputModeTwo: focusedInput }))}
                                                    small={true}
                                                    numberOfMonths={global.isMobile? 1 : 2}
                                                    showDefaultInputIcon={true}
                                                    minimumNights={0}
                                                    isDayBlocked={this.isDayBlockedExcludedDays}
                                                    renderMonthText={this.renderMonth}
                                                />                                                                                                 
                                            </InputGroup>
                                        </Col>
                                        {!global.isMobile ? 
                                            <Col className="col-4" >
                                                <CommonHelper help={<FormattedMessage id="BookingPromotionCard.ExcludedDatesHelp" />} id={'excludeddatestooltipModeTwo'} />
                                            </Col>
                                        : ''}
                                    </Row>        
                                    <Row className="pt-2" >
                                        <Col className="col-lg-4" />
                                        <Col className="col-lg-8 col-12">
                                            {
                                                this.getExcludedDates().map((item, key) =>
                                                    <Row key={key}>
                                                        <Col className={global.isMobile ? 'col-10' : ''}>
                                                            {
                                                                item.endDate ? 
                                                                    <p><FormattedMessage id="BookingPromotionCard.From" /> {`${item.startDate.date()}/${item.startDate.month() + 1}/${item.startDate.year()}`}, <FormattedMessage id="BookingPromotionCard.To" /> {`${item.endDate.date()}/${item.endDate.month() + 1}/${item.endDate.year()}`}</p>
                                                                    :
                                                                    <p>{`${item.startDate.date()}/${item.startDate.month() + 1}/${item.startDate.year()}`}</p>
                                                            }
                                                        </Col>
                                                        <Col className={global.isMobile ? 'col-2 pl-0' : ''}>
                                                            <Button className="btn btn-sm btn-host float-left" onClick={() => this.removeExcludedDate(item)}>
                                                                <i class="fas fa-trash-alt" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </form>
                            </div>                     
                        </CardBody>
                        <div style={{ display: displayModeOne }}>
                            <form ref={this.savePromotionFormAdvanceSettings}>
                                <CardHeader className="border shadow bg-white d-flex justify-content-between align-items-center">
                                    <FormattedMessage id="BookingPromotionCard.AdvancedSettings" />
                                    <a onClick={this.toggleAdvanceSettings}><i className={this.state.AdvancedSettings ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'}/></a>
                                </CardHeader>
                                <Collapse isOpen={this.state.AdvancedSettings}>
                                    <CardBody>
                                        <Row className="pt-2">
                                            <Col className="col-lg-4 col-10">
                                                <Label for="isNonRefundable"><FormattedMessage id="BookingPromotionCard.IsNonRefundable" /></Label>
                                            </Col>
                                            {global.isMobile ?
                                                <Col className="col-2">
                                                    <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.IsNonRefundableHelp" />} id={'isNonRefundabletooltip'} />
                                                </Col>
                                            :''}
                                            <Col className="col-lg-4">
                                                <CustomInput type="checkbox" name="isNonRefundable" id="isNonRefundable" bsSize="sm" className="text-sm " checked={this.state.selectedPromotion.non_refundable} onChange={this.handleNonRefundableChange} />
                                            </Col>
                                            {!global.isMobile ?
                                                <Col className="col-4">
                                                    <CommonHelper help={<FormattedMessage id="BookingPromotionCard.IsNonRefundableHelp" />} id={'isNonRefundabletooltip'} />
                                                </Col>
                                            :''}
                                        </Row> 
                                        <Row className="pt-2">
                                            <Col className="col-lg-4 col-10" >
                                                <Label for="WhenCanBeSeen"><FormattedMessage id="BookingPromotionCard.WhenCanBeSeen" /></Label>
                                            </Col>
                                            {global.isMobile ?
                                                <Col className="col-2">
                                                    <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.WhenCanBeSeenHelp" />} id={'book_dateTooltip'} />
                                                </Col>
                                            : ''}
                                            <Col className="col-lg-4" >
                                                <DateRangePicker
                                                    startDate={this.state.selectedPromotion.book_date && this.state.selectedPromotion.book_date.start ? moment(this.state.selectedPromotion.book_date.start).locale(moment.locale()) : null}
                                                    startDateId="startBookDate"
                                                    startDatePlaceholderText={this.props.intl.formatMessage({ id: "BookingPromotionCard.StartDatePlaceholderText" })}
                                                    endDate={this.state.selectedPromotion.book_date && this.state.selectedPromotion.book_date.end ? moment(this.state.selectedPromotion.book_date.end).locale(moment.locale()) : null}
                                                    endDateId="endBookDate"
                                                    endDatePlaceholderText={this.props.intl.formatMessage({ id: "BookingPromotionCard.EndDatePlaceholderText" })}
                                                    isOutsideRange={day => day < moment() || day > moment().add(12, 'month')}
                                                    isDayBlocked={day => this.state.blockWeekDays.filter(el => el === day.day()).length > 0}
                                                    onDatesChange={({ startDate, endDate }) => this.setState(prevState => ({ selectedPromotion: { ...prevState.selectedPromotion, book_date: { start: startDate, end: endDate } } }))}
                                                    focusedInput={this.state.focusedInputAdvancedSettings}
                                                    onFocusChange={focusedInput => this.setState(({ focusedInputAdvancedSettings: focusedInput }))}
                                                    small={true}
                                                    numberOfMonths={global.isMobile ? 1 : 3}
                                                    showDefaultInputIcon={true}
                                                    renderMonthText={this.renderMonth}
                                                />
                                            </Col>
                                            {!global.isMobile ?
                                                <Col className="col-4" >
                                                    <CommonHelper help={<FormattedMessage id="BookingPromotionCard.WhenCanBeSeenHelp" />} id={'book_dateTooltip'} />
                                                </Col>
                                            : ''}
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-lg-4 col-10" >
                                                <Label for="LimitPromotionToHours"><FormattedMessage id="BookingPromotionCard.LimitPromotionToHours" /></Label>
                                            </Col>
                                            {global.isMobile ?
                                                <Col className="col-2">
                                                    <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.LimitPromotionToHoursHelp" />} id={'promohurstooltip'} />
                                                </Col>
                                            : ''}
                                            <Col className="col-lg-2" >
                                                    <Input type="number" name="hoursstart" id="hoursstart" bsSize="sm" className="text-sm" value={this.state.selectedPromotion.book_time && this.state.selectedPromotion.book_time.start ? this.state.selectedPromotion.book_time.start : ''} onChange={this.handleHourStartChange} />
                                            </Col>
                                            <Col className="col-lg-2">
                                                    <Input type="number" name="hoursstartto" id="hoursstartto" bsSize="sm" className="text-sm " value={this.state.selectedPromotion.book_time && this.state.selectedPromotion.book_time.end ? this.state.selectedPromotion.book_time.end : ''} onChange={this.handleHourEndChange} />
                                            </Col>
                                            {!global.isMobile ?
                                                <Col className="col-4" >
                                                    <CommonHelper help={<FormattedMessage id="BookingPromotionCard.LimitPromotionToHoursHelp" />} id={'promohurstooltip'} />
                                                </Col>
                                            : ''}
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-lg-4 col-10" >
                                                <Label for="ExcludedDates">
                                                    <FormattedMessage id="BookingPromotion.ExcludedDates" />*
                                                </Label>
                                            </Col>
                                            {global.isMobile ?
                                                <Col className="col-2" >
                                                    <CommonHelper placement='right' help={<FormattedMessage id="BookingPromotionCard.ExcludedDatesHelp" />} id={'excludeddatestooltipModeOne'} />
                                                </Col>
                                            : ''}
                                            <Col className="col-lg-4" >
                                                <InputGroup size="sm text-right" >
                                                    <DateRangePicker
                                                        startDate={this.state.excludedStartDate ? moment(this.state.excludedStartDate).locale(moment.locale()) : null}
                                                        startDateId="your_unique_start_date_id_promotion"
                                                        startDatePlaceholderText={this.props.intl.formatMessage({ id: "BookingPromotionCard.StartDatePlaceholderText" })}
                                                        isOutsideRange={day => day < moment() || day > moment(this.state.excludedStartDate).add(30, 'day')}
                                                        endDate={null}
                                                        endDateId="your_unique_end_date_id_promotion"
                                                        endDatePlaceholderText={this.props.intl.formatMessage({ id: "BookingPromotionCard.EndDatePlaceholderText" })}
                                                        onDatesChange={this.onDatesChangeExcludedDates}
                                                        focusedInput={this.state.focusedInputExcludedDatesAdvancedSettings}
                                                        onFocusChange={focusedInput => this.setState(({ focusedInputExcludedDatesAdvancedSettings: focusedInput }))}
                                                        small={true}
                                                        numberOfMonths={global.isMobile? 1 : 2}
                                                        showDefaultInputIcon={true}
                                                        minimumNights={0}
                                                        isDayBlocked={this.isDayBlockedExcludedDays}
                                                        renderMonthText={this.renderMonth}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            {!global.isMobile ?
                                                <Col className="col-4" >
                                                    <CommonHelper help={<FormattedMessage id="BookingPromotionCard.ExcludedDatesHelp" />} id={'excludeddatestooltipModeOne'} />
                                                </Col>
                                            : ''}
                                        </Row>
                                        <Row className="pt-2">
                                            <Col className="col-4" />
                                            <Col className="col-lg-8 col-12">
                                                {
                                                    this.getExcludedDates().map((item, key) =>
                                                        <Row key={key}>
                                                            <Col className={global.isMobile ? 'col-10' : ''}>
                                                                {
                                                                    item.endDate ?
                                                                        <p><FormattedMessage id="BookingPromotionCard.From" /> {`${item.startDate.date()}/${item.startDate.month() + 1}/${item.startDate.year()}`}, <FormattedMessage id="BookingPromotionCard.To" /> {`${item.endDate.date()}/${item.endDate.month() + 1}/${item.endDate.year()}`}</p>
                                                                        :
                                                                        <p>{`${item.startDate.date()}/${item.startDate.month() + 1}/${item.startDate.year()}`}</p>
                                                                }
                                                            </Col>
                                                            <Col className={global.isMobile ? 'col-2 pl-0' : ''}>
                                                                <Button className="btn btn-sm btn-host float-left" onClick={() => this.removeExcludedDate(item)}>
                                                                    <i class="fas fa-trash-alt" />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col>
                                                *<FormattedMessage id="BookingPromotionCard.ExcludedDatesInfoText" />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Collapse>
                            </form>
                        </div>
                    </Card>                       
                </BlockUi> 
            </div>
        );
    }
}
export default injectIntl(BookingPromotionCard)