import React, { Component } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from "moment";
import CustomSelect from '../Base/Common/CustomSelect';
import { CalendarBarFlex } from './CalendarBarFlex';
import 'react-dates/initialize';
import { RoomCategoryBar } from './RoomCategoryBar';
import { RateDetailsBar } from './RateDetailsBar';
import BlockUi from 'react-block-ui';
import { CustomDateRangePicker } from '../Base/Common/CustomReactDates';

export class ManualReservationCategoriesPrices extends Component {

    constructor(props) {
        super(props);
        this.changeDate = this.changeDate.bind(this);        
        this.state = {            
            nextDays: []
        };
    }

    componentDidMount() {
        const { checkin, checkout } = this.props;
        this.setState({ nextDays: this.getDaysArray(checkout, checkin) });
    }

    getDaysArray(checkout, checkin) {
        const days = [];
        const numDays = moment(moment(checkout).format("YYYY-MM-DD")).diff(moment(moment(checkin).format("YYYY-MM-DD")), 'days');
        for (let i = 0; i <= numDays; i++) {
            days.push(moment(checkin).add(i, 'days'));
        }
        return days;
    }

    changeDate(_, date) {
        const { updateCheckInAndCheckout, getCalendar } = this.props;
        const datai = moment(date);
        if (datai.isBefore(moment(), 'day')) {
            return;
        }
        const dataf = moment(date).add(14, 'days');
        const days = this.getDaysArray(dataf, datai);
        this.setState(({ nextDays: days }), _ => updateCheckInAndCheckout(datai, dataf, getCalendar));
    }

    render() {
        const { reservation, handleAddDay, confirmRoomStay, isEditable, block, checkin, checkout, roomCategories, getCalendar, updateCheckInAndCheckout, roomCategory, setCombo, roomCategoriesOptions, colors, getTotalDailyPrice } = this.props;
        const { nextDays } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <Row style={{ paddingLeft: '15px' }}>
                    <Col className="col-5 px-1">
                        <FormGroup row>
                        <Label sm={4} style={{fontWeight: 500}}>
                                <FormattedMessage id="ManualReservation.CheckinCheckOut" />
                            </Label>
                            <Col sm={8} style={{ zIndex: '1001' }}>
                                <CustomDateRangePicker
                                    startDate={checkin}
                                    startDateId="your_unique_checkin_id"
                                    isOutsideRange={day => day <= moment().subtract(1, 'day') || day > moment().add(1, 'year')}
                                    endDate={checkout}
                                    endDateId="your_unique_checkout_id"
                                    onDatesChange={(startDate, endDate) => updateCheckInAndCheckout(startDate, endDate)}
                                    numberOfMonths={2}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-5 px-1">
                        <FormGroup row>
                        <Label for="name" sm={4} style={{ fontWeight: 500 }}>
                                <FormattedMessage id="ManualReservation.RoomCategory" />
                            </Label>
                            <Col sm={8}>
                                <CustomSelect placeholder={<FormattedMessage id="generic.select"/>} name="roomCategory" options={roomCategoriesOptions} value={roomCategoriesOptions.find(option => option.value == roomCategory)} onChange={setCombo} isSearchable isClearable />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm mr-1" onClick={_ => this.setState({ nextDays: this.getDaysArray(checkout, checkin) }, getCalendar)}>
                            <i className="fas fa-search" />
                        </Button>                            
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            roomCategories.length > 0 ?
                                <div className="container-fluid" style={{ overflowX: 'auto', paddingLeft: '0px', paddingRight: '0px', minHeight: '375px' }}>
                                    <CalendarBarFlex currentDay={checkin} next14Days={nextDays} action={this.changeDate} />
                                    {
                                        roomCategories.map((rc, key) =>
                                            <div key={`${rc.categoryId}${key}`} className="my-2 shadow">
                                                <RoomCategoryBar
                                                    roomCategory={rc}
                                                    roomStays={reservation.roomStays}
                                                />
                                                {
                                                    rc.rates.map((rate, key2) =>
                                                        <RateDetailsBar
                                                            key={`${rc.categoryId}${key}${key2}`}
                                                            rate={rate}
                                                            roomStays={reservation.roomStays}
                                                            handleAddDay={handleAddDay}
                                                            roomCategoryDesc={rc.categoryDescription}
                                                            colors={colors}
                                                            confirmRoomStay={confirmRoomStay}
                                                            isEditable={isEditable}
                                                            componentPrice={rate.components.reduce((acc, curr) => acc + curr.componentPrice, 0)}
                                                            componentPriceChildren={rate.components.reduce((acc, curr) => acc + curr.componentPriceChildren, 0)}
                                                            getTotalDailyPrice={getTotalDailyPrice}
                                                        />
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                :
                                <div />
                        }
                    </Col>
                </Row>
            </BlockUi>
        );
    }
}