import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Button, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard } from '../../Base/Common/CommonUIComponents';
import CustomSelect from '../../Base/Common/CustomSelect';
import { deleteAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';

export class EditCurrencyModal extends Component {

    constructor(props) {
        super(props);
        this.editCurrencyForm = React.createRef();
        this.state = {
            block: false,
            rate: this.props.modalData.rate
        };
    }

    handleRate = (evt) => {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    handleSave = () => {
        if (this.editCurrencyForm.current.reportValidity()) {
            const { modalData, updateTable, toggle } = this.props;
            const { rate } = this.state;

            this.setState({ block: true });

            const request = {
                baseCurrency: modalData.baseCurrency,
                targetCurrency: modalData.targetCurrency,
                rate: rate
            };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="CurrencyConversion.SuccessSave" />, <FormattedMessage id="General.Success" />);
                    this.setState({ block: false, error: errorMessage });
                    updateTable(modalData.idx, data.hotelId, rate);
                    toggle();
                }
                else {
                    this.setState(({ block: false, error: errorMessage }));
                }
            }, '/api/Price/Price/Currency/v1/exchange', request);
        }
    }

    handleRemove = () => {
        const { modalData, removeCurrency, toggle } = this.props;

        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            else {
                handleNotification(data, <FormattedMessage id="CurrencyConversion.SuccessRemove" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false, error: errorMessage });
                removeCurrency();
                toggle();
            }
        }, `/api/Price/Price/Currency/v1/exchange/${modalData.baseCurrency}/${modalData.targetCurrency}`);
    }

    render() {
        const { modal, toggle, modalData, baseCurrencies, targetCurrencies } = this.props;
        const { block, error, rate } = this.state;

        return (
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="CurrencyConversion.EditCurrencyRate" />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.editCurrencyForm}>
                            <Row className="mb-2">
                                <Col className="text-right">
                                    {
                                        modalData.hotelId ?
                                            <Button className="btn btn-host btn-sm mr-2" onClick={this.handleRemove}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                            :
                                            <span />
                                    }
                                    <Button className="btn btn-host btn-sm" onClick={this.handleSave}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-2">
                                    <FormattedMessage id="CurrencyConversion.BaseCurrency" />
                                </Col>
                                <Col className="col-3">
                                    <CustomSelect options={baseCurrencies} value={baseCurrencies.find(bs => bs.value === modalData.baseCurrency)} isDisabled={true} />
                                </Col>
                                <Col>
                                    <FormattedMessage id="CurrencyConversion.TargetCurrency" />
                                </Col>
                                <Col className="col-3">
                                    <CustomSelect options={targetCurrencies[modalData.baseCurrency]} value={targetCurrencies[modalData.baseCurrency].find(bs => bs.value === modalData.targetCurrency)} isDisabled={true} />
                                </Col>
                                <Col>
                                    <FormattedMessage id="CurrencyConversion.Rate" />
                                </Col>
                                <Col>
                                    <Input type="number" id="rate" name="rate" value={rate} onChange={this.handleRate} required min="0" step="0.0001" />
                                </Col>
                            </Row>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}